import React, { Fragment, ReactNode, useState } from "react";
import { DndProvider } from "react-dnd";
import { Box } from "@material-ui/core";
import { useQuery } from "react-apollo";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { HTML5Backend } from "react-dnd-html5-backend";
import { green, red } from "@material-ui/core/colors";
import { LenderProfiles } from "@trnsact/trnsact-shared-types/src/generated";
import { ProgramsDialog } from "../dialogs";
import { useModal } from "../../../../hooks/useModal";
import {
  FinanceProgramModal,
  PreviewFinanceProgramModal,
  mapCriteriaFormValuesToCreateInput,
  mapFinanceProgramEntityToFormValues,
  mapFormValuesToCreateFinanceProgramInput,
  mapFormValuesToUpdateFinanceProgramInput,
} from "../../../financeProgram";
import { modalsSelectors } from "../../../../redux/modalsReducer";
import { ConfigurationPanel, Header, LendersList } from "../components";
import { ModalsKeys } from "../../../../global";
import { useMutation } from "@apollo/react-hooks";
import {
  CREATE_FINANCE_PROGRAM,
  GET_FINANCE_PROGRAMS,
  M_Create_Prescreen_Criteria,
  Q_Prescreen_Criteria,
  UPDATE_FINANCE_PROGRAM,
} from "../../../../pages/LenderWaterfall/queries";
import { useNotifications } from "../../../notification";
import { CreateFinanceProgramInput, FinanceProgram, UpdateFinanceProgramInput } from "@trnsact/trnsact-shared-types";

export const PrescreenPage = () => {
  const classes = useStyles();

  // Start - This code only for testing Create\Update Finance program flow
  const [criteriaOptions, setCriteriaOptions] = useState([]);

  const { showNotification } = useNotifications();
  const { handleOpen: handleOpenPreviewDialog } = useModal(ModalsKeys.PreviewFinanceForm);
  const { handleOpen: handleOpenFinanceFormModal, handleClose: handleCloseFinanceFormModal } = useModal(
    ModalsKeys.FinanceForm
  );

  const openedModals = useSelector(modalsSelectors.openedModals) as ModalsKeys[];
  const lenderProfiles = useSelector((state: any) => state.vp?.lenderProfiles ?? []) as LenderProfiles[];
  const accountId = useSelector((state: any) => state.account.id);

  useQuery(Q_Prescreen_Criteria, {
    variables: { accountId },
    onCompleted(data) {
      const setOfCriteria = data?.prescreenCriteria ?? [];

      const options = setOfCriteria.map((criteria: any) => ({
        label: criteria.name,
        value: criteria.prescreenCriteriaId,
        createdDateTime: criteria?.createdDateTime,
        formRules: criteria?.jsonCriteria?.formRules ?? [],
      }));

      setCriteriaOptions(options);
    },
  });

  const [createLenderProgram] = useMutation<any, { input: CreateFinanceProgramInput }>(CREATE_FINANCE_PROGRAM, {
    context: { authRequired: true },
  });

  const [updateLenderProgram] = useMutation<any, { input: Partial<UpdateFinanceProgramInput> }>(
    UPDATE_FINANCE_PROGRAM,
    {
      context: { authRequired: true },
    }
  );

  const [createPrescreenCriteria] = useMutation(M_Create_Prescreen_Criteria, {
    context: { authRequired: true },
  });

  const { data, refetch: refetchFinancePrograms } = useQuery(GET_FINANCE_PROGRAMS, {
    variables: {
      accountId,
    },
  });

  const modalsWithKeys: Partial<Record<ModalsKeys, ReactNode>> = {
    [ModalsKeys.PrescreenProgramsDialog]: <ProgramsDialog />,
    [ModalsKeys.FinanceForm]: <FinanceProgramModal />,
    [ModalsKeys.PreviewFinanceForm]: <PreviewFinanceProgramModal />,
  };

  const onCreateProgram = () => {
    handleOpenFinanceFormModal({
      criteriaOptions,
      isMultipleLenders: false,
      lendersOptions: lenderProfiles,
      onSubmit: async formValue => {
        try {
          const preparedData = mapFormValuesToCreateFinanceProgramInput(formValue, accountId);

          const { data: createdCriteria } = await createPrescreenCriteria({
            variables: {
              input: preparedData.prescreenCriteriaInput,
            },
          });

          const { data: createdProgram } = await createLenderProgram({
            variables: {
              input: {
                ...preparedData,
                prescreenCriteriaIdToLink: createdCriteria?.createPrescreenCriteria?.prescreenCriteriaId,
              },
            },
          });

          await updateLenderProgram({
            variables: {
              input: {
                financeProgramId: createdProgram?.createFinanceProgram?.financeProgramId,
                prescreenCriteriaIdToLink: createdCriteria?.createPrescreenCriteria?.prescreenCriteriaId,
              },
            },
          });

          showNotification(`Finance program created!`);

          handleCloseFinanceFormModal();
        } catch (error) {
          showNotification(`Finance program was not created!`, {
            type: "error",
          });
        }
      },
    });
  };

  const onUpdateProgram = (programToUpdate: FinanceProgram) => {
    const programFormValues = mapFinanceProgramEntityToFormValues(programToUpdate, lenderProfiles);

    handleOpenPreviewDialog({
      program: programFormValues,
      onEdit: () => {
        handleOpenFinanceFormModal({
          criteriaOptions,
          isMultipleLenders: false,
          program: programFormValues,
          lendersOptions: lenderProfiles,
          onSubmit: async formValue => {
            try {
              const preparedData = mapFormValuesToUpdateFinanceProgramInput(programToUpdate, formValue);

              await updateLenderProgram({
                variables: {
                  input: preparedData,
                },
              });

              const isCriteriaDidntUpdate =
                programToUpdate?.prescreenCriteria?.prescreenCriteriaId === formValue?.program?.third?.criteria?.value;

              if (!isCriteriaDidntUpdate) {
                const { data: createdCriteria } = await createPrescreenCriteria({
                  variables: {
                    input: mapCriteriaFormValuesToCreateInput(formValue, accountId),
                  },
                });

                await updateLenderProgram({
                  variables: {
                    input: {
                      financeProgramId: "6fe11fbc-a7e4-11ef-bcbe-e34d317e0710",
                      prescreenCriteriaIdToLink: createdCriteria?.createPrescreenCriteria?.prescreenCriteriaId,
                    },
                  },
                });
              }

              await refetchFinancePrograms();

              showNotification(`Finance program updated!`);

              handleCloseFinanceFormModal();
            } catch (error) {
              showNotification(`Finance program was not updated!`, {
                type: "error",
              });
            }
          },
        });
      },
    });
  };
  // Start - This code only for testing Create\Update Finance program flow

  return (
    <>
      <Box className={classes.mainPage}>
        <Header />

        <Box className={classes.pageContent}>
          <DndProvider backend={HTML5Backend}>
            <LendersList lenders={lenderProfiles} />

            <ConfigurationPanel
              financePrograms={data?.financePrograms ?? []}
              onCreateProgram={onCreateProgram}
              onUpdateProgram={onUpdateProgram}
            />
          </DndProvider>
        </Box>
      </Box>

      {openedModals.map(modalKey => (
        <Fragment key={modalKey}>{modalsWithKeys[modalKey]}</Fragment>
      ))}
    </>
  );
};

const useStyles = makeStyles({
  mainPage: {
    gap: "4px",
    display: "flex",
    flexDirection: "column",

    "& .pageHeader": {
      padding: "24px",
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "space-between",

      "& .pageHeaderMain": {
        gap: "24px",
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "flex-start",

        "& .pageHeaderMainTitle": {
          display: "flex",
          flexDirection: "column",
        },

        "& .statusActive": {
          color: green["500"],
        },

        "& .statusInactive": {
          color: red["500"],
        },
      },
    },
  },
  pageContent: {
    gap: "4px",
    display: "flex",

    "& .list": {
      flex: 1,
      height: "68vh",
    },

    "& .panel": {
      flex: 2,
      overflow: "auto",
      height: "68vh",
    },
  },
});
