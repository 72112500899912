import React, { ReactNode } from "react";
import { FormSummaryInfo } from "../FormSummaryInfo";
import { FinanceProgramForm } from "../../../../../schema";
import { criteriaViewer, determineRuleType } from "../../../../../lib";

interface Props {
  financeProgramFormValues: FinanceProgramForm["program"];
}

export const CriteriaSummaryPart = ({ financeProgramFormValues: program }: Props) => {
  const formData = program.third.conditions;

  const renderData = formData.reduce<Record<string, ReactNode>>((acc, item) => {
    acc[
      item.rule
        .replace(/_/g, " ")
        .toLowerCase()
        .replace(/\b(\w)/g, char => char.toUpperCase())
    ] = criteriaViewer(determineRuleType(item.rule), item);

    return acc;
  }, {});

  return <FormSummaryInfo data={renderData} />;
};
