import React, { DOMElement, useEffect, useRef, useState } from "react";
import PerfectScrollbar from "perfect-scrollbar";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import sidebarStyle from "assets/jss/material-dashboard-pro-react/components/sidebarStyle.js";
import avatar from "assets/img/default-avatar.png";
import { Box, Divider } from "@material-ui/core";
import { vendorProfileDemoLogoPath } from "../../pages/Prequal/constants";
import { useSelector } from "react-redux";
import { UserProfile, VendorProfile } from "@trnsact/trnsact-shared-types";
import trnsactDefaultLogo from "assets/img/trnsact-logo-dark.png";
import { useRoutes } from "hooks/useRoutes";
import { SidebarNavItem } from "./SidebarNavItem";
import { LogoutNavItem } from "./LogoutNavItem";
import { NeedHelpSection } from "./NeedHelpSection";
const config = require("../../config.js");

//@ts-ignore
const useStyles = makeStyles(sidebarStyle);

export const SidebarV2 = () => {
  const classes = useStyles();

  const { availableAppRoutes, availalbeUserRoutes } = useRoutes();

  const vendorProfile = useSelector((state: any) => state.vp) as VendorProfile;
  const userProfile = useSelector((state: any) => state.userProfile) as UserProfile;

  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);

  const userRoutesToShowInSidebar = availalbeUserRoutes?.filter(r => r.showInSidebarNav);
  const adminRoutesToShowInSidebar = availableAppRoutes?.filter(r => r.showInSidebarNav);

  const vendorLogoPath = userProfile?.isDemoMode ? vendorProfileDemoLogoPath : vendorProfile?.logo;

  const sidebarWrapperRef = useRef<any>();

  useEffect(() => {
    sidebarWrapperRef.current &&
      new PerfectScrollbar(sidebarWrapperRef.current.firstChild, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
  }, [sidebarWrapperRef]);

  return (
    <Drawer
      ref={sidebarWrapperRef}
      anchor="right"
      variant="permanent"
      open
      classes={{
        paper: classes.drawerPaper /* + " " + classes.mobSideBar */ + " " + classes.drawerRoot,
      }}
    >
      <Box className={classes.logo}>
        <img
          src={
            vendorLogoPath
              ? `https://${config.S3_BUCKET_NAME}.s3-us-west-2.amazonaws.com/${vendorLogoPath}`
              : trnsactDefaultLogo
          }
          alt="logo"
          className={classes.img}
          style={{ backgroundColor: vendorProfile?.logoColors?.logoComplementaryColor || "#FFFFFF" }}
        />
      </Box>

      <List className={classes.list} style={{ zIndex: 10 }}>
        <ListItem className={`${classes.item} ${classes.userItem}`}>
          <Box className={classes.userInfoWrapper}>
            <Box className={classes.userImageWrapper}>
              {!!userProfile.mugshot ? (
                <img
                  src={`https://${config.S3_BUCKET_NAME}.s3-us-west-2.amazonaws.com/${userProfile.mugshot}`}
                  className={classes.avatarImg}
                  alt="user image"
                />
              ) : (
                <img src={avatar} className={classes.avatarImg} alt="user image" />
              )}
            </Box>

            <Box className={classes.currentUserNavItemLink} onClick={() => setIsCollapsed(prev => !prev)}>
              <ListItemText
                secondary={
                  <b
                    className={
                      classes.caret + " " + classes.userCaret + " " + (!isCollapsed ? classes.caretActive : "")
                    }
                  />
                }
                disableTypography={true}
                style={{ overflowX: "hidden", margin: 0 }}
              >
                <Box display="inline-block">
                  Welcome
                  <br />
                  {userProfile.fullName}
                </Box>
              </ListItemText>
            </Box>
          </Box>
          <Collapse in={!isCollapsed} /* unmountOnExit */>
            <List className={classes.list + " " + classes.collapseList}>
              {userRoutesToShowInSidebar?.map(routeConfig => (
                <SidebarNavItem key={routeConfig.id} routeConfig={routeConfig} />
              ))}
              <LogoutNavItem />
            </List>
          </Collapse>
        </ListItem>

        <Divider className={classes.divider} />

        {adminRoutesToShowInSidebar?.map(routeConfig => (
          <SidebarNavItem key={routeConfig.id} routeConfig={routeConfig} />
        ))}
      </List>

      <NeedHelpSection />
    </Drawer>
  );
};
