import React, { useMemo } from "react";
import clsx from "clsx";
import CancelIcon from "@material-ui/icons/Cancel";
import { lightGreen, red } from "@material-ui/core/colors";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { Box, makeStyles, Tooltip, Typography } from "@material-ui/core";
import { FactToCheck } from "../../../../../types";

export interface Props {
  isMatched: boolean;
  factsToCheck: FactToCheck[];
}

export const PricingResults = ({ isMatched, factsToCheck }: Props) => {
  return null;
  // const classes = useStyles();

  // const tooltipTitle = useMemo(() => {
  //   const factsToCheckReasons = factsToCheck.map(f => f.factKey.toLowerCase().replace("_", " ")).join(", ");

  //   return (
  //     <Box>
  //       {isMatched ? (
  //         <Box>
  //           <Box>This combination of {factsToCheckReasons} has pre-configured default pricing.</Box>

  //           <Box>Quote this product with confidence!</Box>
  //         </Box>
  //       ) : (
  //         <Box>
  //           <Box>This combination of {factsToCheckReasons} does not have configured default pricing.</Box>

  //           <Box>You can still use this product in your menu, but you will need to manually set the pricing.</Box>
  //         </Box>
  //       )}
  //     </Box>
  //   );
  // }, []);

  // return (
  //   <span
  //     className={clsx(classes.alert, {
  //       [classes.alertSuccess]: isMatched,
  //       [classes.alertFailed]: !isMatched,
  //     })}
  //   >
  //     <Tooltip title={tooltipTitle}>
  //       {isMatched ? <CheckCircleIcon className="infoIcon" /> : <CancelIcon className="infoIcon" />}
  //     </Tooltip>
  //     <Typography className="pricing">{`Pricing ${isMatched ? "" : "Not"} Validated`}</Typography>
  //   </span>
  // );
};

const useStyles = makeStyles({
  alert: {
    gap: "4px",
    padding: "4px",
    display: "flex",
    borderRadius: "2px",
    alignItems: "center",
    justifyContent: "center",
    color: "white !important",
  },
  alertSuccess: {
    backgroundColor: lightGreen[100],

    "& .infoIcon": {
      color: lightGreen[600],
    },

    "& .pricing": {
      color: lightGreen[600],
    },
  },
  alertFailed: {
    backgroundColor: red[100],

    "& .infoIcon": {
      color: red[600],
    },

    "& .pricing": {
      color: red[600],
    },
  },
});
