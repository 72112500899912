import gql from "graphql-tag";

export const GET_LOCATIONS = gql`
  query locations {
    locations {
      locationId
      locationName
      category
      locationParentId
    }
  }
`;
