import { AllRules, ConditionsFieldsTypes } from "../types";
import {
  LENDER_WATERFALL_FACT_NAMES_BOOLEAN,
  LENDER_WATERFALL_FACT_NAMES_NUMERIC,
} from "@trnsact/types-lender-waterfall";

export function determineRuleType(rule: AllRules): ConditionsFieldsTypes {
  if (rule in LENDER_WATERFALL_FACT_NAMES_NUMERIC) return ConditionsFieldsTypes.Number;
  if (rule in LENDER_WATERFALL_FACT_NAMES_BOOLEAN) return ConditionsFieldsTypes.Boolean;

  return ConditionsFieldsTypes.String;
}
