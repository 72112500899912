import React, { Fragment, useMemo } from "react";
import clsx from "clsx";
import { Box, Typography } from "@material-ui/core";
import { useFormContext } from "react-hook-form";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { FinanceProgramForm } from "../../schema";
import { FinanceProgramFormSteps, StepsConfig } from "../../types";
import { useStepperActions } from "../../hooks/useStepperActions";

interface Props {
  isReadOnly: boolean;
}

export const Stepper = ({ isReadOnly }: Props) => {
  const classes = useStyles();

  const { formState } = useFormContext<FinanceProgramForm>();

  const { handleGoToExactStep, currentStepWatchValue } = useStepperActions({
    isReadOnly,
  });

  const stepsConfig = useMemo<StepsConfig[]>(
    () => [
      {
        label: "Program Details",
        step: FinanceProgramFormSteps.First,
        isValid: !!Object.values(formState?.errors?.program?.first ?? {}).find(Boolean),
      },
      {
        label: "Configure Structure",
        step: FinanceProgramFormSteps.Second,
        isValid: !!Object.values(formState?.errors?.program?.second ?? {}).find(Boolean),
      },
      {
        label: "Choose Criteria",
        step: FinanceProgramFormSteps.Third,
        isValid: !!Object.values(formState?.errors?.program?.third ?? {}).find(Boolean),
      },
      {
        label: "Confirm",
        step: FinanceProgramFormSteps.Fourth,
        isValid: !!Object.values(formState?.errors?.program?.fourth ?? {}).find(Boolean),
      },
    ],
    [formState]
  );

  return (
    <Box className={classes.container}>
      {stepsConfig.map(({ step, label }, index, initialArray) => (
        <Fragment key={label}>
          <Box
            className={classes.step}
            onClick={async () => {
              await handleGoToExactStep(step);
            }}
          >
            {currentStepWatchValue <= step ? (
              <Box className={classes.stepValueWrapper}>
                <Box
                  className={clsx(classes.stepValue, {
                    [classes.currentStepValue]: currentStepWatchValue >= step,
                  })}
                >
                  <Typography variant="subtitle1">{step}</Typography>
                </Box>
              </Box>
            ) : (
              <CheckCircleIcon color="primary" fontSize="large" />
            )}
            <Box
              onClick={async () => {
                await handleGoToExactStep(step);
              }}
            >
              <Typography
                component="span"
                variant="subtitle2"
                style={{ whiteSpace: "nowrap" }}
                color={currentStepWatchValue >= step ? "textPrimary" : "textSecondary"}
                className={clsx({
                  [classes.currentStepLabel]: currentStepWatchValue === step,
                })}
              >
                {label}
              </Typography>
            </Box>
          </Box>

          {initialArray.length - 1 !== index && (
            <Box
              className={clsx(classes.stepDivider, {
                [classes.currentStepDivider]: currentStepWatchValue > step,
              })}
            />
          )}
        </Fragment>
      ))}
    </Box>
  );
};

const useStyles = makeStyles<Theme>(({ palette }) => ({
  container: {
    gap: "24px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  step: {
    gap: "8px",
    display: "flex",
    alignItems: "center",
    color: palette.common.white,

    "&:hover": {
      opacity: 0.7,
      cursor: "pointer",
    },
  },
  stepValueWrapper: {
    width: "35px",
    height: "35px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  stepValue: {
    width: "29.7px",
    height: "29.7px",
    display: "flex",
    borderRadius: "50%",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: palette.grey["400"],
  },
  currentStepLabel: {
    color: palette.primary.main,
  },
  currentStepValue: {
    backgroundColor: palette.primary.main,
  },
  stepDivider: {
    width: "100%",
    height: "3px",
    backgroundColor: palette.grey["400"],
  },
  currentStepDivider: {
    backgroundColor: palette.primary.main,
  },
}));
