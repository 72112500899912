import React from "react";
import { Box, Button, makeStyles, Typography } from "@material-ui/core";
import { CheckboxField, MultiSelect, SwitchField } from "components/form";
import { Controller, useForm } from "react-hook-form";
import {
  PortalConfiguration,
  PortalConfigurationType,
  UpsertPortalConfigurationInput,
  UserProfile,
  VendorProfile,
} from "@trnsact/trnsact-shared-types";
import { getLocationsSelectOptions, getUserRolesSelectOptions, getUsersSelectOptions } from "utils/selectOptionsHelper";
import { useDispatch, useSelector } from "react-redux";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { LocationsReponse, PortalConfigurationsReponse, SavePortalConfigurationReponse } from "../../api/types";
import { OpportunitiesNotificationsForm, OpportunitiesNotificationsStageUpdatesForm } from "../../types";
import { portalConfigurationsActions, portalConfigurationsSelectors } from "redux/portalConfigurationReducer";
import { getPortalConfigurationDefaultConfig } from "../../lib";
import { GET_LOCATIONS, GET_PORTAL_CONFIGURATIONS, SAVE_PORTAL_CONFIGURATION } from "../../api";
import { useNotifications } from "modules/notification";

export const OpportunitiesNotifications = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { showNotification } = useNotifications();

  const vendorProfile = useSelector((state: any) => state.vp) as VendorProfile;
  const relatedUsers = useSelector((state: any) => state.relatedUsers) as UserProfile[];
  const voNotificationPortalConfig = useSelector(state =>
    portalConfigurationsSelectors.configDataByType(state, PortalConfigurationType.VoNotifications)
  ) as PortalConfiguration;

  const portalConfigurationDefaultConfig = getPortalConfigurationDefaultConfig(
    vendorProfile.id,
    PortalConfigurationType.VoNotifications
  );

  const { control, setValue, reset, handleSubmit, watch } = useForm<OpportunitiesNotificationsForm>({
    defaultValues: portalConfigurationDefaultConfig.jsonDefinition.config as OpportunitiesNotificationsForm,
  });

  useQuery<PortalConfigurationsReponse>(GET_PORTAL_CONFIGURATIONS, {
    fetchPolicy: "no-cache",
    skip: !vendorProfile?.id,
    variables: {
      vendorProfileId: vendorProfile?.id,
      types: [PortalConfigurationType.VoNotifications],
    },
    onCompleted(response) {
      const configToSet = response?.portalConfigurations?.[0] || portalConfigurationDefaultConfig;
      dispatch(portalConfigurationsActions.setConfigData([configToSet]));
      reset(configToSet.jsonDefinition.config as OpportunitiesNotificationsForm);
    },
  });

  const { data: locationsData } = useQuery<LocationsReponse>(GET_LOCATIONS);

  const [savePortalConfiguration] = useMutation<
    SavePortalConfigurationReponse,
    { input: UpsertPortalConfigurationInput }
  >(SAVE_PORTAL_CONFIGURATION, {
    onCompleted() {
      showNotification("Saved!");
    },
    onError() {
      showNotification("Error!", { type: "error" });
    },
  });

  const userRolesSelectOptions = getUserRolesSelectOptions();
  const usersSelectOptions = getUsersSelectOptions(relatedUsers);

  const handleMultiSelectChange = (
    selected: (string | number)[],
    key: keyof OpportunitiesNotificationsStageUpdatesForm
  ) => {
    setValue(`stageUpdates.${key}`, selected as string[]);
  };

  const handleSave = async (formData: OpportunitiesNotificationsForm) => {
    const configToSave: PortalConfiguration = {
      ...voNotificationPortalConfig,
      jsonDefinition: { config: formData },
    };
    const result = await savePortalConfiguration({
      variables: {
        input: configToSave,
      },
    });
    configToSave.portalConfigurationId = result.data?.savePortalConfiguration;
    dispatch(portalConfigurationsActions.setConfigData([configToSave]));
  };

  const isTurnedOn = watch("stageUpdates.isTurnedOn");

  return (
    <Box>
      <Typography component="p" variant="h6">
        Opportunities
      </Typography>
      <Box display="flex" alignItems="center">
        <Typography component="span" variant="subtitle2">
          Stage Updates
        </Typography>
        <SwitchField
          control={control}
          className={classes.isTurnedOnSwitch}
          name="stageUpdates.isTurnedOn"
          tooltip="Notify the below group(s) when an Opportunity stage changes by:"
        />
      </Box>
      {isTurnedOn && (
        <Box className={classes.stageUpdatesContainer}>
          <CheckboxField
            control={control}
            name="stageUpdates.isNotifyAllRelatedUsers"
            label="Assignment - All users assigned to an opportunity."
          />
          <Controller
            control={control}
            name="stageUpdates.locationIds"
            render={({ field }) => (
              <MultiSelect
                label="Location - All users attributed to the following location(s):"
                options={getLocationsSelectOptions(locationsData?.locations ?? [])}
                className={classes.input}
                formControlProps={{
                  variant: "standard",
                }}
                {...field}
                onChange={(selected: (string | number)[]) => handleMultiSelectChange(selected, "locationIds")}
              />
            )}
          />
          <Controller
            control={control}
            name="stageUpdates.userRoles"
            render={({ field }) => (
              <MultiSelect
                label="Role - All users of the following user role(s):"
                options={userRolesSelectOptions}
                className={classes.input}
                formControlProps={{
                  variant: "standard",
                }}
                {...field}
                onChange={(selected: (string | number)[]) => handleMultiSelectChange(selected, "userRoles")}
              />
            )}
          />
          <Controller
            control={control}
            name="stageUpdates.userIds"
            render={({ field }) => (
              <MultiSelect
                label="Specific User(s)"
                options={usersSelectOptions}
                className={classes.input}
                formControlProps={{
                  variant: "standard",
                }}
                {...field}
                onChange={(selected: (string | number)[]) => handleMultiSelectChange(selected, "userIds")}
              />
            )}
          />
        </Box>
      )}
      <Button variant="contained" onClick={handleSubmit(handleSave)}>
        Save
      </Button>
    </Box>
  );
};

const useStyles = makeStyles(({ palette }) => ({
  stageUpdatesContainer: {
    paddingLeft: 20,
    marginTop: 10,
  },
  input: {
    margin: "16px 0",
  },
  isTurnedOnSwitch: {
    marginLeft: 12,
  },
}));
