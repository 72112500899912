export enum ProposalsViewTypes {
  All = "all",
  My = "my",
}

export interface CellDefaultProps<TableData = any> {
  proposal: TableData;
}

export interface CellArgs<TableData = any> {
  original: TableData;
}

export interface ColumnFilter {
  value: string[];
  id: string;
}
