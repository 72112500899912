import React, { useEffect } from "react";
import { GaigAvailableAsset, GaigProgramCode } from "@trnsact/aftermarket-vendor-sdk-gaig";
import { SelectOption } from "global";
import { Grid } from "@material-ui/core";
import { FormInputSelect } from "components/form";
import { useSelector } from "react-redux";
import { deskingSelectors } from "../../../../../model";
import { ProposalProductCardModes } from "modules/desking/types";

interface Props {
  configuration: Record<
    | "programCode"
    | "selectedProgram"
    | "availableAsset"
    | "contractTerm"
    | "coverageTerm"
    | "billingFrequency"
    | "contractResponse",
    any
  >;
  uniqueAvailableAssets: GaigAvailableAsset[];
  filteredProgramCodes: GaigProgramCode[];
  handleAvailableAssetChange: any;
  handleSelectedProgramChange: any;
  handleContractTermChange: any;
  handleCoverageTermChange: any;
  handleBillingFrequencyChange: any;
  isDynamicPricingLoading: boolean;
  mode: ProposalProductCardModes;
}

export const Fields = ({
  configuration,
  uniqueAvailableAssets,
  handleAvailableAssetChange,
  filteredProgramCodes,
  handleSelectedProgramChange,
  handleContractTermChange,
  handleCoverageTermChange,
  handleBillingFrequencyChange,
  isDynamicPricingLoading = false,
  mode,
}: Props) => {
  configuration.billingFrequency = "Monthly";
  const currentTerm = useSelector(deskingSelectors.term);

  useEffect(() => {
    handleContractTermChange(currentTerm.term);

    if (filteredProgramCodes && filteredProgramCodes.length === 1) {
      handleSelectedProgramChange(filteredProgramCodes[0].ProgramCode);
    }
  }, []);

  const getTermArrayByStep = (min: number, max: number, step: number = 12): SelectOption[] => {
    const termArray: SelectOption[] = [];

    if (min === 1) {
      termArray.push({ label: "1", value: "1" });
    }

    for (let i = min === 1 ? 12 : min; i <= max; i += step) {
      termArray.push({ label: i.toString(), value: i.toString() });
    }

    return termArray;
  };

  function getProgramDeductibleValue() {
    if (configuration.programCode && filteredProgramCodes.find(fpc => fpc.ProgramCode === configuration.programCode)) {
      return configuration.programCode;
    }
    if (filteredProgramCodes.length === 1) {
      return filteredProgramCodes[0].ProgramCode;
    }
    return "";
  }

  return (
    <>
      {filteredProgramCodes.length !== 0 && (
        <FormInputSelect
          variant="standard"
          label="Equipment Type"
          disabled={isDynamicPricingLoading}
          onChange={handleAvailableAssetChange}
          value={configuration.availableAsset || ""}
          options={[
            { label: "Select Equipment Type", value: "" },
            ...uniqueAvailableAssets.map(asset => ({
              label: asset.displayName,
              value: asset.code,
            })),
          ]}
        />
      )}

      {configuration.availableAsset && (
        <FormInputSelect
          label="Program / Deductible"
          variant="standard"
          disabled={isDynamicPricingLoading}
          value={getProgramDeductibleValue()}
          onChange={handleSelectedProgramChange}
          options={[
            { label: "Select Program / Deductible", value: "" },
            ...filteredProgramCodes.map(programCode => ({
              label: programCode.ProgramDescriptionExternal,
              value: programCode.ProgramCode,
            })),
          ]}
        />
      )}

      {configuration.selectedProgram && (
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormInputSelect
              label="Financing Term"
              variant="standard"
              disabled={isDynamicPricingLoading || mode === ProposalProductCardModes.Constructor}
              value={configuration.contractTerm ? configuration.contractTerm : ""}
              options={[
                { label: "Select Contract Term", value: "" },
                ...getTermArrayByStep(
                  configuration.selectedProgram.ContractTermMin,
                  configuration.selectedProgram.ContractTermMax
                ),
              ]}
              onChange={value => {
                handleContractTermChange(value);
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <FormInputSelect
              label="Coverage Term"
              variant="standard"
              disabled={isDynamicPricingLoading}
              value={configuration.coverageTerm ? configuration.coverageTerm : ""}
              options={[
                { label: "Select Coverage Term", value: "" },
                ...getTermArrayByStep(
                  configuration.selectedProgram.CoverageTermMin,
                  configuration.selectedProgram.CoverageTermMax
                ),
              ]}
              onChange={value => {
                handleCoverageTermChange(value);
              }}
            />
          </Grid>
        </Grid>
      )}

      {/* {configuration.selectedProgram && (
        <FormInputSelect
          label="Billing Frequency"
          variant="standard"
          disabled={isDynamicPricingLoading}
          value={configuration.billingFrequency ? configuration.billingFrequency : "" }
          options={[
            { label: "Select Billing Frequency", value: "" },
            ...configuration.selectedProgram.BillingFrequencyOptions.map((billingFrequency: string) => ({
              label: billingFrequency,
              value: billingFrequency,
            })),
          ]}
        />
      )} */}
    </>
  );
};
