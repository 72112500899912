import { get } from "lodash";
import { ColumnFilter } from "../types";
import { HubProposal } from "../api/types";
import { SelectAllValue } from "../constants";

export function hubSearchFilter(data: HubProposal[], searchValue: string) {
  if (!Array.isArray(data) || !data?.length || !searchValue) return data;

  return data.filter(proposal => proposal?.businessName?.toLowerCase().includes(searchValue.toLowerCase()));
}

export function hubSearchFilterByColumn(filter: ColumnFilter, row: any) {
  const selectedValues = filter.value || [];

  if (!selectedValues.length) return true;

  if (selectedValues.includes(SelectAllValue)) return true;

  return selectedValues.includes(get(row, filter.id));
}

export function hubSearchFilterFoTable(data: any[], filters: ColumnFilter[]) {
  return filters.reduce(
    (filteredData: any[], filter: ColumnFilter) => filteredData.filter(row => hubSearchFilterByColumn(filter, row)),
    data
  );
}
