import React, { ReactNode } from "react";
import { Box } from "@material-ui/core";
import { useFormContext, useWatch } from "react-hook-form";
import { LenderProfiles } from "@trnsact/trnsact-shared-types/src/generated";
import { FinanceProgramForm } from "../../schema";
import { Confirm } from "./formSteps/confirm/Confirm";
import { ProgramDetails } from "./formSteps/details/ProgramDetails";
import { ChooseCriteria } from "./formSteps/criteria/ChooseCriteria";
import { FinanceCriteriaOptions, FinanceProgramFormSteps } from "../../types";
import { ConfigureStructure } from "./formSteps/structure/ConfigureStructure";

interface Props {
  isReadOnly: boolean;
  isMultipleLenders?: boolean;
  userType?: "lender" | "dealer";
  lendersOptions: LenderProfiles[];
  isLendersSelectDisabled?: boolean;
  dealerVendorProfileOptions?: any[];
  criteriaOptions: FinanceCriteriaOptions[];
}

export const Form = ({
  userType,
  isReadOnly,
  lendersOptions,
  criteriaOptions,
  isMultipleLenders,
  isLendersSelectDisabled,
  dealerVendorProfileOptions,
}: Props) => {
  const { control, getValues } = useFormContext<FinanceProgramForm>();
  const { step: currentStepWatchValue = FinanceProgramFormSteps.First } = useWatch<FinanceProgramForm>({
    control,
  });

  const renderFormStep: Record<FinanceProgramFormSteps, ReactNode> = {
    [FinanceProgramFormSteps.First]: (
      <ProgramDetails
        userType={userType}
        isReadOnly={isReadOnly}
        lendersOptions={lendersOptions}
        isMultipleLenders={isMultipleLenders}
        isLendersSelectDisabled={isLendersSelectDisabled}
        dealerVendorProfileOptions={dealerVendorProfileOptions}
      />
    ),
    [FinanceProgramFormSteps.Second]: <ConfigureStructure isReadOnly={isReadOnly} />,
    [FinanceProgramFormSteps.Third]: <ChooseCriteria isReadOnly={isReadOnly} criteriaOptions={criteriaOptions} />,
    [FinanceProgramFormSteps.Fourth]: <Confirm financeProgramFormValues={getValues("program")} />,
  };

  return <Box className="formSection">{renderFormStep[currentStepWatchValue]}</Box>;
};
