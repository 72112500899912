import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles, Box, Button } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { menuConstructorActions, menuConstructorSelectors } from "../../../model";
import { ProposalProduct } from "@trnsact/trnsact-shared-types/dist/generated";
import { CardContainer } from "components/shared/CardContainer/CardContainer";
import { ProposalProductsCard } from "modules/desking";
import { ProposalProductCardModes } from "modules/desking/types";
import { useModal } from "hooks/useModal";
import { ModalsKeys } from "global";

export const ProductsCard = () => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const isLoading = useSelector(menuConstructorSelectors.isLoading) as boolean;
  const selectedMenuOption = useSelector(menuConstructorSelectors.selectedMenuOption);
  const allProducts = useSelector(menuConstructorSelectors.proposalsProducts) as ProposalProduct[];
  const selectedProducts = useSelector(menuConstructorSelectors.selectedProposalsProducts) as ProposalProduct[];

  const { handleOpen: handleProposalProductsDialogOpen } = useModal(ModalsKeys.ProposalProductsDialog);

  const updateProductConfiguration = (productId: string, menuName: string, updateValues: Record<string, any>) => {
    dispatch(
      menuConstructorActions.updateMenuProduct({
        productId,
        updateValues,
      })
    );
  };

  const handleProductsDialogConfirm = (selectedProducts: ProposalProduct[]) => {
    dispatch(menuConstructorActions.setSelectedProducts(selectedProducts));
  };

  const renderCards = useMemo(() => {
    const productsToEdit = selectedMenuOption?.products || [];

    const productCardsForEdit = productsToEdit.map((product: ProposalProduct) => {
      return (
        <ProposalProductsCard
          key={`forEdit-${product.proposalProductId}`}
          type="forEdit"
          product={product}
          updateProductConfiguration={updateProductConfiguration}
          proposalProductConfiguration={product}
          mode={ProposalProductCardModes.Constructor}
        />
      );
    });

    const selectedProductCards = selectedProducts.map(product => (
      <ProposalProductsCard
        key={product.proposalProductId}
        type="simple"
        product={product}
        mode={ProposalProductCardModes.Constructor}
      />
    ));

    return [...selectedProductCards, ...productCardsForEdit];
  }, [selectedMenuOption, selectedProducts]);

  return (
    <CardContainer
      title="Products"
      isLoading={isLoading}
      actionBtn={
        <Button
          startIcon={<AddIcon />}
          onClick={() => {
            handleProposalProductsDialogOpen({
              products: allProducts,
              selectedProposalsProductsIds: selectedProducts?.map(p => p.proposalProductId),
              onConfirm: handleProductsDialogConfirm,
            });
          }}
        >
          {!!selectedProducts.length ? "Add more" : "Add product"}
        </Button>
      }
    >
      <Box className={classes.cardsContainer}>{renderCards}</Box>
    </CardContainer>
  );
};

const useStyles = makeStyles(({ palette }) => ({
  cardsContainer: {
    overflow: "auto",
    height: 700,
    display: "flex",
    flexDirection: "column",
    gap: 10,
  },
}));
