import React from "react";
import { Tooltip } from "@material-ui/core";
import { Controller, RegisterOptions, Control } from "react-hook-form";
import { FieldValues, UseControllerProps } from "react-hook-form/dist/types";
import { ChipsFormInput } from "../ChipsFormInput";

interface Props<FormValues extends FieldValues = FieldValues> {
  label?: string;
  tooltip?: string;
  control: Control<FormValues>;
  name: UseControllerProps<FormValues>["name"];
  rules?: Omit<RegisterOptions<FormValues>, "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled">;
}

export const ChipsInputField = <FormValues extends FieldValues = FieldValues>({
  name,
  label,
  tooltip,
  rules,
  control,
}: Props<FormValues>) => {
  return (
    <Controller
      name={name}
      rules={rules}
      control={control}
      render={({ field: { onChange, value }, fieldState }) => (
        <Tooltip title={tooltip || ""} arrow disableHoverListener={!tooltip}>
          <ChipsFormInput
            value={value || []}
            onChange={onChange}
            error={!!fieldState.error}
            placeholder={label || "Enter values..."}
            helperText={fieldState.error ? fieldState.error.message : ""}
          />
        </Tooltip>
      )}
    />
  );
};
