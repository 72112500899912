import React, { ChangeEvent, useMemo } from "react";
import { get } from "lodash";
import { Column } from "react-table";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import FormControl from "@material-ui/core/FormControl";
import { Checkbox, MenuItem, Select } from "@material-ui/core";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import { HubProposal } from "../../api/types";
import { SelectAllValue } from "../../constants";
import { SelectOption } from "../../../../global";

interface MultiSelectFilterProps {
  rows: any[];
  column: Column<HubProposal>;
  filter?: { value: string[] };
  labelFormatter?: (label: string) => string;
  onChange: (value: string[]) => void;
}

export const MultiSelectFilter = ({ filter, onChange, column, rows, labelFormatter }: MultiSelectFilterProps) => {
  const options = useMemo(
    () =>
      rows.reduce<SelectOption[]>((acc, row) => {
        const valueToAdd = get(row, column?.id ?? "");

        if (valueToAdd && !acc.map(({ value }) => value).includes(valueToAdd)) {
          acc.push({
            value: valueToAdd,
            label: labelFormatter ? labelFormatter(valueToAdd) : valueToAdd,
          });
        }

        return acc;
      }, []),
    [rows, column]
  );

  const handleChange = (event: ChangeEvent<{ value: unknown }>) => {
    const nextValue = event.target.value as string[];

    const isAllAfterOther =
      nextValue.includes(SelectAllValue) && nextValue.length > 1 && nextValue[nextValue.length - 1] === SelectAllValue;
    const isOtherAfterAll = nextValue.includes(SelectAllValue) && nextValue.length > 1;

    let newSelected: string[];

    switch (true) {
      case isAllAfterOther:
        newSelected = [SelectAllValue];
        break;
      case isOtherAfterAll:
        newSelected = nextValue.filter(value => value !== SelectAllValue);
        break;
      default:
        newSelected = nextValue;
    }

    onChange(newSelected);
  };

  return (
    <FormControl fullWidth size="small">
      <Select
        multiple
        displayEmpty
        onChange={handleChange}
        disabled={!options.length}
        value={filter?.value ?? [SelectAllValue]}
        renderValue={selected =>
          (selected as string[]).map(value => (labelFormatter ? labelFormatter(value) : value)).join(", ")
        }
        MenuProps={{
          getContentAnchorEl: null,
          style: { maxHeight: "500px" },
          anchorOrigin: { vertical: "bottom", horizontal: "left" },
        }}
      >
        <MenuItem key="select-all" value={SelectAllValue}>
          <Checkbox
            checkedIcon={<CheckBoxIcon fontSize="small" />}
            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
            checked={!filter?.value || !!filter?.value?.includes(SelectAllValue)}
          />
          Select All
        </MenuItem>

        {options.map(({ value, label }) => (
          <MenuItem key={value} value={value}>
            <Checkbox
              checkedIcon={<CheckBoxIcon fontSize="small" />}
              icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
              checked={!!filter?.value?.includes(value)}
            />
            {label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
