import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import { useModal } from "../../../../hooks/useModal";
import { useForm } from "react-hook-form";
import { MenuTemplateSaveAsFormInputs } from "modules/aftermarketMenuConstructor/types";
import { menuConstructorActions, menuConstructorSelectors } from "modules/aftermarketMenuConstructor/model";
import { ProposalMenu } from "@trnsact/trnsact-shared-types";
import { ModalsKeys } from "../../../../global";
import { GeneralInformationForm } from "../pages/addEditMenuTemplate/GeneralInformationForm";
import { usePermissions } from "modules/aftermarketMenuConstructor/hooks/usePermissions";

export const SaveAsConfirmationDialog = () => {
  const dispatch = useDispatch();
  const proposalMenu = useSelector(menuConstructorSelectors.proposalsMenu) as ProposalMenu;
  const saveAsForm = useForm<MenuTemplateSaveAsFormInputs>({
    defaultValues: {
      name: `${proposalMenu.name} (COPY)`,
      description: proposalMenu.description,
    },
  });

  const { isOpen, data, handleClose } = useModal(ModalsKeys.AftermarketSaveAsDialog);
  const { hasWriteAccess } = usePermissions();

  const handleSave = () => {
    const menuToSave = {
      ...proposalMenu,
      ...saveAsForm.getValues(),
      proposalMenuId: "",
    };
    dispatch(menuConstructorActions.setMenu(menuToSave));
    if (data?.onSave) data?.onSave(menuToSave);
    handleClose();
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="sm" fullWidth={true}>
      <DialogTitle>Save As</DialogTitle>

      <DialogContent>
        <GeneralInformationForm
          control={saveAsForm.control}
          errors={saveAsForm.formState.errors}
          hasWriteAccess={hasWriteAccess}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" variant="outlined">
          Cancel
        </Button>
        <Button onClick={saveAsForm.handleSubmit(handleSave)} color="primary" variant="contained">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
