import { FinanceProgram, ProposalMenu, ProposalMenuOption } from "@trnsact/trnsact-shared-types";
/* import { ProposalProduct } from "@trnsact/trnsact-shared-types/src/generated"; */

/* import { ProposalProduct } from "@trnsact/trnsact-shared-types"; */
import { ProposalProduct } from "@trnsact/trnsact-shared-types/src/generated";

import {
  ADD_MENU_OPTION,
  EDIT_MENU_OPTION,
  REMOVE_MENU_OPTION,
  SELECT_MENU_OPTION,
  ADD_MENU_OPTION_PRODUCT,
  REMOVE_MENU_OPTION_PRODUCT,
  SET_PROPOSALS_MENU,
  SET_PROPOSALS_PRODUCTS,
  SET_SELECTED_PROPOSALS_PRODUCTS,
  SET_MENU_OPTION_TO_ADD_EDIT,
  UPDATE_MENU_PRODUCT,
  SET_MENU_GENERAL_FORM_VALUES,
  SET_INITIAL_PROPOSALS_MENU,
  SET_IS_LOADING,
  CLEAR,
} from "./actionTypes";
import { MenuOptionToAddEdit, MenuTemplateGeneralFormInputs } from "../types";

const setMenu = (payload: ProposalMenu) => ({
  type: SET_PROPOSALS_MENU,
  payload,
});

const setMenuGeneralFormValues = (payload: MenuTemplateGeneralFormInputs) => ({
  type: SET_MENU_GENERAL_FORM_VALUES,
  payload,
});

const setProducts = (payload: ProposalProduct[]) => ({
  type: SET_PROPOSALS_PRODUCTS,
  payload,
});

const setSelectedProducts = (payload: ProposalProduct[]) => ({
  type: SET_SELECTED_PROPOSALS_PRODUCTS,
  payload,
});

const setMenuOptionToAddEdit = (payload: { currentMenuOption: MenuOptionToAddEdit }) => ({
  type: SET_MENU_OPTION_TO_ADD_EDIT,
  payload,
});

const addMenuOption = (payload: { menuOption: MenuOptionToAddEdit }) => ({
  type: ADD_MENU_OPTION,
  payload,
});

const editMenuOption = (payload: { menuOption: MenuOptionToAddEdit }) => ({
  type: EDIT_MENU_OPTION,
  payload,
});

const removeMenuOption = (payload: { currentMenuOption: MenuOptionToAddEdit }) => ({
  type: REMOVE_MENU_OPTION,
  payload,
});

const selectMenuOption = (payload: { menuOption: ProposalMenuOption | null }) => ({
  type: SELECT_MENU_OPTION,
  payload,
});

const addMenuOptionProduct = (payload: { menuType: string; product: ProposalProduct }) => ({
  type: ADD_MENU_OPTION_PRODUCT,
  payload,
});

const removeMenuOptionProduct = (payload: { menuType: string; removeIndex: number }) => ({
  type: REMOVE_MENU_OPTION_PRODUCT,
  payload,
});

const updateMenuProduct = (payload: any) => ({
  type: UPDATE_MENU_PRODUCT,
  payload,
});

const setInitialMenu = (payload: ProposalMenu) => ({
  type: SET_INITIAL_PROPOSALS_MENU,
  payload,
});

const setIsLoading = (payload: boolean) => ({
  type: SET_IS_LOADING,
  payload,
});

const clearState = () => ({
  type: CLEAR,
});

export const menuConstructorActions = {
  setMenu,
  setProducts,
  setSelectedProducts,
  setMenuOptionToAddEdit,
  setMenuGeneralFormValues,
  addMenuOption,
  editMenuOption,
  removeMenuOption,
  selectMenuOption,
  addMenuOptionProduct,
  removeMenuOptionProduct,
  updateMenuProduct,
  setInitialMenu,
  setIsLoading,
  clearState,
};
