import gql from "graphql-tag";

export const GET_PORTAL_CONFIGURATIONS = gql`
  query portalConfigurations($vendorProfileId: ID!, $types: [PortalConfigurationType]) {
    portalConfigurations(vendorProfileId: $vendorProfileId, types: $types) {
      portalConfigurationId
      vendorProfileId
      jsonDefinition
      type
    }
  }
`;
