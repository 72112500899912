import { SelectOption } from "../../../global";

export function convertEnumToSelectOptions<Value extends string, T extends Record<string, unknown>>(
  enumValue: T
): SelectOption<Value>[] {
  function formatKey(key: string): string {
    const withSpaces = key.replace(/([a-z])([A-Z])/g, "$1 $2").replace(/-/g, " ");

    return withSpaces
      .split(" ")
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  }

  return Object.entries(enumValue).map(([key, value]) => ({
    label: formatKey(key),
    value: value as Value,
  }));
}
