import { CreateFinanceProgramInput, FinanceProgramRateType } from "@trnsact/trnsact-shared-types";
import { FinanceProgramForm } from "../schema";
import { mapCriteriaFormValuesToCreateInput } from "./mapCriteriaFormValuesToCreateInput";
import { mapConfigurationFormValuesToCreateOrUpdate } from "./mapConfigurationFormValuesToCreateOrUpdate";

export function mapFormValuesToCreateFinanceProgramInput(
  formValue: FinanceProgramForm,
  accountId: string
): CreateFinanceProgramInput & {
  paymentOptionsConfiguration: {
    notes: string;
    guidelines: JSON;
    dealerMarkup: number;
    customerRate: number;
    rateType: FinanceProgramRateType;
  };
} {
  const {
    program: { first, second, third },
  } = formValue;

  const programAvailableForLenders = [...first.lenders, ...first.dealers].map(({ value }) => value);

  return {
    accountId,
    nameInternal: first.name,
    financeProgramModificationSettings: {
      markup: true,
    },
    lenderProfileIds: programAvailableForLenders,
    prescreenCriteriaIdToLink: third?.criteria?.value,
    validDateEnd: second.isValidityDates ? second.endDate : null,
    validDateStart: second.isValidityDates ? second.startDate : null,
    prescreenCriteriaInput: mapCriteriaFormValuesToCreateInput(formValue, accountId),
    paymentOptionsConfiguration: mapConfigurationFormValuesToCreateOrUpdate(formValue),
  };
}
