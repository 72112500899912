import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@material-ui/core";
import { ModalsKeys } from "../../../global";
import { useModal } from "../../../hooks/useModal";

export const ConfirmationModal = () => {
  const classes = useStyles();

  const { isOpen, data, handleClose } = useModal(ModalsKeys.Confirmation);

  const handleReset = () => {
    if (data?.onConfirm) data.onConfirm();
    handleClose();
  };

  return (
    <Dialog open={isOpen} fullWidth maxWidth="sm">
      {data?.title && (
        <Box className={classes.dialogTitle}>
          <DialogTitle>
            <Typography component="span" variant="h6">
              {data.title}
            </Typography>
          </DialogTitle>
        </Box>
      )}

      <DialogContent>
        <Box className={classes.dialogContent}>
          <Typography component="span" variant="body1">
            {data?.message}
          </Typography>
        </Box>
      </DialogContent>

      <DialogActions>
        {data.actionButtons || (
          <>
            <Button size="small" color="primary" variant="outlined" onClick={handleClose}>
              Cancel
            </Button>

            <Button size="small" color="primary" variant="contained" onClick={handleReset}>
              Confirm
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles({
  dialog: {
    padding: "10px 4px",
  },
  dialogTitle: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  dialogContent: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
});
