import React from "react";
import { useDispatch } from "react-redux";
import { grey } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import InfoOutlined from "@material-ui/icons/InfoOutlined";
import { Box, IconButton, Typography } from "@material-ui/core";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { ProposalProduct } from "@trnsact/trnsact-shared-types/dist/generated";
import { ProposalProductAddon } from "@trnsact/trnsact-shared-types/src/generated";
import { deskingActions } from "../../../model";
import { CardPriceBlock } from "../cardPriceBlock";
import { ProposalProductCardAddons } from "./ProposalProductCardAddons";
import { ProposalProductsCardTypeChip } from "../ProposalProductsCardTypeChip";
import { ProductCatalogDetails } from "../catalogDetails/ProductCatalogDetails";

interface Props {
  logo?: string;
  menuType: string;
  productIndex: number;
  product: ProposalProduct;
  onRemoveProduct?: VoidFunction;
}

export const ProposalProductCard = ({ logo, product, productIndex, onRemoveProduct, menuType }: Props) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const handleRemoveAddon = (title: string) => {
    dispatch(
      deskingActions.removeMenuProductAddon({
        productId: product.proposalProductId,
        productIndex: productIndex,
        title,
        menuType,
      })
    );
  };

  return (
    <Box className={classes.card}>
      <Box className={classes.titleContainer}>
        {logo && <img className={classes.productLogo} src={logo} alt="Proposal product logo" />}

        <Box className={classes.titleWithLogo}>
          <Typography className="productName" variant="h6">
            {product.title}
          </Typography>

          {product?.aftermarketProduct?.productCategory && (
            <ProposalProductsCardTypeChip productCategory={product.aftermarketProduct.productCategory} />
          )}
        </Box>

        <Box className="actions">
          <InfoOutlined color="primary" />

          {onRemoveProduct && (
            <IconButton
              size="small"
              onClick={event => {
                event.stopPropagation();
                onRemoveProduct();
              }}
            >
              <DeleteOutlineIcon color="error" />
            </IconButton>
          )}
        </Box>
      </Box>

      <ProductCatalogDetails product={product} />

      <CardPriceBlock type="simple" cost={product.cost} markup={product.markup} retailCost={product.retailCost} />

      {!!(product as any)?.addons?.length && (
        <ProposalProductCardAddons
          onRemoveAddon={handleRemoveAddon}
          addons={product.addons ? (product.addons as ProposalProductAddon[]) : []}
        />
      )}
    </Box>
  );
};

const useStyles = makeStyles({
  card: {
    gap: "8px",
    display: "flex",
    padding: "8px",
    borderRadius: "2px",
    flexDirection: "column",
    backgroundColor: grey["100"],
  },
  titleContainer: {
    gap: "8px",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  titleWithLogo: {
    display: "flex",
    flexDirection: "column",
  },
  productLogo: {
    width: "36px",
    height: "36px",
  },
});
