import _ from "lodash";
import { MarkupType } from "../types";

export function prepareMenuOptionsForAPI(menuOptions: any[], financeProgramDetails: any) {
  if (!menuOptions) return null;

  return menuOptions?.map((menuOption: any) => ({
    name: menuOption.name,
    description: menuOption.description,
    titleColor: menuOption.titleColor,
    titleBgColor: menuOption.titleBgColor,
    products: menuOption.products.map((product: any) => {
      const aftermarketProduct = product.aftermarketProduct ? { ...product.aftermarketProduct } : undefined;

      if (product.markup) {
        delete product.markup.__typename;
        product.markup.markup = _.round(product.markup.markup, 2);
      }

      if (product.configuration) {
        // aftermarketProduct.configuration = { ...product.configuration };
        delete product.configuration;
      }

      if (aftermarketProduct && aftermarketProduct.__typename) {
        delete aftermarketProduct.productDescriptionInternal;
        delete aftermarketProduct.createdDateTime;
        delete aftermarketProduct.modifiedDateTime;
        delete aftermarketProduct.__typename;

        if (aftermarketProduct && aftermarketProduct.aftermarketProductCatalogDetails) {
          delete aftermarketProduct.aftermarketProductCatalogDetails.__typename;
        }

        if (aftermarketProduct && aftermarketProduct.config) {
          delete aftermarketProduct.config.__typename;
        }
      }

      return {
        title: product.title,
        cost: Number(product.cost),
        retailCost: Number(product.retailCost),
        thirdPartyId: product.thirdPartyId,
        proposalProductId: product.proposalProductId,
        markup: product.markup
          ? { ...product.markup, markup: product?.markup?.markup ?? 0, type: product?.markup?.type }
          : null,
        aftermarketProductToPartnerLinkId: product.aftermarketProductToPartnerLinkId,
        addons: product?.addons ?? [],
        aftermarketProduct,
      };
    }),
    menuOptionDetail:
      financeProgramDetails && financeProgramDetails[menuOption.name] ? financeProgramDetails[menuOption.name] : [],
  }));
}
