import React from "react";
import { CellDefaultProps } from "../../../types";
import { Box, Typography } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";

const proposalColorStatusByTitle: Record<string, string> = {
  Accepted: "#D3F2BD",
  Draft: "#FFE897",
  "Proposal Sent": "#C8E3F8",
  Funded: "#A8F6D9",
  Declined: "#FBA18D",
  "Viewed by customer": "#D9D8F8",
  "Awaiting Dealer Approval": "#e9d8f8",
};

export const StatusCell = ({ proposal }: CellDefaultProps) => {
  const classes = useStyles({ bgColor: proposalColorStatusByTitle[proposal?.status] ?? "#ACCBEDFF" });

  return (
    <Box className={classes.container}>
      <Typography align="center" noWrap component="span" variant="body2">
        {proposal.status ?? "Draft"}
      </Typography>
    </Box>
  );
};

const useStyles = makeStyles<Theme, { bgColor: string }>({
  container: {
    display: "inline-flex",
    alignItems: "center",
    borderRadius: "24px",
    padding: "4px 10px",
    justifyContent: "center",
    backgroundColor: ({ bgColor }) => bgColor,
  },
});
