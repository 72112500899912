import React from "react";
import { NavLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import cx from "classnames";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import sidebarStyle from "assets/jss/material-dashboard-pro-react/components/sidebarStyle.js";
import { Badge } from "@material-ui/core";
import { useRoutes } from "hooks/useRoutes";
import { RouteConfig } from "types";

//@ts-ignore
const useStyles = makeStyles(sidebarStyle);

interface Props {
  routeConfig: RouteConfig;
}

export const SidebarNavItem = ({ routeConfig }: Props) => {
  const classes = useStyles();

  const { currentRouteConfig } = useRoutes();
  return (
    <ListItem className={classes.item}>
      <NavLink
        to={routeConfig.dynamicPath || routeConfig.path}
        className={cx({ [classes.itemLink]: true }, { [classes.blue]: currentRouteConfig?.id === routeConfig.id })}
        style={{ overflowX: "hidden" }}
      >
        {routeConfig.icon && <routeConfig.icon className={classes.itemIcon} />}

        {routeConfig.isNewFeature ? (
          <Badge badgeContent={"NEW"} color="secondary">
            <ListItemText
              primary={routeConfig.name}
              disableTypography={true}
              className={classes.itemText}
              style={{ paddingLeft: 0 }}
            />
          </Badge>
        ) : (
          <ListItemText primary={routeConfig.name} disableTypography={true} className={classes.itemText} />
        )}
      </NavLink>
    </ListItem>
  );
};
