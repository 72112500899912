import React from "react";
import { RolesSetting } from "../pages/PortalConfigurationWizard/CommonComponents/RolesSetting";
import { TextFieldSetting } from "../pages/PortalConfigurationWizard/CommonComponents/TextFieldSetting";
import { SelectFieldSetting } from "../pages/PortalConfigurationWizard/CommonComponents/SelectFieldSetting";
import { AutocomleteFieldSetting } from "../pages/PortalConfigurationWizard/CommonComponents/AutocomleteFieldSetting";
import { DocumentsByTypeSettings } from "../pages/PortalConfigurationWizard/CommonComponents/DocumentsByTypeSettings";
import { MultipleSelectFieldSetting } from "../pages/PortalConfigurationWizard/CommonComponents/MultipleSelectFieldSetting";
import { PrescreenManagementTypeLabel } from "../pages/PortalConfigurationWizard/CommonComponents/PrescreenManagementTypeLabel";
import { PrescreenManagementTypeHelperText } from "../pages/PortalConfigurationWizard/CommonComponents/PrescreenManagementTypeHelperText";
import { configurableItemsTypes, documentsByKey, portalConfigurationTypes } from "../pages/Prequal/constants";
import {
  nonCreditMgrsRoles,
  vendorContactRole,
  vendorContactRoleHumanReadable,
  vendorContactRole as vendorContactRoles,
} from "../utils";

import _ from "lodash";
import { PrescreenTypes } from "../global";

const rolesSettingsKeys = {
  masked: "masked",
  hidden: "hidden",
  readOnly: "readOnly",
  hiddenOnPDF: "hiddenOnPDF",
  reportsHidden: "reportsHidden",
  assignedOnly: "assignedOnly",
  locationBased: "locationBased",
  documentsByTypeSettingsHidden: "documentsByTypeSettingsHidden",
  notificationsManagementHidden: "notificationsManagementHidden",
  cadmReadOnly: "cadmReadOnly",
  disallowVoСreation: "disallowVoСreation",
  // Prescreen
  prescreenType: "prescreenType",
  hiddenPrescreenPage: "hiddenPrescreenPage",
  hiddenPrescreenResults: "hiddenPrescreenResults",
  hiddenPrescreenSubmissionAccess: "hiddenPrescreenSubmissionAccess",
};

const rolesSettingsHumanReadable = {
  [rolesSettingsKeys.masked]: "Masked",
  [rolesSettingsKeys.hidden]: "Hidden",
  [rolesSettingsKeys.readOnly]: "Read-only",
  [rolesSettingsKeys.hiddenOnPDF]: "Hidden On PDF",
  [rolesSettingsKeys.reportsHidden]: "Reports Hidden",
  [rolesSettingsKeys.assignedOnly]: "Assigned Only",
  [rolesSettingsKeys.locationBased]: "Location Based",
  [rolesSettingsKeys.documentsByTypeSettingsHidden]: "Documents By Type Settings Hidden",
  [rolesSettingsKeys.notificationsManagementHidden]: "Notifications Management Hidden",
  // Prescreen
  [rolesSettingsKeys.prescreenType]: "Module type",
  [rolesSettingsKeys.hiddenPrescreenPage]: "Prescreen page hidden will be for",
  [rolesSettingsKeys.hiddenPrescreenResults]: "Prescreen results hidden for",
  [rolesSettingsKeys.hiddenPrescreenSubmissionAccess]: "Submission Access hidden for",
};

const getItemDefaultRolesSettings = (itemType, additionalParams) => {
  const getFieldRolesSettings = () => {
    return {
      [rolesSettingsKeys.masked]: [],
      [rolesSettingsKeys.hidden]: [],
      [rolesSettingsKeys.readOnly]: [],
      [rolesSettingsKeys.hiddenOnPDF]: [],
    };
  };

  const getBlockRolesSettings = () => {
    return {
      [rolesSettingsKeys.hidden]: [],
      [rolesSettingsKeys.readOnly]: [],
      [rolesSettingsKeys.hiddenOnPDF]: [],
    };
  };

  const getSectionRolesSettings = getBlockRolesSettings;

  const getModalRolesSettings = () => {
    return {
      [rolesSettingsKeys.hidden]: [],
      [rolesSettingsKeys.readOnly]: [],
      [rolesSettingsKeys.hiddenOnPDF]: [],
    };
  };

  const getScorecardSettings = () => {
    return {
      [rolesSettingsKeys.hidden]: nonCreditMgrsRoles,
      [rolesSettingsKeys.reportsHidden]: nonCreditMgrsRoles,
    };
  };

  const getLeadsSettings = () => {
    return {
      [rolesSettingsKeys.hidden]: [],
      /*       [rolesSettingsKeys.readOnly]: [], */
      [rolesSettingsKeys.assignedOnly]: [vendorContactRoles.sales_rep],
      [rolesSettingsKeys.locationBased]: [],
    };
  };

  const getOpportunitiesSettings = () => {
    return {
      [rolesSettingsKeys.hidden]: [],
      /*       [rolesSettingsKeys.readOnly]: [], */
      [rolesSettingsKeys.assignedOnly]: [],
      [rolesSettingsKeys.locationBased]: [],
    };
  };

  const getUserManagementSettings = () => {
    return {
      [rolesSettingsKeys.hidden]: [vendorContactRoles.sales_rep],
      [rolesSettingsKeys.readOnly]: [],
    };
  };

  const getSecurityManagementSettings = () => {
    return {
      [rolesSettingsKeys.assignedOnly]: [],
    };
  };
  const getAftermarketManagementSettings = () => {
    return {
      [rolesSettingsKeys.assignedOnly]: [],
      [rolesSettingsKeys.readOnly]: [],
    };
  };

  const getHealthCheckSettings = () => {
    return {
      [rolesSettingsKeys.hidden]: [],
    };
  };

  const getCreditReportsSettings = () => {
    return {
      [rolesSettingsKeys.hidden]: [],
    };
  };

  const getVoManagementSettings = () => {
    return {
      [rolesSettingsKeys.cadmReadOnly]: [],
      [rolesSettingsKeys.disallowVoСreation]: nonCreditMgrsRoles,
    };
  };

  const getDocumentsByTypeSettings = additionalParams => {
    const docKey = additionalParams.docSettings.key;
    const userRolesDefaultHiddenByDocKeys = {
      [documentsByKey.bank_statements.key]: [vendorContactRoles.sales_rep, vendorContactRoles.sales_mgr],
      [documentsByKey.tax_returns.key]: [vendorContactRoles.sales_rep, vendorContactRoles.sales_mgr],
      [documentsByKey.credit_report.key]: [vendorContactRoles.sales_rep, vendorContactRoles.sales_mgr],
      [documentsByKey.compliance.key]: [vendorContactRoles.sales_rep, vendorContactRoles.sales_mgr],
    };
    return {
      [rolesSettingsKeys.hidden]: userRolesDefaultHiddenByDocKeys[docKey]
        ? userRolesDefaultHiddenByDocKeys[docKey]
        : [],
    };
  };

  const getPortalSetupSettings = () => {
    return {
      [rolesSettingsKeys.hidden]: [vendorContactRoles.sales_rep, vendorContactRoles.sales_mgr],
      [rolesSettingsKeys.documentsByTypeSettingsHidden]: [],
      [rolesSettingsKeys.notificationsManagementHidden]: [vendorContactRoles.sales_rep, vendorContactRoles.sales_mgr],
    };
  };

  const getPrescreenSettings = () => {
    return {
      [rolesSettingsKeys.prescreenType]: PrescreenTypes.Off,
      [rolesSettingsKeys.hiddenPrescreenPage]: Object.values(vendorContactRole),
      [rolesSettingsKeys.hiddenPrescreenResults]: Object.values(vendorContactRole),
      [rolesSettingsKeys.hiddenPrescreenSubmissionAccess]: Object.values(vendorContactRole),
    };
  };

  const getRolesSettingsByItemTypeHandlers = {
    [configurableItemsTypes.section]: getSectionRolesSettings,
    [configurableItemsTypes.block]: getBlockRolesSettings,
    [configurableItemsTypes.field]: getFieldRolesSettings,
    [configurableItemsTypes.modal]: getModalRolesSettings,
    [configurableItemsTypes.scorecard]: getScorecardSettings,
    [configurableItemsTypes.leads]: getLeadsSettings,
    [configurableItemsTypes.opportunities]: getOpportunitiesSettings,
    [configurableItemsTypes.userManagement]: getUserManagementSettings,
    [configurableItemsTypes.securityManagement]: getSecurityManagementSettings,
    [configurableItemsTypes.aftermarket]: getAftermarketManagementSettings,
    [configurableItemsTypes.healthCheck]: getHealthCheckSettings,
    [configurableItemsTypes.creditReports]: getCreditReportsSettings,
    //[configurableItemsTypes.documents]: getDocumentsSettings,
    [configurableItemsTypes.documentsByTypeSettings]: getDocumentsByTypeSettings,
    [configurableItemsTypes.portalSetup]: getPortalSetupSettings,
    [configurableItemsTypes.voManagement]: getVoManagementSettings,
    [configurableItemsTypes.prescreen]: getPrescreenSettings,
  };
  return getRolesSettingsByItemTypeHandlers[itemType]
    ? getRolesSettingsByItemTypeHandlers[itemType](additionalParams)
    : null;
};

// TODO: Move getting default portal configurations to api-server!!!
const getDefaultPortalConfigurationByType = (type, vendorProfileId) => {
  const getScorecardDefaulJSONDefinition = () => {
    return {
      config: {
        lenderProfileId: "",
        rolesSettings: getItemDefaultRolesSettings(configurableItemsTypes.scorecard),
      },
    };
  };

  const getLeadsDefaultJSONDefinition = () => {
    return {
      config: {
        rolesSettings: getItemDefaultRolesSettings(configurableItemsTypes.leads),
      },
    };
  };

  const getOpportunitiesDefaultJSONDefinition = () => {
    return {
      config: {
        rolesSettings: getItemDefaultRolesSettings(configurableItemsTypes.opportunities),
      },
    };
  };

  const getUserManagementDefaultJSONDefinition = () => {
    return {
      config: {
        rolesSettings: getItemDefaultRolesSettings(configurableItemsTypes.userManagement),
      },
    };
  };

  const getSecurityManagementDefaultJSONDefinition = () => {
    return {
      config: {
        rolesSettings: getItemDefaultRolesSettings(configurableItemsTypes.securityManagement),
      },
    };
  };

  const getAftermarketManagementDefaultJSONDefinition = () => {
    return {
      config: {
        rolesSettings: getItemDefaultRolesSettings(configurableItemsTypes.aftermarket),
      },
    };
  };

  const getHealthCheckDefaultJSONDefinition = () => {
    return {
      config: {
        rolesSettings: getItemDefaultRolesSettings(configurableItemsTypes.healthCheck),
      },
    };
  };

  const getCreditReportsDefaultJSONDefinition = () => {
    return {
      config: {
        rolesSettings: getItemDefaultRolesSettings(configurableItemsTypes.creditReports),
      },
    };
  };

  const getDocumentsDefaultJSONDefinition = () => {
    const getDocumentsByTypeDefaultSettings = () => {
      return _.reduce(
        documentsByKey,
        (result, docSettings) => {
          const docKeysHiddenForLendersByDefault = [
            documentsByKey.decision_letter.key,
            documentsByKey.credit_report.key,
          ];
          result[docSettings.key] = {
            rolesSettings: getItemDefaultRolesSettings(configurableItemsTypes.documentsByTypeSettings, { docSettings }),
            hiddenForLenders: _.includes(docKeysHiddenForLendersByDefault, docSettings.key),
          };
          return result;
        },
        {}
      );
    };

    return {
      config: {
        //rolesSettings: getItemDefaultRolesSettings(configurableItemsTypes.documents),
        creditAppHiddenForLenderProfileIds: [],
        //creditReportsHiddenForLenderProfileIds: [],
        documentsByTypeSettings: getDocumentsByTypeDefaultSettings(),
      },
    };
  };

  const getPortalSetupDefaultJSONDefinition = () => {
    return {
      config: {
        rolesSettings: getItemDefaultRolesSettings(configurableItemsTypes.portalSetup),
      },
    };
  };

  const getVoChecklistTemplateDefaultJSONDefinition = () => {
    return {
      config: {
        checklist: [],
        hidden: true, // Needs to be reworked in future to use rolesSettings (common approach) instead of just hidden prop
      },
    };
  };

  const getVoManagementDefaultJSONDefinition = () => {
    return {
      config: {
        rolesSettings: getItemDefaultRolesSettings(configurableItemsTypes.voManagement),
      },
    };
  };

  const getPrescreenDefaultJSONDefinition = () => {
    return {
      config: {
        ...getItemDefaultRolesSettings(configurableItemsTypes.prescreen),
      },
    };
  };

  const getDefaultJSONDefinitionByTypeHandlers = {
    [portalConfigurationTypes.scorecard]: getScorecardDefaulJSONDefinition,
    [portalConfigurationTypes.leads]: getLeadsDefaultJSONDefinition,
    [portalConfigurationTypes.opportunities]: getOpportunitiesDefaultJSONDefinition,
    [portalConfigurationTypes.userManagement]: getUserManagementDefaultJSONDefinition,
    [portalConfigurationTypes.securityManagement]: getSecurityManagementDefaultJSONDefinition,
    [portalConfigurationTypes.aftermarket]: getAftermarketManagementDefaultJSONDefinition,
    [portalConfigurationTypes.healthCheck]: getHealthCheckDefaultJSONDefinition,
    [portalConfigurationTypes.creditReports]: getCreditReportsDefaultJSONDefinition,
    [portalConfigurationTypes.documents]: getDocumentsDefaultJSONDefinition,
    [portalConfigurationTypes.portalSetup]: getPortalSetupDefaultJSONDefinition,
    [portalConfigurationTypes.voChecklistTemplate]: getVoChecklistTemplateDefaultJSONDefinition,
    [portalConfigurationTypes.voManagement]: getVoManagementDefaultJSONDefinition,
    [portalConfigurationTypes.prescreen]: getPrescreenDefaultJSONDefinition,
  };

  const defaultJSONDefinitionByType = getDefaultJSONDefinitionByTypeHandlers[type]
    ? getDefaultJSONDefinitionByTypeHandlers[type]()
    : null;

  return {
    vendorProfileId,
    type,
    jsonDefinition: defaultJSONDefinitionByType,
  };
};

const configurableItemsSettings = {
  [configurableItemsTypes.section]: {
    title: "Section settings",
    settings: [
      {
        label: "Title",
        configurationKey: "title",
        component: TextFieldSetting,
      },
      {
        label: "Settings By Roles:",
        configurationKey: "rolesSettings",
        component: RolesSetting,
      },
    ],
  },
  [configurableItemsTypes.block]: {
    title: "Block settings",
    settings: [
      {
        label: "Title",
        configurationKey: "title",
        component: TextFieldSetting,
      },
      {
        label: "Settings By Roles:",
        configurationKey: "rolesSettings",
        component: RolesSetting,
      },
    ],
  },
  [configurableItemsTypes.field]: {
    title: "Field settings",
    settings: [
      {
        label: "Title",
        configurationKey: "title",
        component: TextFieldSetting,
      },
      {
        label: "Settings By Roles:",
        configurationKey: "rolesSettings",
        component: RolesSetting,
      },
    ],
  },
  [configurableItemsTypes.scorecard]: {
    title: "Scorecard Settings",
    settings: [
      {
        label: "Select the Lender whose card to show on the VO page:",
        configurationKey: "lenderProfileId",
        component: AutocomleteFieldSetting,
      },
      {
        label: "Settings By Roles:",
        configurationKey: "rolesSettings",
        component: RolesSetting,
      },
    ],
  },
  [configurableItemsTypes.leads]: {
    title: "Leads",
    settings: [
      {
        label: "Settings By Roles:",
        configurationKey: "rolesSettings",
        component: RolesSetting,
      },
    ],
  },
  [configurableItemsTypes.opportunities]: {
    title: "Opportunities & Proposals",
    settings: [
      {
        label: "Settings By Roles:",
        configurationKey: "rolesSettings",
        component: RolesSetting,
        customHelperText: {
          [rolesSettingsKeys.hidden]: "Opportunities and Proposals will be hidden for selected roles",
          [rolesSettingsKeys.assignedOnly]:
            "The selected roles will be able to see only those Opportunities and Proposals that are assigned to them",
          [rolesSettingsKeys.locationBased]:
            "Selected roles will be able to see only Opportunities and Proposals from their location",
        },
      },
    ],
  },
  [configurableItemsTypes.userManagement]: {
    title: "User Management",
    settings: [
      {
        label: "Settings By Roles:",
        configurationKey: "rolesSettings",
        component: RolesSetting,
      },
    ],
  },
  [configurableItemsTypes.securityManagement]: {
    title: "Security Management",
    settings: [
      {
        label: "MFA Enabled By Roles:",
        configurationKey: "rolesSettings",
        component: RolesSetting,
      },
    ],
  },
  [configurableItemsTypes.healthCheck]: {
    title: "Health Check",
    settings: [
      {
        label: "Settings By Roles:",
        configurationKey: "rolesSettings",
        component: RolesSetting,
      },
    ],
  },
  [configurableItemsTypes.creditReports]: {
    title: "Access to Pull Credit Reports",
    settings: [
      {
        label: "Settings By Roles:",
        configurationKey: "rolesSettings",
        component: RolesSetting,
      },
    ],
  },
  [configurableItemsTypes.documents]: {
    title: "Documents Settings",
    settings: [
      {
        label: "Allow Credit Application for the following Lenders:",
        configurationKey: "creditAppHiddenForLenderProfileIds",
        component: AutocomleteFieldSetting,
      },
      /*       {
        label: "Allow Credit Reports for the following Lenders:",
        configurationKey: "creditReportsHiddenForLenderProfileIds",
        component: AutocomleteFieldSetting,
      }, */
      {
        label: "Documents By Type Settings:",
        configurationKey: "documentsByTypeSettings",
        component: DocumentsByTypeSettings,
      },
    ],
  },
  [configurableItemsTypes.portalSetup]: {
    title: "Portal Setup Settings",
    settings: [
      {
        label: "Settings By Roles:",
        configurationKey: "rolesSettings",
        component: RolesSetting,
      },
    ],
  },
  [configurableItemsTypes.voManagement]: {
    title: "Opportunity Management",
    settings: [
      {
        label: "Settings By Roles:",
        configurationKey: "rolesSettings",
        optionsByRoleSettingKey: {
          [rolesSettingsKeys.cadmReadOnly]: {
            label: "Ability to Edit Credit Applications",
            helperText: "Only user roles selected here will have this ability",
            isInversed: true,
          },
          [rolesSettingsKeys.disallowVoСreation]: {
            label: "Create New Opportunity Manually",
            helperText: "Only user roles selected here will have this ability",
            isInversed: true,
          },
        },
        component: RolesSetting,
      },
    ],
  },
  [configurableItemsTypes.aftermarket]: {
    title: "Aftermarket Management",
    settings: [
      {
        label: "Settings By Roles:",
        configurationKey: "rolesSettings",
        optionsByRoleSettingKey: {
          [rolesSettingsKeys.assignedOnly]: {
            label: "Able to change/update Aftermarket Products Page",
            helperText: "Select user roles that will have this ability",
            isInversed: false,
          },
          [rolesSettingsKeys.readOnly]: {
            label: "Able to read/see Aftermarket Products Page",
            helperText: "Select user roles that will have this ability",
            isInversed: false,
          },
        },
        component: RolesSetting,
      },
    ],
  },
  [configurableItemsTypes.prescreen]: {
    title: "Prescreen Management",
    settings: [
      {
        label: React.createElement(PrescreenManagementTypeLabel),
        configurationKey: rolesSettingsKeys.prescreenType,
        component: SelectFieldSetting,
        selectLabel: "Type",
        helperText: React.createElement(PrescreenManagementTypeHelperText),
        options: [
          { label: "Prescreen", value: PrescreenTypes.Prescreen },
          { label: "LenderWaterfall", value: PrescreenTypes.LenderWaterfall },
          { label: "Off", value: PrescreenTypes.Off },
          { label: "Demo", value: PrescreenTypes.Demo },
        ],
      },
      {
        label: "Builder hidden for",
        configurationKey: rolesSettingsKeys.hiddenPrescreenPage,
        component: MultipleSelectFieldSetting,
        selectLabel: "Hidden for:",
        helperText: "Builder will be hidden for",
        options: Object.entries(vendorContactRoleHumanReadable).reduce((acc, [value, label]) => {
          acc.push({ label, value });
          return acc;
        }, []),
      },
      {
        label: "Prescreen results hidden for",
        configurationKey: rolesSettingsKeys.hiddenPrescreenResults,
        component: MultipleSelectFieldSetting,
        selectLabel: "Hidden for:",
        helperText: "Prescreen results will be hidden for",
        options: Object.entries(vendorContactRoleHumanReadable).reduce((acc, [value, label]) => {
          acc.push({ label, value });
          return acc;
        }, []),
      },
      {
        label: "Submission Access hidden for",
        configurationKey: rolesSettingsKeys.hiddenPrescreenSubmissionAccess,
        component: MultipleSelectFieldSetting,
        selectLabel: "Hidden for:",
        helperText: "Submission Access will be hidden for",
        options: Object.entries(vendorContactRoleHumanReadable).reduce((acc, [value, label]) => {
          acc.push({ label, value });
          return acc;
        }, []),
      },
    ],
  },
};

const getHiddenDocumentsKeys = (documentsPortalConfiguration, userRole) => {
  return _(documentsPortalConfiguration)
    .chain()
    .get("jsonDefinition.config.documentsByTypeSettings")
    .pickBy((value, key) => _.includes(value.rolesSettings.hidden, userRole))
    .keys()
    .value();
};

const rejectHiddenDocumentTypeOptions = (documentsPortalConfiguration, documentTypeOptions, userRole) => {
  const documentsKeysHidden = getHiddenDocumentsKeys(documentsPortalConfiguration, userRole);
  return _.reject(documentTypeOptions, documentSetting => _.includes(documentsKeysHidden, documentSetting.key));
};

export {
  // methods
  getItemDefaultRolesSettings,
  getDefaultPortalConfigurationByType,
  //
  getHiddenDocumentsKeys,
  rejectHiddenDocumentTypeOptions,
  // constants
  rolesSettingsKeys,
  rolesSettingsHumanReadable,
  configurableItemsSettings,
};
