import { MarkupType, ProposalProduct } from "@trnsact/trnsact-shared-types/dist/generated";

type Field = "cost" | "markup.markup" | "retailCost";

interface Values {
  cost: number;
  markup: number;
  retailCost: number;
  markupType: MarkupType;
}

interface RulesResults {
  costNextValue: number;
  markupNextValue: number;
  retailCostNextValue: number;
}

function getMarkupValue(markupType: MarkupType, markup: number) {
  return markupType === MarkupType.Percentage ? markup / 100 : markup;
}

export function proposalProductUpdateDependentFields(
  field: Field,
  nextValue: number,
  proposalProduct: ProposalProduct,
  values: Values
) {
  const { cost, retailCost, markup, markupType } = values;

  const rules: Record<Field, () => RulesResults> = {
    cost: () => {
      const markupValue = getMarkupValue(markupType, markup);

      let costNextValue = nextValue;
      let markupNextValue = markup;
      let retailCostNextValue = retailCost;

      if (!markupValue && !proposalProduct.retailCost) {
        retailCostNextValue = nextValue;
      }

      if (markupValue && !proposalProduct.retailCost) {
        if (markupType === MarkupType.Percentage) {
          retailCostNextValue = nextValue + nextValue * markupValue;
        }

        if (markupType === MarkupType.Flat) {
          retailCostNextValue = nextValue + markupValue;
        }
      }

      if (markupValue && proposalProduct.retailCost) {
        if (markupType === MarkupType.Percentage) {
          retailCostNextValue = nextValue + nextValue * markupValue;
        }

        if (markupType === MarkupType.Flat) {
          retailCostNextValue = nextValue + markupValue;
        }
      }

      return {
        costNextValue,
        markupNextValue,
        retailCostNextValue,
      };
    },
    "markup.markup": () => {
      const markupValue = getMarkupValue(markupType, nextValue);

      let costNextValue = cost;
      let markupNextValue = nextValue;
      let retailCostNextValue = retailCost;

      if (cost && !retailCost) {
        if (markupType === MarkupType.Percentage) {
          retailCostNextValue = cost + cost * markupValue;
        }

        if (markupType === MarkupType.Flat) {
          retailCostNextValue = cost + markupValue;
        }
      }

      if (!cost && retailCost) {
        if (markupType === MarkupType.Percentage) {
          costNextValue = retailCost - markupValue * 100;
        }

        if (markupType === MarkupType.Flat) {
          costNextValue = retailCost - markupValue;
        }
      }

      if (cost && retailCost) {
        if (markupType === MarkupType.Percentage) {
          retailCostNextValue = markupValue * cost + cost;
        }

        if (markupType === MarkupType.Flat) {
          retailCostNextValue = nextValue + cost;
        }
      }

      if (!markupValue && retailCost) {
        if (markupType === MarkupType.Percentage) {
          markupNextValue = retailCost - markupValue;
        }

        if (markupType === MarkupType.Flat) {
          markupNextValue = (retailCost - markupValue) / 100;
        }
      }

      return {
        costNextValue,
        markupNextValue,
        retailCostNextValue,
      };
    },
    retailCost: () => {
      const markupValue = getMarkupValue(markupType, markup);

      let costNextValue = cost;
      let markupNextValue = markup;
      let retailCostNextValue = nextValue;

      if (markupValue && !cost) {
        if (markupType === MarkupType.Percentage) {
          costNextValue = nextValue / (1 + markupValue);
        }

        if (markupType === MarkupType.Flat) {
          costNextValue = nextValue - markupValue;
        }
      }

      if (markupValue && cost) {
        if (markupType === MarkupType.Percentage) {
          markupNextValue = ((nextValue - cost) / cost) * 100;
        }

        if (markupType === MarkupType.Flat) {
          markupNextValue = nextValue - cost;
        }
      }

      if (!markupValue && cost) {
        if (markupType === MarkupType.Percentage) {
          markupNextValue = ((nextValue - cost) / cost) * 100;
        }

        if (markupType === MarkupType.Flat) {
          markupNextValue = nextValue - cost;
        }
      }

      return {
        costNextValue,
        markupNextValue,
        retailCostNextValue,
      };
    },
  };

  return rules[field]();
}
