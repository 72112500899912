import { LENDER_WATERFALL_FACT_NAMES_NUMERIC } from "@trnsact/types-lender-waterfall/dist/models/LenderWaterfallFactNamesNumeric";
import { LENDER_WATERFALL_FACT_NAMES_STRING } from "@trnsact/types-lender-waterfall/dist/models/LenderWaterfallFactNamesString";
import { LENDER_WATERFALL_FACT_NAMES_BOOLEAN } from "@trnsact/types-lender-waterfall/dist/models/LenderWaterfallFactNamesBoolean";
import { determineNumberValue } from "./determineNumberValue";
import { FinanceProgramForm } from "../schema";

export function mapCriteriaFormValuesToConditions(
  conditions: FinanceProgramForm["program"]["third"]["conditions"]
): any {
  return conditions.map(condition => {
    if (condition.rule in LENDER_WATERFALL_FACT_NAMES_NUMERIC) {
      const parsedMinValue = determineNumberValue(condition.number.min);
      const parsedMaxValue = determineNumberValue(condition.number.max);

      return {
        fact: condition.rule as LENDER_WATERFALL_FACT_NAMES_NUMERIC,
        minValue: parsedMinValue ? String(parsedMinValue) : null,
        maxValue: parsedMaxValue ? String(parsedMaxValue) : null,
        exactMatch: undefined,
      };
    }

    if (condition.rule in LENDER_WATERFALL_FACT_NAMES_STRING) {
      return {
        fact: condition.rule as LENDER_WATERFALL_FACT_NAMES_STRING,
        exactMatch: condition.string.value.map(option => option?.value),
      };
    }

    return {
      fact: condition.rule as LENDER_WATERFALL_FACT_NAMES_BOOLEAN,
      value: condition.boolean.value,
      exactMatch: undefined,
    };
  });
}
