import { DocumentNode } from "graphql";
import { ProposalsViewTypes } from "../types";
import { GET_PROPOSALS_FOR_HUB_TABLE, GET_PROPOSALS_FOR_HUB_TABLE_BY_ACCOUNT } from "../api";

export const SelectAllValue = "Select All";

export const hubTableApiQueryByType: Record<ProposalsViewTypes, DocumentNode> = {
  [ProposalsViewTypes.All]: GET_PROPOSALS_FOR_HUB_TABLE_BY_ACCOUNT,
  [ProposalsViewTypes.My]: GET_PROPOSALS_FOR_HUB_TABLE,
};
