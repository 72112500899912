import { PortalConfiguration, PortalConfigurationType } from "@trnsact/trnsact-shared-types";
import { defaultConfigByType } from "../constants";

export const getPortalConfigurationDefaultConfig = (
  vendorProfileId: string,
  type: PortalConfigurationType
): PortalConfiguration => ({
  vendorProfileId,
  type,
  jsonDefinition: {
    config: defaultConfigByType[type],
  },
});
