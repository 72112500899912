import { z } from "zod";
import moment from "moment";
import {
  LENDER_WATERFALL_FACT_NAMES_BOOLEAN,
  LENDER_WATERFALL_FACT_NAMES_NUMERIC,
  LENDER_WATERFALL_FACT_NAMES_STRING,
} from "@trnsact/types-lender-waterfall";
import { FinanceProgramType, PaymentPeriodTiming, FinanceProgramRateType } from "@trnsact/trnsact-shared-types";
import { FinanceProgramFormSteps, MarkupMethods, Structures } from "./types";

export const financeProgramSchema = z.object({
  step: z.nativeEnum(FinanceProgramFormSteps),
  program: z.object({
    id: z.string().nullable(),
    first: z.object({
      name: z.string().min(1, { message: "Required field" }),
      displayName: z.string().min(1, { message: "Required field" }),
      guidelines: z.any(),
      lenders: z
        .object({
          label: z.string(),
          value: z.string(),
        })
        .array(),
      dealers: z
        .object({
          label: z.string(),
          value: z.string(),
        })
        .array(),
      notes: z.string(),
    }),
    second: z
      .object({
        rateType: z.nativeEnum(FinanceProgramRateType),
        terms: z.array(z.string()).refine(arr => arr.length > 0, {
          message: "Please, select at least one term",
        }),
        rate: z
          .number({
            message: "Rate should be equal or greater than 0",
          })
          .gte(0, {
            message: "Rate should be equal or greater than 0",
          }),
        specifyRates: z
          .object({
            term: z.string(),
            rate: z
              .number({
                message: "Rate should be equal or greater than 0",
              })
              .gte(0, {
                message: "Rate should be equal or greater than 0",
              }),
          })
          .array(),
        customerRate: z.number().gte(0, {
          message: "Rate should be equal or greater than 0",
        }),
        isSpecifyRate: z.boolean(),
        structure: z.nativeEnum(Structures),
        method: z.nativeEnum(MarkupMethods),
        dealerMarkup: z
          .number({
            message: "Markup should be equal or greater than 0",
          })
          .gte(0),
        programType: z.nativeEnum(FinanceProgramType),
        advancePayment: z.string().min(1, { message: "Required field" }),
        paymentCalculationMethod: z.nativeEnum(PaymentPeriodTiming),
        residual: z.number({
          message: "Residual should be equal or greater than 0",
        }),
        isValidityDates: z.boolean(),
        startDate: z.any(),
        endDate: z.any(),
        fee: z
          .object({
            amount: z
              .number({
                message: "Amount should be equal or greater than 0",
              })
              .gte(0, {
                message: "Amount should be equal or greater than 0",
              }),
            description: z.string(),
            isFinanced: z.boolean(),
          })
          .array(),
      })
      .refine(
        data => {
          if (data.isValidityDates) return !!data.startDate && !!data.endDate;
          return true;
        },
        {
          path: ["startDate", "endDate"],
          message: "Required",
        }
      ),
    third: z.object({
      criteria: z
        .object({
          label: z.string(),
          value: z.string(),
        })
        .nullable(),
      conditions: z
        .object({
          rule: z.union([
            z.nativeEnum(LENDER_WATERFALL_FACT_NAMES_BOOLEAN),
            z.nativeEnum(LENDER_WATERFALL_FACT_NAMES_NUMERIC),
            z.nativeEnum(LENDER_WATERFALL_FACT_NAMES_STRING),
          ]),
          boolean: z.object({
            value: z.boolean(),
          }),
          number: z.object({
            min: z.any().nullable(),
            max: z.any().nullable(),
          }),
          string: z.object({
            value: z.any().array(),
          }),
          isRequiredToQuote: z.boolean(),
        })
        .array(),
    }),
    fourth: z.object({}),
  }),
});

export type FinanceProgramForm = z.infer<typeof financeProgramSchema>;

export const initialFormValue: FinanceProgramForm = {
  step: FinanceProgramFormSteps.First,
  program: {
    id: null,
    first: {
      name: "",
      notes: "",
      lenders: [],
      dealers: [],
      guidelines: "",
      displayName: "",
    },
    second: {
      fee: [],
      rate: 0,
      terms: [],
      residual: 0,
      customerRate: 0,
      dealerMarkup: 0,
      specifyRates: [],
      advancePayment: "0",
      isSpecifyRate: false,
      isValidityDates: false,
      structure: Structures.None,
      programType: FinanceProgramType.Loan,
      rateType: FinanceProgramRateType.InterestRate,
      method: MarkupMethods.CustomerRate,
      endDate: moment().toDate(),
      startDate: moment().toDate(),
      paymentCalculationMethod: PaymentPeriodTiming.BeginningOfPeriod,
    },
    third: {
      criteria: null,
      conditions: [],
    },
    fourth: {},
  },
};
