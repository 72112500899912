import React from "react";
import { useFormContext } from "react-hook-form";
import { FinanceProgramForm } from "../../../../schema";
import { CurrencyInputField, InputField } from "../../../../../../components/form";
import { LENDER_WATERFALL_FACT_NAMES_NUMERIC } from "@trnsact/types-lender-waterfall/dist/models/LenderWaterfallFactNamesNumeric";

interface Props {
  index: number;
  type: LENDER_WATERFALL_FACT_NAMES_NUMERIC;
}

export const NumberFieldsByRule = ({ index, type }: Props) => {
  const { control } = useFormContext<FinanceProgramForm>();

  const currencyInputs = [
    LENDER_WATERFALL_FACT_NAMES_NUMERIC.DOWN_PAYMENT,
    LENDER_WATERFALL_FACT_NAMES_NUMERIC.DEAL_SIZE,
  ];

  return (
    <>
      {currencyInputs.includes(type) ? (
        <>
          <CurrencyInputField
            label="Min value"
            control={control}
            textFieldProps={{ size: "small", fullWidth: true }}
            name={`program.third.conditions.${index}.number.min`}
          />

          <CurrencyInputField
            label="Max value"
            control={control}
            textFieldProps={{ size: "small", fullWidth: true }}
            name={`program.third.conditions.${index}.number.max`}
          />
        </>
      ) : (
        <>
          <InputField
            label="Min value"
            control={control}
            name={`program.third.conditions.${index}.number.min`}
            inputProps={{
              type: "number",
            }}
          />

          <InputField
            label="Max value"
            control={control}
            name={`program.third.conditions.${index}.number.max`}
            inputProps={{
              type: "number",
            }}
          />
        </>
      )}
    </>
  );
};
