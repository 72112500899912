import React, { useMemo } from "react";
import { DeleteOutline } from "@material-ui/icons";
import { useFormContext, useWatch } from "react-hook-form";
import { Box, IconButton, Typography } from "@material-ui/core";
import { allFacts } from "../../../../constants";
import { FinanceProgramForm } from "../../../../schema";
import { SelectOption } from "../../../../../../global";
import { ConditionsFieldsByRuleType } from "./ConditionsFieldsByRuleType";
import { SelectField, SwitchField } from "../../../../../../components/form";

interface Props {
  index: number;
  onRemove: VoidFunction;
}

export const ChooseCriteriaRuleField = ({ index, onRemove }: Props) => {
  const { control, getValues } = useFormContext<FinanceProgramForm>();
  const { program } = useWatch<FinanceProgramForm>({ control });

  const rulesOptionsFilteredBySelected = useMemo(() => {
    const addedCriteriaTypes = getValues("program.third.conditions").map(({ rule }) => rule);

    const currentValue = allFacts.find(fact => fact.value === addedCriteriaTypes[index]);

    return [currentValue, ...allFacts.filter(({ value }) => !addedCriteriaTypes.includes(value))] as SelectOption[];
  }, [program]);

  return (
    <Box className="arrayField">
      <Box className="arrayFieldHeader">
        <IconButton className="customIconBtn" size="small" onClick={onRemove}>
          <DeleteOutline color="error" />
        </IconButton>

        <Typography component="span" variant="subtitle2">
          Condition #{index + 1}
        </Typography>
      </Box>

      <Box className="formRow">
        <SelectField
          label="Rule"
          control={control}
          style={{ flex: 1 }}
          options={rulesOptionsFilteredBySelected}
          name={`program.third.conditions.${index}.rule`}
        />

        <ConditionsFieldsByRuleType index={index} />
      </Box>

      <Box>
        <SwitchField
          control={control}
          label="Required to Quote?"
          name={`program.third.conditions.${index}.isRequiredToQuote`}
          tooltip="Requires this condition to be met in order to generate a proposal"
        />
      </Box>
    </Box>
  );
};
