import React from "react";
import { Button, ButtonGroup } from "@material-ui/core";
import { ProposalsViewTypes } from "../../types";

interface Props {
  currentType: ProposalsViewTypes;
  onTypeChange: (nextType: ProposalsViewTypes) => void;
}

export const ProposalsTypeManager = ({ currentType, onTypeChange }: Props) => {
  const viewManagerConfiguration = [
    {
      label: "My Proposals",
      value: ProposalsViewTypes.My,
    },
    {
      label: "All Proposals",
      value: ProposalsViewTypes.All,
    },
  ];

  return (
    <ButtonGroup color="primary" fullWidth>
      {viewManagerConfiguration.map(({ label, value }) => (
        <Button
          key={value}
          variant={currentType === value ? "contained" : "outlined"}
          onClick={() => {
            if (currentType === value) return;
            onTypeChange(value);
          }}
        >
          {label}
        </Button>
      ))}
    </ButtonGroup>
  );
};
