import React, { useEffect } from "react";
import { useQuery } from "react-apollo";
import { useSelector } from "react-redux";
import { deskingSelectors } from "../../model";
import { BaseTable } from "modules/proposals";
import { GET_PROPOSALS_TABLE_DATA } from "../../api";
import { Proposal } from "@trnsact/trnsact-shared-types";
import { ProposalsTableDataResponse, ProposalsTableDataVariables } from "../../api/types";
import { CardContainer } from "../../../../components/shared/CardContainer/CardContainer";

const config = require("../../../../config");

interface Props {
  vendorGUID: string;
}

export const ProposalsTable = ({ vendorGUID }: Props) => {
  const voId = useSelector((state: any) => state.vo.vendorOpportunityId);
  const proposalCreated = useSelector(deskingSelectors.proposalCreated);

  const { data, refetch, loading } = useQuery<ProposalsTableDataResponse, ProposalsTableDataVariables>(
    GET_PROPOSALS_TABLE_DATA,
    {
      skip: !voId,
      variables: { voId },
    }
  );

  useEffect(() => {
    if (proposalCreated) refetch();
  }, [proposalCreated]);

  const getProposalLink = (proposal: Proposal) => {
    window.open(
      `https://app${
        config.environmentName === "dev" ? ".dev" : ""
      }.trnsact.com/ps/?vendorGUID=${vendorGUID}&proposalId=${proposal.id}&viewOnly=true`,
      "_blank"
    );
  };

  const handleGoToProposal = (proposal: Proposal) => {
    window.open(`/proposalDetail/${proposal.id}`, "_blank");
  };

  return (
    <CardContainer title="Proposals">
      <BaseTable
        loading={loading}
        onGoToProposal={handleGoToProposal}
        onGoToProposalDetail={getProposalLink}
        tableData={(data?.proposals as any) ?? []}
      />
    </CardContainer>
  );
};
