import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CardContainer } from "components/shared/CardContainer/CardContainer";
import { menuConstructorActions, menuConstructorSelectors } from "modules/aftermarketMenuConstructor/model";
import { GeneralInformationForm } from "./GeneralInformationForm";
import { usePermissions } from "modules/aftermarketMenuConstructor/hooks/usePermissions";
import { MenuTemplateGeneralFormInputs } from "modules/aftermarketMenuConstructor/types";
import { useFormContext } from "react-hook-form";

export const GeneralInformationCard = () => {
  const isLoading = useSelector(menuConstructorSelectors.isLoading) as boolean;

  const dispatch = useDispatch();

  const {
    control,
    formState: { errors },
    watch,
  } = useFormContext<MenuTemplateGeneralFormInputs>();

  const { hasWriteAccess } = usePermissions();

  useEffect(() => {
    const subscription = watch(value => {
      dispatch(menuConstructorActions.setMenuGeneralFormValues(value as MenuTemplateGeneralFormInputs));
    });

    return subscription.unsubscribe;
  }, [watch]);

  return (
    <CardContainer title="General" isLoading={isLoading}>
      <GeneralInformationForm control={control} errors={errors} hasWriteAccess={hasWriteAccess} />
    </CardContainer>
  );
};
