import { useContext, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";

import BusinessIcon from "@material-ui/icons/Business";
import PersonIcon from "@material-ui/icons/Person";
import ViewListIcon from "@material-ui/icons/ViewList";
import NoteAddIcon from "@material-ui/icons/NoteAdd";
import StorefrontIcon from "@material-ui/icons/Storefront";
import Group from "@material-ui/icons/Group";
import FormatListNumberedIcon from "@material-ui/icons/FormatListNumbered";
import PageList from "../pages/VendorOpportunity/PageOpportunitiesList";
import SubmissionsHub from "../pages/SubmissionsHub";
import CompanyProfile from "../pages/CompanyProfile/CompanyProfile";
import Receipt from "../pages/CreditApp/Receipt";
import AppError from "../pages/CreditApp/AppError";
import PQTView from "../pages/PaymentQuoteTool/PQT.js";
import PQT from "../pages/PQT";
import SendLink from "../pages/PaymentQuoteTool/SendLink.js";
import PrequalApp from "../pages/Prequal/PrequalApp";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import DynamicFeedIcon from "@material-ui/icons/DynamicFeed";
import SendIcon from "@material-ui/icons/Send";
import DeviceHubIcon from "@material-ui/icons/DeviceHub";
import PrequalReceipt from "../pages/Prequal/PrequalReceipt";
import ChatPage from "../pages/ChatPage";
import PageRepList from "../pages/DealerAdmin/RepList";
import { default as UserProfileComponent } from "../pages/UserProfile";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import BarChartIcon from "@material-ui/icons/BarChart";
import ConsolidatedTasks from "../pages/ConsolidatedTasks";
import PageLeadList from "../pages/LeadSheet/LeadsList";
import DnsIcon from "@material-ui/icons/Dns";
import LenderAdmin from "../pages/LenderAdmin";
import LenderDetails from "../pages/LenderAdmin/LenderDetails";
import LeadDetails from "../pages/LeadSheet/LeadDetails";
import CreditAppv2 from "../pages/CreditApp/CreditApp";
import ChatIcon from "@material-ui/icons/Chat";
import ProposalDetails from "../pages/Proposals/ProposalDetails";
import { PrescreenModuleContainer as LenderWaterfall } from "../pages/LenderWaterfall/PrescreenModuleContainer";
import PageCreditPrograms from "../pages/CreditPrograms/CreditPrograms";
import CreditProductDetails from "../pages/CreditPrograms/CreditProduct";
import WebsiteIntegration from "../pages/WebsiteIntegration";
import WebIcon from "@material-ui/icons/Web";
import TuneIcon from "@material-ui/icons/Tune";
import DocList from "../pages/DocMgmt/DocList";
import PostAddIcon from "@material-ui/icons/PostAdd";
import AccountDetails from "../pages/VendorOpportunity/DetailPages/AccountDetails";
import OwnerDetails from "../pages/VendorOpportunity/DetailPages/OwnerDetails";
import DealerList from "../pages/PartnerAdmin/DealerList";
import AddDealer from "../pages/PartnerAdmin/AddDealer";
import PartnerDealerAccountDetails from "../pages/PartnerAdmin/PartnerDealerDetail";
import LocationList from "pages/LocationMgmt/LocationList";
import LocationDetail from "pages/LocationMgmt/LocationDetail";
import DealerDashboards from "pages/DealerDashboards/DealerDashboards";
import AppsIcon from "@material-ui/icons/Apps";
import ShowChart from "@material-ui/icons/ShowChart";
import { LocalAtmRounded, Work as WorkIcon } from "@material-ui/icons";
import { Constructor } from "pages/DynamicOCA/Constructor";
import CustomersPage from "pages/Customers";
import PortalConfigurationWizard from "pages/PortalConfigurationWizard/PortalConfigurationWizard";
import PortalSetup from "pages/PortalSetup/PortalSetup";
import FinanceProgramMgmt from "pages/FinanceProgramMgmt";
import { AddAftermarketProduct, EditAftermarketProduct, ListAftermarketProducts } from "pages/AftermarketProductMgmt";
import ReportsPage from "../pages/Reports";
import ReportsPageLender from "../copiedLP/src/pages/Reports";
import { WorkflowUpsertPage, WorkflowsListPage } from "pages/Workflows";
import { PageOpportunityDetailsContainer } from "../pages/VendorOpportunity/DetailPages";
import { AftermarketAddEditMenuTemplate } from "modules/aftermarketMenuConstructor";
import { PrescreenPage } from "../modules/prescreen";
import { Account, PortalConfigurationRolesSettingsKeys, RouteConfig } from "types";
import {
  AdminRole,
  PortalConfigurationType,
  UserProfile,
  VendorContactRole,
  VendorProfile,
} from "@trnsact/trnsact-shared-types";
import { CommonDataContext } from "contexts/CommonDataContext";
import { useSelector } from "react-redux";
import { portalConfigurationsSelectors } from "redux/portalConfigurationReducer";
import CreditSubmissionsList from "copiedLP/src/pages/CreditSubmissions/CreditSubmissionsList";
import { CreditSubmissionDetailsPageV2 } from "pages/details/CreditSubmissionDetailsPageV2";
import CreditSubmissionDetails from "copiedLP/src/pages/CreditSubmissions/CreditSubmissionDetails";
import AddDealers from "copiedLP/src/pages/AddDealers/AddDealers";
import DealerManagementList from "copiedLP/src/pages/DealerMgmt/DealerList";
import DealerDetails from "copiedLP/src/pages/DealerMgmt/DealerDetails";
import { default as LenderConsolidatedTasks } from "copiedLP/src/pages/CreditSubmissions/ConsolidatedTasjs";
import { default as LenderProposalDetails } from "copiedLP/src/pages/CreditSubmissions/ProposalDetails";
import { useRoutesVisibilityChecks } from "./useRoutesVisibilityChecks";
import { usePortalConfigurationChecks } from "modules/portalConfigurations";
import InsertDriveFileOutlinedIcon from "@material-ui/icons/InsertDriveFileOutlined";
import { ProposalsPage } from "../modules/proposals";

const PETE_DYNAMICS_ACCOUNT_ID = "a3fd7f13-46df-eb11-8139-066ef92796c9";
const TEST_VENDOR_V2_DYNAMICS_ACCOUNT_ID = "38e27536-7ccd-e811-80fa-005056b05a0f";

export function useRoutes() {
  const location = useLocation();
  const { pathname, search } = location;

  const { isDealerUser, isLenderUser, isCommonPartner } = useContext(CommonDataContext);

  const {
    byUserRole,
    checkIfOpportunitiesVisible,
    checkIfAftermarketVisible,
    checkIfLocationsVisible,
    checkIfPrescreenVisible,
  } = useRoutesVisibilityChecks();

  const { checkPortalConfigEntryForCurrentUserRole } = usePortalConfigurationChecks();

  const userProfile = useSelector((state: any) => state.userProfile) as UserProfile;
  const account = useSelector((state: any) => state.account) as Account;
  const vendorProfile = useSelector((state: any) => state.vp) as VendorProfile;
  const portalConfigurationData = useSelector((state: any) => portalConfigurationsSelectors.configData(state));
  const isUserHaveAccessToPrescreenPage = useSelector(state =>
    portalConfigurationsSelectors.checkIfUserHaveAccessToPrescreenPage(state, userProfile)
  );

  const isSuperAdmin = userProfile.adminRole === AdminRole.Super;

  const appRoutes: RouteConfig[] = [
    {
      id: "dealerDashboard",
      showInSidebarNav: true,
      exact: true,
      path: "/dashboard",
      name: "Dashboard",
      icon: ShowChart,
      component: DealerDashboards,
      isVisilbe: () => {
        if (isCommonPartner) {
          return false;
        }
        if (isDealerUser) {
          return isSuperAdmin || (isDealerUser && byUserRole(userProfile, VendorContactRole.CreditMgr));
        }
        return false;
      },
    },
    {
      id: "vo",
      showInSidebarNav: true,
      exact: true,
      path: "/vo",
      name: "Opportunities",
      icon: ViewListIcon,
      component: PageList,
      isVisilbe: () => checkIfOpportunitiesVisible(isDealerUser, isLenderUser, vendorProfile),
    },
    {
      id: "proposals-hub",
      showInSidebarNav: true,
      exact: true,
      path: "/proposals-hub",
      isNewFeature: true,
      name: "Proposals",
      icon: InsertDriveFileOutlinedIcon,
      component: ProposalsPage,
      isVisilbe: () => checkIfOpportunitiesVisible(isDealerUser, isLenderUser, vendorProfile),
    },
    {
      id: "voId",
      showInSidebarNav: false,
      exact: false,
      path: "/vo/:id",
      name: "Opportunity Detail",
      type: "OpportunityDetail",
      icon: ViewListIcon,
      component: PageOpportunityDetailsContainer,
      fastTrackOption: true,
      isVisilbe: () => checkIfOpportunitiesVisible(isDealerUser, isLenderUser, vendorProfile),
    },
    {
      id: "submissionsList",
      showInSidebarNav: true,
      exact: true,
      path: "/cs",
      name: "Submissions List",
      icon: ViewListIcon,
      component: CreditSubmissionsList,
      isVisilbe: () => isLenderUser,
    },
    {
      id: "delearMgmtLender",
      showInSidebarNav: true,
      exact: true,
      path: "/dealers",
      name: "Dealer Management",
      icon: DynamicFeedIcon,
      component: DealerManagementList,
      isVisilbe: () => isLenderUser,
    },
    {
      id: "submissionsHub",
      showInSidebarNav: true,
      exact: true,
      path: "/submissions",
      isNewFeature: true,
      name: "Submissions Hub",
      icon: DeviceHubIcon,
      component: SubmissionsHub,
      isVisilbe: () => !isCommonPartner && isDealerUser && isSuperAdmin,
    },
    {
      id: "workflows",
      showInSidebarNav: true,
      exact: true,
      path: "/workflows",
      isNewFeature: true,
      name: "Workflows",
      icon: WorkIcon,
      component: WorkflowsListPage,
      isVisilbe: () => !isCommonPartner && isDealerUser && isSuperAdmin,
    },
    {
      id: "workflowsEdit",
      showInSidebarNav: false,
      exact: false,
      path: "/workflows/edit/:id",
      isNewFeature: true,
      name: "Edit Workflow",
      icon: WorkIcon,
      component: WorkflowUpsertPage,
      isVisilbe: () => !isCommonPartner && isDealerUser && isSuperAdmin,
    },
    {
      id: "workflowsAdd",
      showInSidebarNav: false,
      exact: true,
      path: "/workflows/add",
      isNewFeature: true,
      name: "Create Workflows",
      icon: WorkIcon,
      component: WorkflowUpsertPage,
      isVisilbe: () => !isCommonPartner && isDealerUser && isSuperAdmin,
    },
    {
      id: "customers",
      showInSidebarNav: true,
      exact: true,
      path: "/customers",
      name: "Customers",
      icon: Group,
      component: CustomersPage,
      isVisilbe: () =>
        isDealerUser &&
        [PETE_DYNAMICS_ACCOUNT_ID, TEST_VENDOR_V2_DYNAMICS_ACCOUNT_ID].includes(account.dynamicsAccountId!),
    },
    {
      id: "creditapp",
      showInSidebarNav: true,
      exact: true,
      path: "/creditapp",
      name: "Create Application",
      component: CreditAppv2,
      isVisilbe: () => isDealerUser && !!vendorProfile?.showPortalCreditApp,
    },
    {
      id: "receipt",
      showInSidebarNav: false,
      exact: true,
      path: "/receipt",
      name: "Success",
      icon: NoteAddIcon,
      component: Receipt,
      isVisilbe: () => isDealerUser,
    },
    {
      id: "appError",
      showInSidebarNav: false,
      exact: true,
      path: "/app-error",
      name: "Error",
      icon: NoteAddIcon,
      component: AppError,
      isVisilbe: () => isDealerUser,
    },
    {
      id: "prequalReceipt",
      showInSidebarNav: false,
      exact: true,
      path: "/prequal-receipt",
      name: "Success",
      icon: NoteAddIcon,
      component: PrequalReceipt,
      isVisilbe: () => isDealerUser,
    },
    {
      id: "pqt",
      showInSidebarNav: true,
      exact: true,
      path: "/pqt",
      name: "Payment Quote Tool",
      icon: MonetizationOnIcon,
      component: PQT,
      isVisilbe: () => !isCommonPartner && ["default", "options"].includes(vendorProfile?.showPqt!),
    },
    {
      id: "pqtOld",
      showInSidebarNav: false,
      exact: true,
      path: "/pqtold",
      name: "Payment Quote Tool",
      icon: MonetizationOnIcon,
      component: PQTView,
      isVisilbe: () => !isCommonPartner && isDealerUser,
    },
    {
      id: "sendoca",
      showInSidebarNav: true,
      exact: true,
      path: "/sendoca",
      name: "Send Application",
      icon: SendIcon,
      component: SendLink,
      isVisilbe: () => {
        if (isCommonPartner) {
          return false;
        }
        return isLenderUser ? !!vendorProfile : true;
      },
    },
    {
      id: "prequal",
      showInSidebarNav: true,
      exact: true,
      path: "/prequal",
      name: "Prequal App",
      icon: NoteAddIcon,
      component: PrequalApp,
      isVisilbe: () => isDealerUser && !!vendorProfile?.showPrequal,
    },
    {
      id: "chat",
      showInSidebarNav: true,
      exact: true,
      path: "/chat",
      name: "Conversations",
      icon: ChatIcon,
      component: ChatPage,
      isVisilbe: () => isDealerUser && vendorProfile?.showChat === "show",
    },
    {
      id: "leads",
      showInSidebarNav: true,
      exact: true,
      path: "/leads",
      name: "Leads",
      icon: DnsIcon,
      component: PageLeadList,
      isVisilbe: () => {
        return isLenderUser ? !!vendorProfile : true;
      },
    },
    {
      id: "lenderTasks",
      showInSidebarNav: true,
      exact: true,
      name: "Tasks",
      path: "/tasks",
      icon: FormatListNumberedIcon,
      component: LenderConsolidatedTasks,
      isVisilbe: () => isLenderUser,
    },
    {
      id: "reports",
      showInSidebarNav: true,
      exact: true,
      path: "/reports",
      name: "Reports",
      icon: BarChartIcon,
      component: isDealerUser ? ReportsPage : ReportsPageLender,
      isVisilbe: () => true,
    },
    {
      id: "consolidatedTasks",
      showInSidebarNav: true,
      exact: true,
      path: "/consolidatedTasks",
      name: "Tasks",
      icon: FormatListNumberedIcon,
      component: ConsolidatedTasks,
      isVisilbe: () =>
        isDealerUser &&
        vendorProfile?.showTasks === "show" &&
        userProfile.vendorContactRole !== VendorContactRole.SalesRep,
    },
    {
      id: "leadDetailLeadId",
      showInSidebarNav: false,
      exact: false,
      path: "/leadDetail/:leadId",
      name: "Lead Detail",
      icon: ViewListIcon,
      component: LeadDetails,
      isVisilbe: () => (isLenderUser ? !!vendorProfile : true),
    },
    {
      id: "proposalDetailPropId",
      showInSidebarNav: false,
      exact: false,
      path: "/proposalDetail/:propId",
      name: "Proposal Detail",
      icon: ViewListIcon,
      component: isDealerUser ? ProposalDetails : LenderProposalDetails,
      isVisilbe: () => isDealerUser || isLenderUser,
    },
    {
      id: "creditProgramDetailCreditProdId",
      showInSidebarNav: false,
      exact: false,
      path: "/creditProgramDetail/:creditProdId",
      name: "Credit Product Detail",
      icon: ViewListIcon,
      component: CreditProductDetails,
      isVisilbe: () => isDealerUser,
    },
    {
      id: "templates",
      showInSidebarNav: true,
      exact: true,
      path: "/templates",
      name: "Docs",
      icon: PostAddIcon,
      component: DocList,
      isVisilbe: () => isDealerUser && vendorProfile?.docusignDocMgmt === "show",
    },
    {
      id: "accountDetailsAccountId",
      showInSidebarNav: false,
      exact: false,
      path: "/accountDetails/:accountId",
      name: "Account Details",
      icon: ViewListIcon,
      component: AccountDetails,
      isVisilbe: () => isDealerUser || isLenderUser,
    },
    {
      id: "ownerPgDetailsOwnerPgId",
      showInSidebarNav: false,
      exact: false,
      path: "/ownerPgDetails/:ownerPgId",
      name: "Owner/PG Details",
      icon: ViewListIcon,
      component: OwnerDetails,
      isVisilbe: () => isDealerUser || isLenderUser,
    },
    {
      id: "dealerMgmt",
      showInSidebarNav: true,
      exact: true,
      path: "/dealerMgmt",
      name: "Dealer Management",
      icon: ViewListIcon,
      component: DealerList,
      isVisilbe: () => isCommonPartner,
    },
    {
      id: "addDealer",
      showInSidebarNav: true,
      exact: true,
      path: "/addDealer",
      name: "Add Dealer",
      icon: ViewListIcon,
      component: AddDealer,
      isVisilbe: () => isCommonPartner,
    },
    {
      id: "partnerAccountDetailsDynamicsId",
      showInSidebarNav: false,
      exact: false,
      path: "/partnerAccountDetails/:dynamicsId",
      name: "Partner Dealer Account Details",
      icon: ViewListIcon,
      component: PartnerDealerAccountDetails,
      isVisilbe: () => isDealerUser,
    },
    {
      id: "submissionDetails",
      showInSidebarNav: false,
      exact: false,
      path: "/cs/:id",
      name: "Submission Details",
      icon: ViewListIcon,
      component: CreditSubmissionDetailsPageV2,
      isVisilbe: () => isLenderUser,
    },
    {
      id: "submissionDetailsOld",
      showInSidebarNav: false,
      exact: false,
      path: "/cs_old/:id",
      name: "Submission Details",
      icon: ViewListIcon,
      component: CreditSubmissionDetails,
      isVisilbe: () => isLenderUser,
    },
    {
      id: "addDealers",
      showInSidebarNav: true,
      exact: true,
      path: "/add",
      name: "Add Dealers",
      icon: NoteAddIcon,
      component: AddDealers,
      isVisilbe: () => isLenderUser && !vendorProfile,
    },
    {
      id: "dealerDetails",
      showInSidebarNav: false,
      exact: false,
      path: "/dealers/:dealerId",
      type: "DealerDetails",
      name: "Dealer Details",
      icon: DynamicFeedIcon,
      component: DealerDetails,
      isVisilbe: () => isLenderUser,
    },
  ];

  const userRoutes: RouteConfig[] = [
    {
      id: "userProfile",
      showInSidebarNav: true,
      exact: false,
      path: "/profile/:dynamicsContactId",
      dynamicPath: `/profile/${userProfile.dynamicsContactId}`,
      name: "User Profile",
      icon: PersonIcon,
      component: UserProfileComponent,
      isVisilbe: () => isDealerUser || isLenderUser,
    },
    {
      id: "companyProfile",
      showInSidebarNav: true,
      exact: true,
      path: "/company-profile",
      name: "Company Profile",
      icon: BusinessIcon,
      component: CompanyProfile,
      isVisilbe: () => isDealerUser || isLenderUser,
    },
    {
      id: "acctAdmin",
      showInSidebarNav: true,
      exact: true,
      path: "/acctadmin",
      name: "User Management",
      icon: SupervisorAccountIcon,
      component: PageRepList,
      isVisilbe: () => {
        if (isDealerUser) {
          return !checkPortalConfigEntryForCurrentUserRole(
            PortalConfigurationType.UserManagement,
            PortalConfigurationRolesSettingsKeys.Hidden
          );
        }
        return true;
      },
    },
    {
      id: "creditPrograms",
      showInSidebarNav: false,
      exact: true,
      path: "/creditPrograms",
      name: "Credit Programs",
      icon: DnsIcon,
      component: PageCreditPrograms,
      isVisilbe: () => isDealerUser,
    },
    {
      id: "lenderAdmin",
      showInSidebarNav: true,
      exact: true,
      path: "/lenderadmin",
      name: "Lender Management",
      icon: PersonAddIcon,
      component: LenderAdmin,
      isVisilbe: () => {
        if (isCommonPartner) {
          return false;
        }
        if (isDealerUser) {
          if (!vendorProfile?.showLenderSubmission) {
            return false;
          }
          switch (vendorProfile?.showLenderSubmission) {
            case "no_access":
              return false;
            case "credit_manager_only":
              return byUserRole(userProfile, VendorContactRole.CreditMgr);
            case "all_managers":
              return !byUserRole(userProfile, VendorContactRole.SalesRep);
            default:
              return !byUserRole(userProfile, VendorContactRole.SalesRep);
          }
        }
        if (isLenderUser) {
          return vendorProfile?.allowSyndication;
        }
      },
    },
    {
      id: "lender",
      showInSidebarNav: false,
      exact: false,
      path: "/lenderadmin/:dynamicLenderProfileId",
      type: "LenderDetail",
      name: "Lender Details",
      icon: PersonAddIcon,
      component: LenderDetails,
      isVisilbe: () => isDealerUser || isLenderUser,
    },
    {
      id: "financePrograms",
      showInSidebarNav: true,
      exact: true,
      path: "/finance-programs",
      name: "Finance Programs",
      icon: LocalAtmRounded,
      component: FinanceProgramMgmt,
      isVisilbe: () => {
        if (isDealerUser) {
          return (
            ["show", "show_base_gbb"].includes(vendorProfile?.showProposal!) &&
            !byUserRole(userProfile, VendorContactRole.SalesRep)
          );
        }
        if (isLenderUser) {
          return true;
        }
        return false;
      },
    },
    {
      id: "locationMgmt",
      showInSidebarNav: true,
      exact: true,
      path: "/locationMgmt",
      name: "Location Management",
      icon: ViewListIcon,
      component: LocationList,
      isVisilbe: () => checkIfLocationsVisible(userProfile, vendorProfile),
    },
    {
      id: "pcw",
      showInSidebarNav: false,
      exact: true,
      path: "/pcw",
      name: "Portal Configuration Wizard",
      icon: TuneIcon,
      component: PortalConfigurationWizard,
      isVisilbe: () => {
        if (isLenderUser) {
          return !!vendorProfile;
        }
        return isDealerUser;
      },
    },
    {
      id: "portalSetup",
      showInSidebarNav: true,
      exact: true,
      path: "/portal-setup",
      name: "Portal Setup",
      icon: TuneIcon,
      component: PortalSetup,
      isVisilbe: () => {
        if (isDealerUser) {
          return !checkPortalConfigEntryForCurrentUserRole(
            PortalConfigurationType.PortalSetup,
            PortalConfigurationRolesSettingsKeys.Hidden
          );
        }
        return false;
      },
    },
    {
      id: "aftermarket",
      showInSidebarNav: true,
      exact: true,
      path: "/aftermarket",
      name: "Aftermarket Products",
      icon: StorefrontIcon,
      component: ListAftermarketProducts,
      isVisilbe: () => checkIfAftermarketVisible(isDealerUser, isCommonPartner, isSuperAdmin),
    },
    {
      id: "aftermarketSubMenues-List",
      showInSidebarNav: false,
      exact: true,
      path: "/aftermarket/menu",
      name: "Aftermarket Menu",
      icon: StorefrontIcon,
      component: ListAftermarketProducts,
      isVisilbe: () => checkIfAftermarketVisible(isDealerUser, isCommonPartner, isSuperAdmin),
    },
    {
      id: "aftermarketSubMenues-Dashboard",
      showInSidebarNav: false,
      exact: true,
      path: "/aftermarket/dashboard",
      name: "Aftermarket Dashboard",
      icon: StorefrontIcon,
      component: ListAftermarketProducts,
      isVisilbe: () => checkIfAftermarketVisible(isDealerUser, isCommonPartner, isSuperAdmin),
    },
    {
      id: "aftermarketMenuNEdit",
      showInSidebarNav: false,
      exact: false,
      path: "/aftermarket/menu/edit/:proposalMenuId",
      name: "Menu Details",
      component: AftermarketAddEditMenuTemplate,
      isVisilbe: () => checkIfAftermarketVisible(isDealerUser, isCommonPartner, isSuperAdmin),
    },
    {
      id: "aftermarketMenuNew",
      showInSidebarNav: false,
      exact: true,
      path: "/aftermarket/menu/new",
      name: "Menu Details",
      component: AftermarketAddEditMenuTemplate,
      isVisilbe: () => checkIfAftermarketVisible(isDealerUser, isCommonPartner, isSuperAdmin),
    },
    {
      id: "aftermarketAdd",
      showInSidebarNav: false,
      exact: true,
      path: "/aftermarket/add",
      name: "Add Aftermarket Product",
      icon: StorefrontIcon,
      component: AddAftermarketProduct,
      isVisilbe: () => checkIfAftermarketVisible(isDealerUser, isCommonPartner, isSuperAdmin),
    },
    {
      id: "aftermarketEdit",
      showInSidebarNav: false,
      exact: true,
      path: "/aftermarket/edit/:aftermarketProductId",
      name: "Edit Aftermarket Product",
      icon: StorefrontIcon,
      component: EditAftermarketProduct,
      isVisilbe: () => checkIfAftermarketVisible(isDealerUser, isCommonPartner, isSuperAdmin),
    },
    {
      id: "locationDetailLocationId",
      showInSidebarNav: false,
      exact: false,
      path: "/locationDetail/:locationId",
      name: "Location Details",
      icon: ViewListIcon,
      component: LocationDetail,
      isVisilbe: () => checkIfLocationsVisible(userProfile, vendorProfile),
    },
    {
      id: "websiteIntegration",
      showInSidebarNav: true,
      exact: true,
      path: "/website-integration",
      name: "Website Integration",
      icon: WebIcon,
      component: WebsiteIntegration,
      isVisilbe: () => {
        if (isCommonPartner) {
          return false;
        }
        if (isDealerUser) {
          return !byUserRole(userProfile, VendorContactRole.SalesRep);
        }
        return false;
      },
    },
    {
      id: "constructor",
      showInSidebarNav: false,
      exact: true,
      path: "/constructor",
      name: "OCA Constructor",
      icon: AppsIcon,
      component: Constructor,
      isVisilbe: () => true,
    },
    {
      id: "lenderWaterfall",
      showInSidebarNav: true,
      exact: true,
      path: "/lender-waterfall",
      name: "Prescreen Tools",
      icon: ViewListIcon,
      component: LenderWaterfall,
      isVisilbe: () =>
        checkIfPrescreenVisible(isDealerUser, isLenderUser, isUserHaveAccessToPrescreenPage, vendorProfile),
    },
    {
      id: "dev",
      showInSidebarNav: false,
      exact: true,
      path: "/dev",
      name: "Prescreen Tools",
      icon: ViewListIcon,
      component: PrescreenPage,
      isVisilbe: () =>
        checkIfPrescreenVisible(isDealerUser, isLenderUser, isUserHaveAccessToPrescreenPage, vendorProfile),
    },
  ];

  const allRoutes = [...appRoutes, ...userRoutes];

  const getActiveRouteConfig = () => {
    const currentUrl = pathname + search;
    return allRoutes.find(routeConfig => {
      return routeConfig.exact
        ? currentUrl === routeConfig.path
        : currentUrl.includes(routeConfig.path!.slice(0, routeConfig.path!.indexOf(":")));
    });
  };

  const [currentRouteConfig, setCurrentRouteConfig] = useState<RouteConfig>(getActiveRouteConfig() as RouteConfig);

  const { availableAppRoutes, availalbeUserRoutes } = useMemo(
    () => ({
      availableAppRoutes: appRoutes.filter(r => (r.isVisilbe ? r.isVisilbe() : true)),
      availalbeUserRoutes: userRoutes.filter(r => (r.isVisilbe ? r.isVisilbe() : true)),
    }),
    [portalConfigurationData, isDealerUser, isLenderUser, vendorProfile]
  );

  useEffect(() => {
    setCurrentRouteConfig(getActiveRouteConfig() as RouteConfig);
  }, [location]);

  return {
    currentRouteConfig,
    availableAppRoutes,
    availalbeUserRoutes,
  };
}
