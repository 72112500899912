import React from "react";
import { IconButton, Typography } from "@material-ui/core";
import { Visibility } from "@material-ui/icons";
import { CellDefaultProps } from "../../../types";
import moment from "moment";
import { DateFormats } from "../../../../../constants";

export const DateCell = ({ proposal }: CellDefaultProps) => {
  return (
    <Typography component="span" variant="body2">
      {moment(proposal.createdDateTime).format(DateFormats.DateTimeShort24Hour)}
    </Typography>
  );
};
