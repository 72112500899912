import {
  PortalConfiguration,
  PortalConfigurationType,
  UserProfile,
  VendorContactRole,
  VendorProfile,
} from "@trnsact/trnsact-shared-types";
import { usePortalConfigurationChecks } from "modules/portalConfigurations";
import { useSelector } from "react-redux";
import { portalConfigurationsSelectors } from "redux/portalConfigurationReducer";
import { PortalConfigurationRolesSettingsKeys } from "types";

export function useRoutesVisibilityChecks() {
  const portalConfigurationData = useSelector((state: any) => portalConfigurationsSelectors.configData(state));

  const { checkPortalConfigEntryForCurrentUserRole } = usePortalConfigurationChecks();

  const byUserRole = (userProfile: UserProfile, userRole: VendorContactRole) =>
    userProfile.vendorContactRole === userRole;

  const checkIfOpportunitiesVisible = (isDealerUser: boolean, isLenderUser: boolean, vendorProfile: VendorProfile) => {
    if (isDealerUser) {
      return !checkPortalConfigEntryForCurrentUserRole(
        PortalConfigurationType.Opportunities,
        PortalConfigurationRolesSettingsKeys.Hidden
      );
    }
    if (isLenderUser) {
      return !!vendorProfile;
    }
    return false;
  };

  const checkIfAftermarketVisible = (isDealerUser: boolean, isCommonPartner: boolean, isSuperAdmin: boolean) => {
    if (isCommonPartner) {
      return false;
    }
    if (isDealerUser) {
      const portalConfiguration = portalConfigurationData?.[PortalConfigurationType.Aftermarket];
      if (portalConfiguration) {
        return (
          isSuperAdmin ||
          checkPortalConfigEntryForCurrentUserRole(
            PortalConfigurationType.Aftermarket,
            PortalConfigurationRolesSettingsKeys.AssignedOnly
          ) ||
          checkPortalConfigEntryForCurrentUserRole(
            PortalConfigurationType.Aftermarket,
            PortalConfigurationRolesSettingsKeys.ReadOnly
          )
        );
      }
      return true;
    }
    return false;
  };

  const checkIfLocationsVisible = (userProfile: UserProfile, vendorProfile: VendorProfile) => {
    return !!vendorProfile?.multiLocationManagement && !byUserRole(userProfile, VendorContactRole.SalesRep);
  };

  const checkIfPrescreenVisible = (
    isDealerUser: boolean,
    isLenderUser: boolean,
    isUserHaveAccessToPrescreenPage: boolean,
    vendorProfile: VendorProfile
  ) => {
    if (isDealerUser) {
      return isUserHaveAccessToPrescreenPage;
    }
    if (isLenderUser) {
      return isUserHaveAccessToPrescreenPage && !!vendorProfile?.allowSyndication;
    }
  };

  return {
    byUserRole,
    checkIfLocationsVisible,
    checkIfOpportunitiesVisible,
    checkIfAftermarketVisible,
    checkIfPrescreenVisible,
  };
}
