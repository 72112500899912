import React from "react";
import { SwitchProps, Tooltip } from "@material-ui/core";
import { Control } from "react-hook-form/dist/types/form";
import { UseControllerProps } from "react-hook-form/dist/types/controller";
import { FieldValues } from "react-hook-form/dist/types/fields";
import { FormSwitch } from "../FormSwitch";
import { Controller } from "react-hook-form";

interface Props<FormValues extends FieldValues = FieldValues> extends SwitchProps {
  tooltip?: string;
  label?: React.ReactNode;
  control: Control<FormValues>;
  name: UseControllerProps<FormValues>["name"];
}

export const SwitchField = <FormValues extends FieldValues = FieldValues>({
  name,
  label,
  tooltip,
  control,
  ...other
}: Props<FormValues>) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { ref, ...field } }) => (
        <Tooltip title={tooltip || ""} arrow disableHoverListener={!tooltip}>
          <FormSwitch inputRef={ref} label={label} checked={field.value} {...field} {...other} />
        </Tooltip>
      )}
    />
  );
};
