interface BaseInputValue {
  firstName: string | undefined | null;
  lastName: string | undefined | null;
  fullName: string | undefined | null;
}

export function getPersonaName<T extends Partial<BaseInputValue>>(value: T): string {
  if (!value) return "";

  return value?.firstName ? `${value?.firstName} ${value?.lastName}` : value?.fullName ?? "";
}
