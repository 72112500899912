import React from "react";
import { CellDefaultProps } from "../../../types";
import { Typography } from "@material-ui/core";

export const StageCell = ({ proposal }: CellDefaultProps) => {
  return (
    <Typography align="center" component="span" variant="body2">
      {proposal.vendorOpportunity?.applicationStage
        ? proposal.vendorOpportunity?.applicationStage
            ?.replace(/_/g, " ")
            ?.replace(/\b\w/g, (char: any) => char?.toUpperCase())
        : "-"}
    </Typography>
  );
};
