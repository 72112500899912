import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { Box, Chip, InputAdornment, TextField, TextFieldProps } from "@material-ui/core";

interface FormInputProps extends Omit<TextFieldProps, "onChange" | "value"> {
  value: string[];
  isUnique?: boolean;
  onChange: (value: string[]) => void;
}

const isValueValid = (value: string, currentValues: string[], isUnique: boolean) =>
  value.trim() !== "" && (!isUnique || !currentValues.includes(value));

export const ChipsFormInput = ({ value = [], onChange, isUnique = true, ...other }: FormInputProps) => {
  const classes = useStyles();
  const [enteredValue, setEnteredValue] = useState<string>("");

  const handleAddValue = () => {
    if (!isValueValid(enteredValue, value, isUnique)) return;
    onChange([...value, enteredValue]);
    setEnteredValue("");
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleAddValue();
    }
  };

  return (
    <Box className={classes.container}>
      <TextField
        fullWidth
        size="small"
        variant="standard"
        value={enteredValue}
        onKeyDown={handleKeyPress}
        onChange={event => setEnteredValue(event.target.value)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end" style={{ cursor: "pointer" }} onClick={handleAddValue}>
              <AddCircleIcon color="primary" />
            </InputAdornment>
          ),
        }}
        {...other}
      />

      {!!value.length && (
        <Box className={classes.values}>
          {value.map(itemValue => (
            <Chip
              key={itemValue}
              label={itemValue}
              color={itemValue !== enteredValue ? "default" : "primary"}
              onDelete={() => onChange(value.filter(currentValue => currentValue !== itemValue))}
            />
          ))}
        </Box>
      )}
    </Box>
  );
};

const useStyles = makeStyles({
  container: {
    gap: "4px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
  },
  values: {
    gap: "4px",
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "flex-start",
  },
});
