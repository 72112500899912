const SET_PROPOSALS_MENU = "MENU_CONSTRUCTOR_SET_PROPOSALS_MENU";
const SET_PROPOSALS_PRODUCTS = "MENU_CONSTRUCTOR_SET_PROPOSALS_PRODUCTS";
const SET_SELECTED_PROPOSALS_PRODUCTS = "MENU_CONSTRUCTOR_SET_SELECTED_PROPOSALS_PRODUCTS";
const SET_MENU_OPTION_TO_ADD_EDIT = "MENU_CONSTRUCTOR_SET_MENU_OPTION_TO_ADD_EDIT";
const ADD_MENU_OPTION = "MENU_CONSTRUCTOR_ADD_MENU_OPTION";
const EDIT_MENU_OPTION = "MENU_CONSTRUCTOR_EDIT_MENU_OPTION";
const REMOVE_MENU_OPTION = "MENU_CONSTRUCTOR_REMOVE_MENU_OPTION";
const SELECT_MENU_OPTION = "MENU_CONSTRUCTOR_SELECT_MENU_OPTION";
const ADD_MENU_OPTION_PRODUCT = "MENU_CONSTRUCTOR_ADD_MENU_OPTION_PRODUCT";
const REMOVE_MENU_OPTION_PRODUCT = "MENU_CONSTRUCTOR_REMOVE_MENU_OPTION_PRODUCT";
const UPDATE_MENU_PRODUCT = "MENU_CONSTRUCTOR_UPDATE_MENU_PRODUCT";
const SET_MENU_GENERAL_FORM_VALUES = "MENU_CONSTRUCTOR_SET_MENU_GENERAL_FORM_VALUES";
const SET_INITIAL_PROPOSALS_MENU = "MENU_CONSTRUCTOR_SET_INITIAL_PROPOSALS_MENU";
const SET_IS_LOADING = "MENU_CONSTRUCTOR_SET_IS_LOADING";
const CLEAR = "MENU_CONSTRUCTOR_CLEAR";

export {
  SET_PROPOSALS_MENU,
  SET_PROPOSALS_PRODUCTS,
  SET_SELECTED_PROPOSALS_PRODUCTS,
  SET_MENU_OPTION_TO_ADD_EDIT,
  ADD_MENU_OPTION,
  EDIT_MENU_OPTION,
  REMOVE_MENU_OPTION,
  SELECT_MENU_OPTION,
  ADD_MENU_OPTION_PRODUCT,
  REMOVE_MENU_OPTION_PRODUCT,
  UPDATE_MENU_PRODUCT,
  SET_MENU_GENERAL_FORM_VALUES,
  SET_INITIAL_PROPOSALS_MENU,
  SET_IS_LOADING,
  CLEAR,
};
