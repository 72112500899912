import React from "react";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@material-ui/core";
import { ModalsKeys } from "../../../global";
import { useModal } from "../../../hooks/useModal";
import { Confirm as ConfirmSection } from "./coreForm/formSteps/confirm/Confirm";

export const PreviewFinanceProgramModal = () => {
  const classes = useStyles();

  const { isOpen, handleClose, data } = useModal(ModalsKeys.PreviewFinanceForm);

  const handleEdit = () => {
    handleClose();
    data.onEdit();
  };

  return (
    <Dialog open={isOpen} maxWidth="lg" fullWidth onClose={handleClose}>
      <DialogTitle>
        <Box className={classes.dialogTitle}>
          <Typography component="span" variant="h6">
            Preview: {data?.program?.first?.displayName}
          </Typography>

          <IconButton size="small" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent dividers classes={{ dividers: classes.dialogDividers }}>
        <ConfirmSection financeProgramFormValues={data.program} />
      </DialogContent>

      <DialogActions>
        <Box className={classes.dialogActions}>
          <Button onClick={handleEdit}>Edit program</Button>

          <Button variant="contained" onClick={handleClose}>
            Close
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles({
  dialogTitle: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  dialogDividers: {
    padding: "16px 24px 24px 24px",
  },
  dialogActions: {
    gap: "0.5rem",
    display: "flex",
    justifyContent: "flex-end",
  },
});
