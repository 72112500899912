import React from "react";
import { Box } from "@material-ui/core";
import { Controller } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import ReactQuill, { ReactQuillProps } from "react-quill";
import { Control } from "react-hook-form/dist/types/form";
import { FieldValues } from "react-hook-form/dist/types/fields";
import { UseControllerProps } from "react-hook-form/dist/types/controller";

interface Props<FormValues extends FieldValues = FieldValues> extends ReactQuillProps {
  control: Control<FormValues>;
  name: UseControllerProps<FormValues>["name"];
}

export const QuillInputField = <FormValues extends FieldValues = FieldValues>({
  name,
  control,
  ...other
}: Props<FormValues>) => {
  const classes = useStyles();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Box className={classes.root}>
          <ReactQuill {...field} {...other} />
        </Box>
      )}
    />
  );
};

const useStyles = makeStyles({
  root: {
    "& .ql-tooltip.ql-hidden": {
      display: "none",
    },
  },
});
