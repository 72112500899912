import React from "react";
import { useFormContext } from "react-hook-form";
import { LENDER_WATERFALL_FACT_NAMES_STRING } from "@trnsact/types-lender-waterfall";
import { FinanceProgramForm } from "../../../../schema";
import { SelectOption } from "../../../../../../global";
import { autocompletePropsByType } from "../../../../constants";
import { AutocompleteField, ChipsInputField } from "../../../../../../components/form";

interface Props {
  index: number;
  type: LENDER_WATERFALL_FACT_NAMES_STRING;
}

export const StringFieldsByRule = ({ index, type }: Props) => {
  const { control } = useFormContext<FinanceProgramForm>();

  return (
    <>
      {autocompletePropsByType?.[type] ? (
        <AutocompleteField<SelectOption, true, FinanceProgramForm>
          multiple
          size="small"
          control={control}
          getOptionSelected={(option, value) => option.value === value.value}
          name={`program.third.conditions.${index}.string.value`}
          {...autocompletePropsByType[type]!}
        />
      ) : (
        <ChipsInputField name={`program.third.conditions.${index}.string.value`} control={control} />
      )}
    </>
  );
};
