import { ProposalMenuOption } from "@trnsact/trnsact-shared-types";
import {
  REMOVE_MENU_OPTION,
  SELECT_MENU_OPTION,
  ADD_MENU_OPTION_PRODUCT,
  REMOVE_MENU_OPTION_PRODUCT,
  SET_PROPOSALS_MENU,
  SET_PROPOSALS_PRODUCTS,
  SET_SELECTED_PROPOSALS_PRODUCTS,
  SET_MENU_OPTION_TO_ADD_EDIT,
  ADD_MENU_OPTION,
  EDIT_MENU_OPTION,
  UPDATE_MENU_PRODUCT,
  SET_INITIAL_PROPOSALS_MENU,
  SET_IS_LOADING,
  CLEAR,
  SET_MENU_GENERAL_FORM_VALUES,
} from "./actionTypes";
import { MenuConstructorState } from "./types";
import { initialMenuOptionOnAddEdit, initialProposalMenu } from "../constants";

const initialState: MenuConstructorState = {
  proposalMenu: initialProposalMenu,
  proposalProducts: [],
  selectedProposalsProducts: [],
  menuOptionToAddEdit: { ...initialMenuOptionOnAddEdit },
  selectedMenuOption: null,
  archivedMenuOptions: [],
  initialProposalMenu: { ...initialProposalMenu },
  isLoading: true,
};

export const menuConstructorReducer = (state = initialState, { type, payload }: any) => {
  switch (type) {
    case SET_PROPOSALS_MENU:
      return {
        ...state,
        proposalMenu: payload,
      };
    case SET_MENU_GENERAL_FORM_VALUES:
      return {
        ...state,
        proposalMenu: { ...state.proposalMenu, ...payload },
      };
    case SET_PROPOSALS_PRODUCTS:
      return {
        ...state,
        proposalProducts: payload,
      };
    case SET_SELECTED_PROPOSALS_PRODUCTS:
      return {
        ...state,
        selectedProposalsProducts: payload,
      };
    case SET_MENU_OPTION_TO_ADD_EDIT:
      return {
        ...state,
        menuOptionToAddEdit: payload.currentMenuOption,
      };
    case ADD_MENU_OPTION:
      return {
        ...state,
        proposalMenu: {
          ...state.proposalMenu,
          menuOptions: [...state.proposalMenu.menuOptions!, payload.menuOption],
        },
      };
    case EDIT_MENU_OPTION:
      return {
        ...state,
        proposalMenu: {
          ...state.proposalMenu,
          menuOptions: state.proposalMenu.menuOptions!.map((option: ProposalMenuOption) => {
            return option.ordinal ===
              payload.menuOption.ordinal /* option.proposalMenuOptionId === payload.menuOption.proposalMenuOptionId */
              ? payload.menuOption
              : option;
          }),
        },
      };
    case REMOVE_MENU_OPTION:
      return {
        ...state,
        proposalMenu: {
          ...state.proposalMenu,
          menuOptions: state.proposalMenu.menuOptions!.filter((option: ProposalMenuOption) => {
            return option !== payload.currentMenuOption;
          }),
        },
        archivedMenuOptions: payload.currentMenuOption.proposalMenuOptionId
          ? [...state.archivedMenuOptions, { ...payload.currentMenuOption, archived: true }]
          : state.archivedMenuOptions,
      };
    case SELECT_MENU_OPTION:
      return {
        ...state,
        selectedMenuOption: payload.menuOption,
      };
    case ADD_MENU_OPTION_PRODUCT:
      return {
        ...state,
        proposalMenu: {
          ...state.proposalMenu,
          menuOptions: state.proposalMenu.menuOptions!.map((option: ProposalMenuOption) => {
            if (option.name === payload.menuType) {
              return {
                ...option,
                products: [...option.products, payload.product],
              };
            }

            return option;
          }),
        },
      };
    case REMOVE_MENU_OPTION_PRODUCT:
      return {
        ...state,
        proposalMenu: {
          ...state.proposalMenu,
          menuOptions: state.proposalMenu.menuOptions!.map((option: ProposalMenuOption) => {
            if (option.name === payload.menuType) {
              return {
                ...option,
                products: option.products.filter((product: any, index: number) => index !== payload.removeIndex),
              };
            }

            return option;
          }),
        },
      };
    case UPDATE_MENU_PRODUCT:
      return {
        ...state,
        proposalMenu: {
          ...state.proposalMenu,
          menuOptions: state.proposalMenu.menuOptions!.map((option: ProposalMenuOption) => {
            if (option.name === state.selectedMenuOption!.name) {
              return {
                ...option,
                products: option.products.map((product: any) => {
                  if (product.proposalProductId !== payload.productId) return product;

                  return {
                    ...product,
                    ...(payload.updateValues ?? {}),
                  };
                }),
              };
            }

            return option;
          }),
        },
      };
    case SET_INITIAL_PROPOSALS_MENU:
      return {
        ...state,
        initialProposalMenu: payload,
      };
    case SET_IS_LOADING:
      return {
        ...state,
        isLoading: payload,
      };
    case CLEAR:
      return initialState;
    default:
      return state;
  }
};
