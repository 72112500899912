import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import sidebarStyle from "assets/jss/material-dashboard-pro-react/components/sidebarStyle.js";
import { Box } from "@material-ui/core";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { Auth } from "aws-amplify";
import { SESSION_TIMEOUT_COOKIE_NAME } from "../../constants";
//@ts-ignore
import Cookies from "js-cookie";

//@ts-ignore
const useStyles = makeStyles(sidebarStyle);

export const LogoutNavItem = () => {
  const classes = useStyles();

  const handleLogout = async () => {
    await Auth.signOut();
    Cookies.remove(SESSION_TIMEOUT_COOKIE_NAME);
    localStorage.clear();
    sessionStorage.clear();
    document.location.pathname = "";
  };

  return (
    <ListItem className={classes.item} onClick={handleLogout}>
      <Box className={classes.itemLink}>
        <ExitToAppIcon className={classes.itemIcon} />
        <ListItemText primary="Logout" disableTypography={true} className={classes.itemText} />
      </Box>
    </ListItem>
  );
};
