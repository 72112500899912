import React, { ReactNode } from "react";
import { FormSummaryInfo } from "../FormSummaryInfo";
import { FinanceProgramForm } from "../../../../../schema";

interface Props {
  financeProgramFormValues: FinanceProgramForm["program"];
}

export const ProgramDetailsSummaryPart = ({ financeProgramFormValues: program }: Props) => {
  const renderConfiguration: Record<string, ReactNode> = {
    "Finance Program Name": program.first.name,
    "Finance Program Display Name": program.first.displayName,
    "Select Lender(s)": program.first.lenders?.length
      ? program.first.lenders?.map(({ label }) => label)?.join(", ")
      : null,
  };

  return <FormSummaryInfo data={renderConfiguration} />;
};
