import React from "react";
import { IconButton, Tooltip, Box } from "@material-ui/core";
import { Visibility, OpenInNew } from "@material-ui/icons";
import { CellDefaultProps } from "../../../types";
import { HubProposal } from "../../../api/types";
import { makeStyles } from "@material-ui/core/styles";

interface Props<TableData> extends CellDefaultProps<TableData> {
  onGoToProposal: (proposal: TableData) => void;
  onGoToProposalDetail: (proposal: TableData) => void;
}

export const ActionCell = <TableData extends any>({
  proposal,
  onGoToProposal,
  onGoToProposalDetail,
}: Props<TableData>) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Tooltip title="Go to proposal details">
        <IconButton color="primary" size="small" onClick={() => onGoToProposal(proposal)}>
          <Visibility color="primary" />
        </IconButton>
      </Tooltip>

      <Tooltip title="Go to proposal">
        <IconButton color="primary" size="small" onClick={() => onGoToProposalDetail(proposal)}>
          <OpenInNew color="primary" />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

const useStyles = makeStyles({
  container: {
    gap: "8px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});
