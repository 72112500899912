import React, { useRef, useEffect, useState, useCallback } from "react";
import { debounce } from "lodash";
import ReactQuill from "react-quill";
import { grey } from "@material-ui/core/colors";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Controller, useFormContext } from "react-hook-form";
import { ProposalMenuOption } from "@trnsact/trnsact-shared-types";
import { FinanceProgramFormValues } from "../../../types";

interface Props {
  menuOption: ProposalMenuOption;
}

const optionSummaryDetailsQuillModules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ["bold", "italic", "underline"], // ,'strike', 'blockquote'
    [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
  ],
};

export const OptionSummaryDetails = ({ menuOption }: Props) => {
  const classes = useStyles();
  const quillRef = useRef<ReactQuill>(null);

  const { control } = useFormContext<FinanceProgramFormValues>();
  const [editorValue, setEditorValue] = useState(menuOption.menuOptionDetail || "");

  const getDelta = () => {
    if (quillRef.current) {
      const quill = quillRef.current.getEditor();
      return quill.getContents();
    }
    return { ops: [] };
  };

  const debouncedOnChange = useCallback(
    debounce((deltaOps, field) => {
      field.onChange(deltaOps);
    }, 500),
    []
  );

  useEffect(() => {
    // Only update the editor if menuOption.menuOptionDetail changes from outside
    setEditorValue(menuOption.menuOptionDetail || "");
  }, [menuOption.menuOptionDetail]);

  return (
    <Box className={classes.container}>
      <Box className={classes.panel}>
        <Typography component="span" variant="subtitle2">
          Finance Program Description
        </Typography>
      </Box>

      <Controller
        name={`financeProgramDetails.${menuOption.name}` as const}
        control={control}
        render={({ field }) => (
          <Box className={classes.root}>
            <ReactQuill
              value={editorValue}
              ref={quillRef}
              theme="snow"
              modules={optionSummaryDetailsQuillModules}
              onBlur={() => {
                const deltaOps = getDelta().ops;
                setEditorValue(deltaOps);
                debouncedOnChange(deltaOps, field);
              }}
            />
          </Box>
        )}
      />
    </Box>
  );
};

const useStyles = makeStyles({
  container: {
    gap: "12px",
    display: "flex",
    flexDirection: "column",
  },
  panel: {
    padding: "8px",
    display: "flex",
    borderRadius: "2px",
    alignItems: "center",
    backgroundColor: grey["A100"],
  },
  form: {
    display: "flex",
    flexDirection: "column",
  },
  root: {
    "& .ql-tooltip.ql-hidden": {
      display: "none",
    },
  },
});
