import { LenderWaterfallConditions } from "@trnsact/types-lender-waterfall";
import { FinanceProgramForm } from "../schema";
import { mapCriteriaFormValuesToConditions } from "./mapCriteriaFormValuesToConditions";

export function mapCriteriaFormValuesToCreateInput(formValue: FinanceProgramForm, accountId: string) {
  const programAvailableForLenders = formValue.program.first.lenders.map(({ value }) => value);

  const lenderWaterfallConditions = new LenderWaterfallConditions();

  const conditionsForSave = mapCriteriaFormValuesToConditions(formValue.program.third?.conditions);

  conditionsForSave.forEach((condition: any) => lenderWaterfallConditions.addCondition(condition));

  return {
    accountId,
    notes: "",
    guidelines: "",
    financeProgramIdsToLink: [],
    name: formValue.program.first.displayName,
    lenderProfileIdsToLink: programAvailableForLenders,
    jsonCriteria: lenderWaterfallConditions.getConditions() as any,
  };
}
