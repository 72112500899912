import { useMutation } from "@apollo/react-hooks";
import { useSelector } from "react-redux";
import { ProposalMenu } from "@trnsact/trnsact-shared-types";
import { useState } from "react";
import {
  CREATE_PROPOSAL_MENU,
  CREATE_PROPOSAL_MENU_OPTION,
  UPDATE_PROPOSAL_MENU,
  UPDATE_PROPOSAL_MENU_OPTION,
} from "../api";
import { asyncForEach } from "utils";

import { menuConstructorSelectors } from "../model";
import { MenuOptionToAddEdit } from "../types";
import { ProposalProduct } from "@trnsact/trnsact-shared-types/dist/generated";

export function useSavingMenu() {
  const menuOptionsToArchive = useSelector(menuConstructorSelectors.archivedMenuOptions) as MenuOptionToAddEdit[];

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [createProposalMenu] = useMutation(CREATE_PROPOSAL_MENU);
  const [updateProposalMenu] = useMutation(UPDATE_PROPOSAL_MENU);
  const [createProposalMenuOption] = useMutation(CREATE_PROPOSAL_MENU_OPTION);
  const [updateProposalMenuOption] = useMutation(UPDATE_PROPOSAL_MENU_OPTION);

  const getProductMenuOptionLinkingCriteria = (p: ProposalProduct) => {
    return {
      productId: p.proposalProductId,
      cost: p.cost,
      retailCost: p.retailCost,
      config: {
        // @ts-expect-error TODO: Fix this typing, but configuration exists
        criteriaValues: p.configuration ?? [],
      },
      markup: {
        type: p.markup.type,
        markup: p.markup.markup,
      },
    };
  };

  const updateMenu = async (proposalMenu: ProposalMenu) => {
    setIsLoading(true);

    const getBaseMenuOptionToCreateOrUpdate = (menuOption: MenuOptionToAddEdit) => ({
      name: menuOption.name,
      description: menuOption.description,
      titleColor: menuOption.titleColor,
      titleBgColor: menuOption.titleBgColor,
      proposalProductMenuOptionLinkingCriteria: (menuOption.products as ProposalProduct[])?.map(
        getProductMenuOptionLinkingCriteria
      ),
    });

    const createMenuOption = async (menuOption: MenuOptionToAddEdit) => {
      try {
        const result = await createProposalMenuOption({
          variables: {
            input: getBaseMenuOptionToCreateOrUpdate(menuOption),
          },
        });
        return result?.data?.createProposalMenuOption?.proposalMenuOptionId;
      } catch (error) {
        console.error(error);
      }
    };

    const updateMenuOption = async (menuOption: MenuOptionToAddEdit) => {
      try {
        const result = await updateProposalMenuOption({
          variables: {
            input: {
              proposalMenuOptionId: menuOption.proposalMenuOptionId,
              ...getBaseMenuOptionToCreateOrUpdate(menuOption),
            },
          },
        });
        return result?.data?.updateProposalMenuOption?.proposalMenuOptionId;
      } catch (error) {
        console.error(error);
      }
    };

    try {
      const menuOptionIds: string[] = [];

      await asyncForEach(proposalMenu.menuOptions, async (menuOption: any) => {
        const proposalMenuOptionId = menuOption.proposalMenuOptionId
          ? await updateMenuOption(menuOption)
          : await createMenuOption(menuOption);
        menuOptionIds.push(proposalMenuOptionId);
      });

      const menuPayload = {
        proposalMenuId: proposalMenu.proposalMenuId,
        name: proposalMenu.name,
        description: proposalMenu.description,
        menuOptionIdsToLink: menuOptionIds,
      };

      await updateProposalMenu({
        variables: {
          input: menuPayload,
        },
      });
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const createMenu = async (proposalMenu: ProposalMenu) => {
    setIsLoading(true);

    const formatMenuOptionForPayload = (menuOption: any /* MenuOptionToAddEdit */) => {
      return {
        name: menuOption.name,
        description: menuOption.description,
        titleColor: menuOption.titleColor,
        titleBgColor: menuOption.titleBgColor,
        proposalProductMenuOptionLinkingCriteria: menuOption.products?.map(getProductMenuOptionLinkingCriteria),
      };
    };

    try {
      const menuPayload = {
        name: proposalMenu.name,
        description: proposalMenu.description,
        newMenuOptions: proposalMenu.menuOptions?.map(formatMenuOptionForPayload),
      };

      try {
        await createProposalMenu({
          variables: {
            input: menuPayload,
          },
        });
      } catch (error) {
        console.error(error);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    updateMenu,
    createMenu,
    isLoading,
  };
}
