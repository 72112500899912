import React from "react";
import { Box, Typography } from "@material-ui/core";
import ReactQuill from "react-quill";
import { ProposalProduct } from "@trnsact/trnsact-shared-types/dist/generated";

interface Props {
  proposalProduct: ProposalProduct;
}

export const ProductCatalogDetails = ({ proposalProduct }: Props) => {
  return (
    <Box className="infoItem">
      <Typography style={{ fontWeight: "bold", marginBottom: 15 }} variant="body1">
        {proposalProduct.title}
      </Typography>

      {proposalProduct.aftermarketProduct?.aftermarketProductCatalogDetails?.productSummary && (
        <div>
          <Typography style={{ fontWeight: "bold" }} component="span" variant="body1" color="textSecondary">
            Product Summary
          </Typography>
          <ReactQuill
            value={proposalProduct.aftermarketProduct?.aftermarketProductCatalogDetails.productSummary}
            readOnly={true}
            theme="bubble"
          />
          <hr />
        </div>
      )}

      {proposalProduct.aftermarketProduct?.aftermarketProductCatalogDetails?.paragraphDescription && (
        <div>
          <Typography style={{ fontWeight: "bold" }} component="span" variant="body1" color="textSecondary">
            Paragraph Description
          </Typography>
          <ReactQuill
            value={proposalProduct.aftermarketProduct?.aftermarketProductCatalogDetails.paragraphDescription}
            readOnly={true}
            theme="bubble"
          />
          <hr />
        </div>
      )}

      {proposalProduct.aftermarketProduct?.aftermarketProductCatalogDetails?.coverageDetails && (
        <div>
          <Typography style={{ fontWeight: "bold" }} component="span" variant="body1" color="textSecondary">
            Coverage Details
          </Typography>
          <ReactQuill
            value={proposalProduct.aftermarketProduct?.aftermarketProductCatalogDetails.coverageDetails}
            readOnly={true}
            theme="bubble"
          />
          <hr />
        </div>
      )}

      {proposalProduct.aftermarketProduct?.aftermarketProductCatalogDetails?.limitationsOfLiability && (
        <div>
          <Typography style={{ fontWeight: "bold" }} component="span" variant="body1" color="textSecondary">
            Limitations of Liability
          </Typography>
          <ReactQuill
            value={proposalProduct.aftermarketProduct?.aftermarketProductCatalogDetails.limitationsOfLiability}
            readOnly={true}
            theme="bubble"
          />
          <hr />
        </div>
      )}

      {proposalProduct.aftermarketProduct?.aftermarketProductCatalogDetails?.eligibilityAndInclusions && (
        <div>
          <Typography style={{ fontWeight: "bold" }} component="span" variant="body1" color="textSecondary">
            Eligibility and Inclusions
          </Typography>
          <ReactQuill
            value={proposalProduct.aftermarketProduct?.aftermarketProductCatalogDetails.eligibilityAndInclusions}
            readOnly={true}
            theme="bubble"
          />
          <hr />
        </div>
      )}

      {proposalProduct.aftermarketProduct?.aftermarketProductCatalogDetails?.legalDisclaimer && (
        <div>
          <Typography style={{ fontWeight: "bold" }} component="span" variant="body1" color="textSecondary">
            Legal Disclaimer
          </Typography>
          <ReactQuill
            value={proposalProduct.aftermarketProduct?.aftermarketProductCatalogDetails.legalDisclaimer}
            readOnly={true}
            theme="bubble"
          />
          <hr />
        </div>
      )}
    </Box>
  );
};
