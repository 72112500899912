import React, { PropsWithChildren, ReactNode } from "react";
import clsx from "clsx";
import { Skeleton } from "@material-ui/lab";
import { Paper, Box, Typography, Button, ButtonProps, PaperProps } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ContentViewType } from "../../../global";
import { isDesktop, isMobile } from "../../../utils/contentViewType";

interface Props extends PaperProps {
  title?: string;
  titleEl?: ReactNode;
  subTitle?: string;
  isLoading?: boolean;
  actionBtn?: ReactNode;
  containerClassName?: string;
  contentViewType?: ContentViewType;
  actionBtnProps?: ButtonProps & { label: string; isHidden?: boolean };
}

export const CardContainer = ({
  title,
  titleEl,
  subTitle,
  isLoading,
  children,
  actionBtn,
  actionBtnProps,
  containerClassName,
  contentViewType = ContentViewType.Desktop,
  ...paperProps
}: PropsWithChildren<Props>) => {
  const classes = useStyles();

  return (
    <Paper className={clsx(classes.container, containerClassName)} {...paperProps}>
      {isLoading ? (
        <Skeleton variant="rect" height="100%" width="100%" />
      ) : (
        <>
          <Box
            className={clsx(classes.header, {
              [classes.headerDesktop]: isDesktop(contentViewType),
              [classes.headerMobile]: isMobile(contentViewType),
            })}
          >
            {title && (
              <Typography component="span" variant="h6">
                {title}
              </Typography>
            )}

            {titleEl}

            {actionBtn || (
              <>
                {actionBtnProps && !actionBtnProps?.isHidden && (
                  <Button size="small" {...actionBtnProps}>
                    {actionBtnProps.label}
                  </Button>
                )}
              </>
            )}
          </Box>

          {subTitle && (
            <Typography component="span" variant="subtitle2" color="textSecondary">
              {subTitle}
            </Typography>
          )}

          <Box>{children}</Box>
        </>
      )}
    </Paper>
  );
};

const useStyles = makeStyles({
  container: {
    gap: "16px",
    height: "100%",
    padding: "24px",
    display: "flex",
    flexDirection: "column",
  },
  header: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  headerDesktop: {
    flexDirection: "row",
  },
  headerMobile: {
    gap: "8px",
    flexDirection: "column",
  },
});
