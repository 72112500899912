import React, { ReactNode } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { useFormContext, useWatch } from "react-hook-form";
import { Box, Button, ButtonGroup } from "@material-ui/core";
import { Menu } from "./Menu";
import { Finance } from "./Finance";
import { FormSectionHeader } from "../../../../components";
import { useModal } from "../../../../../../../hooks/useModal";
import { deskingActions, deskingSelectors } from "../../../../../model";
import { FinanceProgramFormValues, FinanceQuoteMode, FormSectionProps } from "../../../../../types";
import { ModalsKeys } from "../../../../../../../global";

export const Structure = ({ onUpdateCollapse, sectionName }: FormSectionProps) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const { handleOpen } = useModal(ModalsKeys.Confirmation);

  const { setValue, control, resetField } = useFormContext<FinanceProgramFormValues>();

  const { financeQuote } = useWatch({ control });

  const isSectionOpen = useSelector((state: any) => deskingSelectors.isSectionOpen(state, sectionName));

  const handleChangeMode = (nextValue: FinanceQuoteMode) => {
    setValue("financeQuote.mode", nextValue);
  };

  const handleClearStructureAndMenuOptions = () => {
    handleOpen({
      title: "Are you sure you want to reset the proposal builder?",
      message: "This action will remove all configurations from the Finance Program and Menu.",
      onConfirm: () => {
        resetField("financeQuote");
        dispatch(deskingActions.resetStructureAndMenuOptions());
      },
    });
  };

  const renderNestedSectionByMode: Record<FinanceQuoteMode, ReactNode> = {
    [FinanceQuoteMode.Finance]: <Finance />,
    [FinanceQuoteMode.Menu]: <Menu />,
  };

  return (
    <Box className="section">
      <FormSectionHeader
        name="Structure"
        sectionName={sectionName}
        isSectionOpen={isSectionOpen}
        sx={{ justifyContent: "center" }}
        onUpdateCollapse={onUpdateCollapse}
        extraAction={
          <Box className={classes.actionBtn}>
            <Button size="small" color="primary" variant="contained" onClick={handleClearStructureAndMenuOptions}>
              Reset
            </Button>
          </Box>
        }
      />

      <Box className="sectionFields">
        <ButtonGroup fullWidth>
          <Button
            color="primary"
            onClick={() => handleChangeMode(FinanceQuoteMode.Finance)}
            variant={financeQuote?.mode === FinanceQuoteMode.Menu ? "outlined" : "contained"}
          >
            Finance
          </Button>

          <Button
            color="primary"
            onClick={() => handleChangeMode(FinanceQuoteMode.Menu)}
            variant={financeQuote?.mode === FinanceQuoteMode.Finance ? "outlined" : "contained"}
          >
            Menu
          </Button>
        </ButtonGroup>

        <Box className="sectionFields">{renderNestedSectionByMode[financeQuote?.mode!]}</Box>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles({
  actionBtn: {
    top: "50%",
    right: "10px",
    position: "absolute",
    transform: "translateY(-50%)",
  },
});
