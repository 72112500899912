import React, { useEffect, useMemo, useState } from "react";
import { Box } from "@material-ui/core";
import { useQuery } from "react-apollo";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { EquipmentActions } from "./EquipmentActions";
import { FormSectionHeader } from "../../../../components";
import { GET_PROPOSAL_PRODUCTS } from "../../../../../api";
import { EquipmentEmptySection } from "./EquipmentEmptySection";
import { equipmentMileageFormatter } from "../../../../../lib";
import { useNotifications } from "../../../../../../notification";
import { ProposalProductsResponse } from "../../../../../api/types";
import { deskingActions, deskingSelectors } from "../../../../../model";
import { FinanceProgramFormValues, FormSectionProps, UserActionsEvents } from "../../../../../types";
import { equipmentConditions, equipmentDependentFields, equipmentYears } from "../../../../../constants";
import { CurrencyInputField, FormInputSelect, InputField, SelectField } from "../../../../../../../components/form";

type Props = FormSectionProps & Partial<UserActionsEvents>;

export const Equipment = ({ onUpdateCollapse, sectionName, ...userActionsEvents }: Props) => {
  const [isEditEquipmentFormMode, setIsEditEquipmentFormMode] = useState<boolean>(false);

  const { showNotification } = useNotifications();

  const { control, setValue } = useFormContext<FinanceProgramFormValues>();
  const { equipment, customer, financeQuote } = useWatch<FinanceProgramFormValues>({ control });

  const dispatch = useDispatch();

  const equipments = useSelector(deskingSelectors.equipment);
  const proposalsProducts = useSelector(deskingSelectors.proposalsProducts);
  const isSectionOpen = useSelector((state: any) => deskingSelectors.isSectionOpen(state, sectionName));
  const equipmentSelectOptions = useSelector(deskingSelectors.equipmentSelectOptions);

  const proposalProductsVariables = useMemo(
    () => ({
      input: {
        proposalProductCriteria: {
          state: customer?.state ?? "",
          vin: equipment?.serialNumberVin ?? "",
          insuredAmount: financeQuote?.amount ? parseFloat(financeQuote.amount) : 0,
          odometer: Number(equipmentMileageFormatter(equipment?.mileage ?? "", "toSimpleStr")),
        },
      },
    }),
    [customer?.state, equipment?.serialNumberVin, financeQuote?.amount, equipment?.mileage]
  );

  const { refetch: refetchProposalProducts, loading } = useQuery<ProposalProductsResponse>(GET_PROPOSAL_PRODUCTS, {
    variables: proposalProductsVariables,
    onCompleted(response) {
      if (!Array.isArray(response?.proposalProducts)) return;

      if (proposalsProducts.length) showNotification("Products updated");

      dispatch(deskingActions.setProducts(response.proposalProducts));
    },
  });

  useEffect(() => {
    dispatch(deskingActions.toggleIsProposalProductsLoading(loading));
  }, [loading]);

  const handleChangeEquipment = async (value: string) => {
    setIsEditEquipmentFormMode(false);

    await refetchProposalProducts(proposalProductsVariables);

    const selectedEquipment = equipments?.find((equipment: any) => equipment.equipmentId === value);

    setValue("equipment.selectedEquipment", value);

    setValue(`equipment.mileage`, equipmentMileageFormatter(selectedEquipment?.mileage, "toView"));

    equipmentDependentFields.forEach(field => setValue(`equipment.${field}`, selectedEquipment?.[field] ?? ""));

    dispatch(deskingActions.setCurrentEquipmentData(value));
    dispatch(deskingActions.setIsNeedToAutoFillProductsDynamicFields());
  };

  return (
    <Box className="section">
      <FormSectionHeader
        name="Equipment"
        sectionName={sectionName}
        isSectionOpen={isSectionOpen}
        onUpdateCollapse={onUpdateCollapse}
        extraAction={
          <EquipmentActions
            isEquipmentExist={!!equipments?.length}
            isEditEquipmentFormMode={isEditEquipmentFormMode}
            onToggleEquipmentFormMode={() => {
              setIsEditEquipmentFormMode(prev => !prev);
            }}
            {...userActionsEvents}
          />
        }
      />

      {isSectionOpen && (
        <>
          {!equipments?.length ? (
            <EquipmentEmptySection />
          ) : (
            <Box className="sectionFields">
              <Box className="row">
                <Controller
                  control={control}
                  name="equipment.selectedEquipment"
                  render={({ field }) => (
                    <FormInputSelect
                      size="small"
                      variant="standard"
                      label="Select Equipment"
                      options={equipmentSelectOptions}
                      inputProps={{ disabled: equipmentSelectOptions?.length < 1 }}
                      {...field}
                      onChange={handleChangeEquipment}
                    />
                  )}
                />

                <Box />
              </Box>

              <Box className="row">
                <InputField
                  label="Quantity"
                  control={control}
                  name="equipment.quantity"
                  inputProps={{ disabled: !isEditEquipmentFormMode }}
                />

                <SelectField
                  label="Condition"
                  control={control}
                  name="equipment.newUsed"
                  options={equipmentConditions}
                  inputProps={{ disabled: !isEditEquipmentFormMode }}
                />

                <SelectField
                  label="Year"
                  control={control}
                  name="equipment.year"
                  options={equipmentYears}
                  inputProps={{ disabled: !isEditEquipmentFormMode }}
                />

                <InputField
                  label="Stock Id"
                  control={control}
                  name="equipment.stockId"
                  inputProps={{ disabled: !isEditEquipmentFormMode }}
                />
              </Box>

              <Box className="row">
                <InputField
                  label="Make"
                  control={control}
                  name="equipment.make"
                  inputProps={{ disabled: !isEditEquipmentFormMode }}
                />

                <InputField
                  label="Model"
                  control={control}
                  name="equipment.model"
                  inputProps={{ disabled: !isEditEquipmentFormMode }}
                />

                <InputField
                  label="Mileage"
                  control={control}
                  name="equipment.mileage"
                  inputProps={{ disabled: !isEditEquipmentFormMode }}
                  formatter={value => equipmentMileageFormatter(value, "toUpdate")}
                />

                <InputField
                  control={control}
                  label="Serial/VIN#"
                  name="equipment.serialNumberVin"
                  inputProps={{ disabled: !isEditEquipmentFormMode }}
                />
              </Box>

              <Box className="row">
                <CurrencyInputField
                  control={control}
                  label="Total Wholesale Cost"
                  name="equipment.totalWholesaleCost"
                  textFieldProps={{ disabled: !isEditEquipmentFormMode }}
                />

                <CurrencyInputField
                  label="Total Retail Price"
                  control={control}
                  name="equipment.totalRetailPrice"
                  textFieldProps={{ disabled: !isEditEquipmentFormMode }}
                />

                <CurrencyInputField
                  control={control}
                  label="Wholesale Cost"
                  name="equipment.wholesaleCost"
                  textFieldProps={{ disabled: !isEditEquipmentFormMode }}
                />

                <InputField
                  control={control}
                  name="equipment.description"
                  label="Additional Description"
                  inputProps={{ disabled: !isEditEquipmentFormMode }}
                />
              </Box>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};
