import React from "react";
import { Box } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import { Description } from "./sections/Description";
import { SummaryContainer } from "./SummaryContainer";
import { FinanceProgramForm } from "../../../../schema";
import { ConfirmSummaryRenderConfig } from "../../../../types";
import { CriteriaSummaryPart } from "./sections/CriteriaSummaryPart";
import { ProgramDetailsSummaryPart } from "./sections/ProgramDetailsSummaryPart";
import { ConfigureStructureSummaryPart } from "./sections/ConfigureStructureSummaryPart";

interface Props {
  financeProgramFormValues: FinanceProgramForm["program"];
}

export const Confirm = ({ financeProgramFormValues }: Props) => {
  const classes = useStyles();

  const renderConfiguration: ConfirmSummaryRenderConfig[] = [
    {
      label: "Program Details",
      sectionKey: "details",
      component: ProgramDetailsSummaryPart,
    },
    {
      label: "Configure Structure",
      sectionKey: "structure",
      component: ConfigureStructureSummaryPart,
    },
    {
      label: "Criteria",
      sectionKey: "criteria",
      component: CriteriaSummaryPart,
    },
  ];

  return (
    <Box className={classes.container}>
      <Description guidelines={financeProgramFormValues.first.guidelines} />

      {renderConfiguration.map(({ label, sectionKey, component: Component }) => (
        <SummaryContainer key={label} label={label} sectionKey={sectionKey}>
          <Component financeProgramFormValues={financeProgramFormValues} />
        </SummaryContainer>
      ))}
    </Box>
  );
};

const useStyles = makeStyles({
  container: {
    gap: "12px",
    display: "flex",
    cursor: "default",
    flexDirection: "column",

    "& .description": {
      gap: "8px",
      display: "flex",
      flexDirection: "column",

      "& .content": {
        padding: "4px",
        borderRadius: "4px",
        border: `2px solid ${grey["A100"]}`,
      },
    },
  },
});
