import React, { ReactNode } from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";

interface Props {
  data: Record<string, ReactNode>;
}

export const FormSummaryInfo = ({ data }: Props) => {
  const classes = useStyles();

  const dataEntries = Object.entries(data);

  if (!dataEntries.length) {
    return (
      <Box className={classes.emptyContainer}>
        <Typography component="span" variant="body1">
          No data for this section
        </Typography>
      </Box>
    );
  }

  return (
    <Box className={classes.container}>
      {Object.entries(data).map(([label, value], index, arr) => (
        <Box key={label} className="rowWithDivider">
          <Box className="rowItem">
            <Typography component="span" color="textSecondary">
              {label}
            </Typography>

            <Typography component="span">{value ?? "-"}</Typography>
          </Box>

          <Divider orientation="horizontal" variant="fullWidth" flexItem className={classes.divider} />
        </Box>
      ))}
    </Box>
  );
};

const useStyles = makeStyles({
  emptyContainer: {
    padding: "8px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  container: {
    gap: "4px",
    display: "grid",
    gridTemplateColumns: "1fr 1fr",

    "& .rowWithDivider": {
      gap: "4px",
      display: "flex",
      flexDirection: "column",

      "& .rowItem": {
        display: "flex",
        flexDirection: "column",
      },
    },
  },
  divider: {
    height: "1px",
    marginBottom: "4px",
  },
});
