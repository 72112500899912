import React, { useState } from "react";
import { Box, Grid } from "@material-ui/core";
import { getItemDefaultRolesSettings } from "../../../services/pcwService";
import { RoleSettingSelect } from "./RoleSettingSelect";
import _ from "lodash";

export function RolesSetting({ settings, itemConfiguration, setItemConfiguration, itemType }) {
  const defaultRolesSettings = getItemDefaultRolesSettings(itemType);
  const [rolesSettingsValue, setRolesSettingsValue] = useState(
    _.get(itemConfiguration, settings.configurationKey, defaultRolesSettings)
  );

  const updateRolesSettingsValue = (value, key) => {
    setRolesSettingsValue(prevSettings => ({ ...prevSettings, [key]: value }));
    setItemConfiguration(prevItemConfiguration => {
      return {
        ...prevItemConfiguration,
        [settings.configurationKey]: {
          ...defaultRolesSettings,
          ...prevItemConfiguration[settings.configurationKey],
          [key]: value,
        },
      };
    });
  };

  return (
    <Box>
      <Grid container spacing={3}>
        {_.map(rolesSettingsValue, (rolesSettingsSelected, key) => (
          <RoleSettingSelect
            key={key}
            rolesSettingsSelected={rolesSettingsSelected}
            rolesSettingsSelectedKey={key}
            updateRolesSettingsValue={updateRolesSettingsValue}
            itemType={itemType}
            optionsByRoleSettingKey={settings.optionsByRoleSettingKey}
            helperText={settings?.customHelperText?.[key]}
          />
        ))}
      </Grid>
    </Box>
  );
}
