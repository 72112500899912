import React, { ReactNode } from "react";
import { Box } from "@material-ui/core";
import { useFormContext, useWatch } from "react-hook-form";
import {
  LENDER_WATERFALL_FACT_NAMES_STRING,
  LENDER_WATERFALL_FACT_NAMES_NUMERIC,
} from "@trnsact/types-lender-waterfall";
import { determineRuleType } from "../../../../lib";
import { booleanOptions } from "../../../../constants";
import { FinanceProgramForm } from "../../../../schema";
import { StringFieldsByRule } from "./StringFieldsByRule";
import { NumberFieldsByRule } from "./NumberFieldsByRule";
import { SelectField } from "../../../../../../components/form";
import { AllRules, ConditionsFieldsTypes } from "../../../../types";

interface Props {
  index: number;
}

export const ConditionsFieldsByRuleType = ({ index }: Props) => {
  const { control } = useFormContext<FinanceProgramForm>();
  const { program } = useWatch<FinanceProgramForm>({ control });

  const rule = program?.third?.conditions?.[index]?.rule! as AllRules;

  const ruleType = determineRuleType(rule);

  const renderFields: Record<ConditionsFieldsTypes, ReactNode> = {
    [ConditionsFieldsTypes.Boolean]: (
      <SelectField
        label="Select"
        control={control}
        options={booleanOptions}
        name={`program.third.conditions.${index}.boolean.value`}
      />
    ),
    [ConditionsFieldsTypes.Number]: (
      <NumberFieldsByRule type={rule as LENDER_WATERFALL_FACT_NAMES_NUMERIC} index={index} />
    ),
    [ConditionsFieldsTypes.String]: (
      <StringFieldsByRule type={rule as LENDER_WATERFALL_FACT_NAMES_STRING} index={index} />
    ),
  };

  return <Box className="conditionsFields">{renderFields[ruleType]}</Box>;
};
