import React from "react";
import { MenuCards, MenuFields, MenuSelector } from "../../../../components";

export const Menu = () => (
  <>
    <MenuSelector />
    <MenuFields />
    <MenuCards />
  </>
);
