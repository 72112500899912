import React from "react";
import MomentUtils from "@date-io/moment";
import { Controller } from "react-hook-form";
import { Control } from "react-hook-form/dist/types/form";
import { FieldValues } from "react-hook-form/dist/types/fields";
import { UseControllerProps } from "react-hook-form/dist/types/controller";
import { DatePicker, DatePickerProps, MuiPickersUtilsProvider } from "@material-ui/pickers";

interface Props<FormValues extends FieldValues = FieldValues> extends Omit<DatePickerProps, "value" | "onChange"> {
  control: Control<FormValues>;
  name: UseControllerProps<FormValues>["name"];
}

export const DateField = <FormValues extends FieldValues = FieldValues>({
  name,
  control,
  ...other
}: Props<FormValues>) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { ref, ...field } }) => (
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <DatePicker innerRef={ref} fullWidth {...field} {...other} />
        </MuiPickersUtilsProvider>
      )}
    />
  );
};
