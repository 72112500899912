import React from "react";
import clsx from "clsx";
import { Box, Button } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { useStepperActions } from "../../hooks/useStepperActions";
import { FinanceProgramFormMode, FinanceProgramFormSteps, StepsActionsConfig } from "../../types";

interface Props {
  isReadOnly: boolean;
  onCancel?: VoidFunction;
  mode: FinanceProgramFormMode;
  isSubmitActionInProgress: boolean;
}

export const StepperActions = ({ isReadOnly, isSubmitActionInProgress, mode, onCancel }: Props) => {
  const classes = useStyles();

  const { handleGoToExactStep, handleNext, handleBack, currentStepWatchValue, availableSteps } = useStepperActions({
    isReadOnly,
  });

  const stepsConfig: StepsActionsConfig[] = [
    {
      step: FinanceProgramFormSteps.First,
      disabled: availableSteps.next,
    },
    {
      step: FinanceProgramFormSteps.Second,
    },
    {
      step: FinanceProgramFormSteps.Third,
    },
    {
      step: FinanceProgramFormSteps.Fourth,
      disabled: availableSteps.prev,
    },
  ];

  const submitBtnLabel = mode === FinanceProgramFormMode.Update ? "Save Changes" : "Confirm Creation";

  return (
    <Box className={classes.container}>
      <Box className={classes.actions}>
        <Button onClick={handleBack} disabled={isSubmitActionInProgress || availableSteps.prev}>
          Back
        </Button>

        {stepsConfig.map(({ step }) => (
          <Box
            key={step}
            onClick={async () => {
              if (isSubmitActionInProgress) return;
              await handleGoToExactStep(step);
            }}
            className={clsx(classes.step, {
              [classes.currentStep]: currentStepWatchValue === step,
            })}
          >
            {step}
          </Box>
        ))}

        <Button onClick={handleNext} disabled={isSubmitActionInProgress || availableSteps.next}>
          Next
        </Button>
      </Box>

      <Box className={classes.actions}>
        {onCancel && (
          <Button variant="outlined" onClick={onCancel}>
            Cancel
          </Button>
        )}

        {!isReadOnly && (
          <Button
            type="submit"
            variant="contained"
            disabled={currentStepWatchValue !== FinanceProgramFormSteps.Fourth || isSubmitActionInProgress}
          >
            {isSubmitActionInProgress ? "Loading..." : submitBtnLabel}
          </Button>
        )}
      </Box>
    </Box>
  );
};

const useStyles = makeStyles<Theme>(({ palette, shadows }) => ({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  actions: {
    gap: "12px",
    display: "flex",
    alignItems: "center",
    position: "relative",
    justifyContent: "center",
  },
  step: {
    padding: "8px 16px",
    borderRadius: "8px",
    backgroundColor: palette.grey["100"],

    "&:hover": {
      cursor: "pointer",
      boxShadow: shadows[1],
    },
  },
  currentStep: {
    color: palette.common.white,
    backgroundColor: palette.primary.main,
  },
}));
