import React, { Fragment, ReactNode, useEffect } from "react";
import { ModalsKeys } from "global";
import { useDispatch, useSelector } from "react-redux";
import { deskingActions } from "../../model";
import { UserActionsEvents } from "../../types";
import { modalsSelectors } from "../../../../redux/modalsReducer";
import { PanelFormContainer } from "./panelForm/PanelFormContainer";
import {
  ProductCatalogDialog,
  ConfirmSaveDraftDialog,
  ProposalProductsDialog,
  NotifyAboutRemovedProductsDialog,
} from "../dialogs";

export const ConfigurationPanel = (props: Partial<UserActionsEvents>) => {
  const dispatch = useDispatch();

  const openedModals = useSelector(modalsSelectors.openedModals) as ModalsKeys[];

  const modalsWithKeys: Partial<Record<ModalsKeys, ReactNode>> = {
    [ModalsKeys.ProductCatalogDialog]: <ProductCatalogDialog />,
    [ModalsKeys.ProposalProductsDialog]: <ProposalProductsDialog />,
    [ModalsKeys.DeskingConfirmSaveDraftDialog]: <ConfirmSaveDraftDialog />,
    [ModalsKeys.DeskingNotifyAboutRemovedProductsDialog]: <NotifyAboutRemovedProductsDialog />,
  };

  useEffect(
    () => () => {
      dispatch(deskingActions.clearState());
    },
    [dispatch]
  );

  return (
    <>
      <PanelFormContainer {...props} />

      {openedModals.map(modalKey => (
        <Fragment key={modalKey}>{modalsWithKeys[modalKey]}</Fragment>
      ))}
    </>
  );
};
