import { FinanceProgramType } from "@trnsact/trnsact-shared-types";
import { FinanceProgramForm } from "../schema";
import { MarkupMethods, Structures } from "../types";

export function mapConfigurationFormValuesToCreateOrUpdate(formValue: FinanceProgramForm) {
  const {
    program: { first, second },
  } = formValue;

  const result: any = {
    notes: first.notes,
    type: second.programType,
    rateType: second.rateType,
    guidelines: first.guidelines,
    customerRate: second.customerRate,
    dealerMarkup: second.dealerMarkup,
    financeProgramName: first.displayName,
    paymentPeriodTiming: second.paymentCalculationMethod,
    numberOfAdvancePayments: Number(second.advancePayment),
    residualPercentage: second.programType === FinanceProgramType.Lease ? second.residual : undefined,
    terms: second.terms.map((term, index) => ({
      term: Number(term),
      rate: second.isSpecifyRate ? second.specifyRates[index].rate : second.rate,
    })),
    flatFees: second.fee.map(({ isFinanced, description, amount }) => ({
      fee: amount,
      financed: isFinanced,
      identifier: description,
    })),
    markups: [],
  };

  if (second.structure === Structures.None) {
    delete result.dealerMarkup;
    delete result.customerRate;
  }

  if (second.structure === Structures.Subsidy) {
    delete result.customerRate;
  }

  if (second.structure === Structures.Markup) {
    if (second.method === MarkupMethods.Markup) {
      delete result.customerRate;
    }

    if (second.method === MarkupMethods.CustomerRate) {
      delete result.dealerMarkup;
    }
  }

  return result;
}
