import { allStringFactsOptions } from "../constants";
import { determineNumberValue } from "./determineNumberValue";

export function mapCriteriaFormRulesToValue(formRules: Record<string, any>[]) {
  if (!Array.isArray(formRules)) return [];

  return formRules.map(rule => {
    return {
      rule: rule.fact,
      boolean: { value: rule?.value ?? false },
      number: {
        min: determineNumberValue(rule?.minValue ?? ""),
        max: determineNumberValue(rule?.maxValue ?? ""),
      },
      string: {
        value: Array.isArray(rule?.exactMatch)
          ? allStringFactsOptions.filter(({ value }) => rule.exactMatch.includes(value))
          : [],
      },
      isRequiredToQuote: false,
    };
  });
}
