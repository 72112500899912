import React from "react";
import { Grid } from "@material-ui/core";
import { MenuTemplateGeneralFormInputs } from "modules/aftermarketMenuConstructor/types";
import { InputField } from "components/form";
import { Control, FieldErrors } from "react-hook-form";

interface Props {
  control: Control<MenuTemplateGeneralFormInputs>;
  errors: FieldErrors<MenuTemplateGeneralFormInputs>;
  hasWriteAccess: boolean;
}

export const GeneralInformationForm = ({ control, errors, hasWriteAccess }: Props) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <InputField
          label="Name"
          control={control}
          name="name"
          rules={{
            required: true,
          }}
          inputProps={{
            required: true,
            error: !!errors.name,
            helperText: errors.name && "Required",
            disabled: !hasWriteAccess,
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <InputField
          label="Description"
          control={control}
          name="description"
          inputProps={{ disabled: !hasWriteAccess }}
        />
      </Grid>
    </Grid>
  );
};
