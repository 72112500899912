import React, { ChangeEvent, useMemo } from "react";
import _ from "lodash";
import { useFormContext } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { Box, Checkbox, Radio, Typography } from "@material-ui/core";
import { LenderProfiles } from "@trnsact/trnsact-shared-types/src/generated";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import { FilterOptionsState } from "@material-ui/lab/useAutocomplete/useAutocomplete";
import { LenderOptions } from "../../../../types";
import { FinanceProgramForm } from "../../../../schema";
import { lenderTypesSettings } from "../../../../../../constants";
import { AutocompleteField } from "../../../../../../components/form";

interface Props {
  isMultipleLenders?: boolean;
  lendersOptions: LenderProfiles[];
  isLendersSelectDisabled?: boolean;
}

const lenderTypesSettingsByValue = _.keyBy(lenderTypesSettings, "code");

export const LendersAutocompleteField = ({
  lendersOptions,
  isMultipleLenders = true,
  isLendersSelectDisabled = false,
}: Props) => {
  const classes = useStyles();

  const { control, setValue } = useFormContext<FinanceProgramForm>();

  const lendersOptionsAdaptedOptions = useMemo<LenderOptions[]>(
    () => lendersOptions.map(option => ({ ...option, value: option.id!, label: (option as any)?.lenderName ?? "" })),
    [lendersOptions]
  );

  const customFilter = (options: LenderOptions[], state: FilterOptionsState<LenderOptions>) =>
    options.filter(
      option =>
        option.label?.toLowerCase()?.includes(state?.inputValue?.toLowerCase()) ||
        (option as any)?.submissionMethod?.toLowerCase()?.includes(state?.inputValue?.toLowerCase())
    );

  const handleChange = (_: ChangeEvent<{}>, value: LenderOptions[]) => {
    const nextLendersValue = isMultipleLenders ? value : [value[value.length - 1]].filter(Boolean);

    setValue("program.first.lenders", nextLendersValue);
  };

  return (
    <AutocompleteField<LenderOptions, true, FinanceProgramForm>
      multiple
      control={control}
      disableCloseOnSelect
      onChange={handleChange}
      filterOptions={customFilter}
      name="program.first.lenders"
      options={lendersOptionsAdaptedOptions}
      disabled={isLendersSelectDisabled || !lendersOptions?.length}
      getOptionSelected={(option, value) => value && option.value === value?.value}
      textFieldProps={{
        label: isMultipleLenders ? "Select Lender(s)" : "Select Lender",
        placeholder: "Search by name or submission method...",
      }}
      renderOption={(option, { selected }) => {
        const method = (option as any)?.submissionMethod;
        const lenderTypeSetting = option.lenderTypeCode && lenderTypesSettingsByValue?.[option.lenderTypeCode];

        return (
          <Box className={classes.listItem}>
            {isMultipleLenders ? (
              <Checkbox
                checked={selected}
                checkedIcon={<CheckBoxIcon fontSize="small" />}
                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
              />
            ) : (
              <Radio size="small" checked={selected} />
            )}
            <Box className={classes.content}>
              <Box className={classes.labelContainer}>
                <Typography component="span" variant="body1">
                  {option.label}
                </Typography>

                {lenderTypeSetting && (
                  <Box className={classes.specificLabelPart}>
                    <Typography style={{ color: lenderTypeSetting?.color ?? "" }}>
                      {lenderTypeSetting?.letter ?? ""}
                    </Typography>

                    <Typography> {lenderTypeSetting?.title ?? ""}</Typography>
                  </Box>
                )}
              </Box>

              {method && (
                <Typography component="span" variant="caption" color="textSecondary">
                  {method}
                </Typography>
              )}
            </Box>
          </Box>
        );
      }}
    />
  );
};

const useStyles = makeStyles({
  listItem: {
    gap: "6px",
    display: "flex",
  },
  content: {
    display: "flex",
    flexDirection: "column",
  },
  labelContainer: {
    gap: "6px",
    display: "flex",
  },
  specificLabelPart: {
    gap: "4px",
    display: "flex",
  },
});
