import { useSelector } from "react-redux";
import { portalConfigurationsSelectors } from "../../../redux/portalConfigurationReducer";
import { PortalConfigurationType } from "@trnsact/trnsact-shared-types";
import { PortalConfigurationRolesSettingsKeys } from "types";
import { usePortalConfigurationChecks } from "modules/portalConfigurations";

export function useAccessControlProposalHubTable() {
  const { checkPortalConfigEntryForCurrentUserRole } = usePortalConfigurationChecks();

  return {
    hasAccessToAllProposals: !checkPortalConfigEntryForCurrentUserRole(
      PortalConfigurationType.Opportunities,
      PortalConfigurationRolesSettingsKeys.AssignedOnly
    ),
  };
}
