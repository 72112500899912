import { useDispatch, useSelector } from "react-redux";
import { PortalConfiguration, PortalConfigurationType, UserProfile } from "@trnsact/trnsact-shared-types";
import { PortalConfigurationRolesSettingsKeys } from "types";
import { portalConfigurationsSelectors } from "redux/portalConfigurationReducer";

export function usePortalConfigurationChecks() {
  const dispatch = useDispatch();

  const userProfile = useSelector((state: any) => state.userProfile) as UserProfile;
  const portalConfigurationData = useSelector((state: any) => portalConfigurationsSelectors.configData(state));

  const checkPortalConfigEntryForCurrentUserRole = (
    type: PortalConfigurationType,
    key: PortalConfigurationRolesSettingsKeys
  ) => {
    const portalConfiguration = portalConfigurationData?.[type];
    const rolesSettings = portalConfiguration?.jsonDefinition?.config.rolesSettings?.[key];
    return !!rolesSettings?.includes(userProfile.vendorContactRole);
  };

  return {
    checkPortalConfigEntryForCurrentUserRole,
  };
}
