import React, { useEffect, useState } from "react";
import { BpmnVisualization } from "bpmn-visualization";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Backdrop, Button, CircularProgress, Grid, LinearProgress, Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { Visualizer } from "./components";
import { makeStyles } from "@material-ui/core/styles";
import _ from "lodash";
import { DndProvider } from "react-dnd";
import LenderProfileList from "./LenderProfileList";
import DropArea from "./DropArea";
import downArrows from "assets/img/icons/down-arrow.png";
import AddIcon from "@material-ui/icons/Add";
import SaveIcon from "@material-ui/icons/Save";
import {
  CREATE_FINANCE_PROGRAM,
  GET_FINANCE_PROGRAMS,
  M_Create_Prescreen_Criteria,
  M_SAVE_PROCESS,
  Q_PROCESS,
  Q_Prescreen_Criteria,
  UPDATE_FINANCE_PROGRAM,
} from "./queries";
import { useModal } from "../../hooks/useModal";
import { ModalsKeys } from "../../global";
import {
  mapCriteriaFormValuesToCreateInput,
  mapFinanceProgramEntityToFormValues,
  mapFormValuesToCreateFinanceProgramInput,
  mapFormValuesToUpdateFinanceProgramInput,
} from "../../modules/financeProgram";
import { useNotifications } from "../../modules/notification";

const ordinal = no => {
  const specialCases = ["11", "12", "13"];
  const lastDigit = no % 10;
  const lastTwoDigits = no % 100;

  if (specialCases.includes(String(lastTwoDigits))) {
    return `${no}th`;
  }

  switch (lastDigit) {
    case 1:
      return `${no}st`;
    case 2:
      return `${no}nd`;
    case 3:
      return `${no}rd`;
    default:
      return `${no}th`;
  }
};

const WorkflowConstructor = ({ account, vp, type, waterfallActive, moduleName }) => {
  const classes = useStyles();

  const { showNotification } = useNotifications();

  const [criteriaOptions, setCriteriaOptions] = useState([]);
  const [bpmnVisualization, setBpmnVisualization] = useState();
  const [xmlBuilder, setXmlBuilder] = useState();
  const [financePrograms, setFinancePrograms] = useState([]);
  const [isShowSnackbar, setIsShowSnackbar] = useState(false);
  const [openVisualizerModal, setOpenVisualizerModal] = useState(false);
  const [generatedBPMNXML, setGeneratedBPMNXML] = useState("");
  const [errorOnSave, setErrorOnSave] = useState("");

  const [profiles, setProfiles] = useState(
    vp.lenderProfiles
      ? vp.lenderProfiles
          .filter(profile => profile.id)
          .map(lp => ({ ...lp, autoSubmit: false, selectedFinancePrograms: [] }))
      : []
  );

  const [droppedItems, setDroppedItems] = useState([]);
  const [lookups, setLookups] = useState([]);
  const [lookupCounter, setLookupCounter] = useState(lookups.length + 1);

  const [createLenderProgram] = useMutation(CREATE_FINANCE_PROGRAM, {
    context: { authRequired: true },
  });

  const [updateLenderProgram] = useMutation(UPDATE_FINANCE_PROGRAM, {
    context: { authRequired: true },
  });

  const [createPrescreenCriteria] = useMutation(M_Create_Prescreen_Criteria, {
    context: { authRequired: true },
  });

  const [createProcess] = useMutation(M_SAVE_PROCESS, {
    context: { authRequired: true },
  });

  const { data: processData } = useQuery(Q_PROCESS, {
    variables: {
      filters: {
        type: "LENDER_WATERFALL",
      },
    },
    fetchPolicy: "no-cache",
  });

  const { loading: fpLoading, refetch: fetchFinancePrograms } = useQuery(GET_FINANCE_PROGRAMS, {
    fetchPolicy: "no-cache",
    context: { authRequired: true },
    notifyOnNetworkStatusChange: true,
    variables: {
      accountId: account.id,
      lenderProfileIds: vp.lenderProfiles ? vp.lenderProfiles.filter(profile => profile.id).map(lp => lp.id) : [],
    },
    onCompleted: data => {
      if (data.financePrograms) {
        setFinancePrograms(data.financePrograms);
      }
    },
  });

  useQuery(Q_Prescreen_Criteria, {
    fetchPolicy: "no-cache",
    context: { authRequired: true },
    variables: { accountId: account.id },
    onCompleted(data) {
      const setOfCriteria = data?.prescreenCriteria ?? [];

      const options = setOfCriteria.map(criteria => ({
        label: criteria.name,
        value: criteria.prescreenCriteriaId,
        createdDateTime: criteria?.createdDateTime,
        formRules: criteria?.jsonCriteria?.formRules ?? [],
      }));

      setCriteriaOptions(options);
    },
  });

  useEffect(() => {
    setBpmnVisualization(
      new BpmnVisualization({
        container: "bpmn-container",
        navigation: { enabled: true },
      })
    );
  }, []);

  useEffect(() => {
    if (processData && !_.isEmpty(processData.processes)) {
      const activeProcess = _.first(processData.processes);
      if (!_.isEmpty(activeProcess.processConfigValues) && _.has(activeProcess, "processConfigValues[0].id")) {
        setLookups(activeProcess.processConfigValues);
        setLookupCounter(activeProcess.processConfigValues.length + 1);
        //Get all lenders and update the profiles on the left side bar displaying only the ones not included on the active process
        const allLenders = vp.lenderProfiles.filter(profile => profile.id);
        // const activeLenders = _.flatMap(activeProcess.processConfigValues, "profiles");
        // const filteredLenders = _.differenceBy(allLenders, activeLenders, "id");
        setProfiles(allLenders);
      }
    }
  }, [processData]);

  const onRemoveLookup = id => {
    const updatedLookups = lookups.filter(lookup => lookup.id !== id);
    //re-arrange index for lookups

    setLookups(
      updatedLookups.map(l => {
        return { ...l, id: updatedLookups.indexOf(l) + 1 };
      })
    );
    //Updates step number
    setLookupCounter(updatedLookups.length + 1);
  };
  useEffect(() => {
    //Nest finance programs on the LP list
    if (financePrograms && !_.isEmpty(financePrograms)) {
      const updatedProfiles = profiles.map(profile => {
        const matchedFinancePrograms = financePrograms.filter(financeProgram =>
          _.some(financeProgram.financeProgramToLenderProfiles, { lenderProfileId: profile.id })
        );

        return { ...profile, financePrograms: matchedFinancePrograms };
      });

      setProfiles(updatedProfiles);

      // //Update lookups
      const updatedLookups = lookups.map(lookup => {
        const profilesUpdated = lookup.profiles.map(profile => {
          const matchedFinancePrograms = financePrograms.filter(financeProgram =>
            _.some(financeProgram.financeProgramToLenderProfiles, { lenderProfileId: profile.id })
          );

          return { ...profile, financePrograms: matchedFinancePrograms };
        });

        return { ...lookup, profiles: profilesUpdated };
      });

      setLookups(updatedLookups);
    }
  }, [financePrograms]);

  useEffect(() => {
    const emptyLayer = _.find(lookups, ({ profiles }) => _.isEmpty(profiles));

    if (emptyLayer) {
      console.log(`Layer ${emptyLayer.id} is now empty`);
    }
  }, [lookups, xmlBuilder]);

  const handleCloseSuccessSnackbar = () => {
    setIsShowSnackbar(false);
  };

  const handleDrop = item => {
    //setProfiles(profiles.filter(p => p.id !== item.id));
    setDroppedItems([...droppedItems, item]);
  };

  const handleAddLookup = () => {
    setLookups(prev => [...prev, { id: lookupCounter, profiles: [] }]);
    setLookupCounter(prev => prev + 1);
  };

  const onDropProfile = (item, lookupId) => {
    const updatedLookups = lookups.map(lookup => {
      if (lookup.id === lookupId) {
        //if (!lookup.profiles.some(profile => profile.id === item.id)) {
        return { ...lookup, profiles: [...lookup.profiles, { ...item, selectedFinancePrograms: [], justAdded: true }] };
        //}
      }
      return lookup;
    });
    setLookups(updatedLookups);
  };

  const handleRemoveProfileFromLookup = (lookupId, index) => {
    const updatedLookups = prevLookups =>
      prevLookups.map(lookup => {
        if (lookup.id === lookupId) {
          const targetForRemoval = lookup.profiles;

          return { ...lookup, profiles: targetForRemoval.filter((_, i) => i !== index) };
        }
        return lookup;
      });

    setLookups(updatedLookups);
  };

  const onToggleAutoSubmitProfileFromLookup = (profileId, lookupId, index) => {
    const updatedLookups = lookups.map(lookup => {
      let profiles = lookup.profiles;

      if (lookup.id === lookupId) {
        profiles[index] = { ...profiles[index], autoSubmit: !profiles[index].autoSubmit };
      }
      return { ...lookup, profiles };
    });

    setLookups(updatedLookups);
  };

  const onChangeSelectedFinanceProgram = (lookupId, index, financeProgramId) => {
    const updatedLookups = lookups.map(lookup => {
      let profiles = lookup.profiles;

      if (lookup.id === lookupId) {
        profiles[index] = { ...profiles[index], selectedFinancePrograms: [financeProgramId] };
      }
      return { ...lookup, profiles };
    });

    setLookups(updatedLookups);
  };

  const { handleOpen, handleClose } = useModal(ModalsKeys.FinanceForm);
  const { handleOpen: handleOpenPreviewDialog } = useModal(ModalsKeys.PreviewFinanceForm);

  const onCreateProgramModalHandler = profileId => {
    handleOpen({
      criteriaOptions,
      isMultipleLenders: false,
      targetLenders: [profileId],
      isLendersSelectDisabled: true,
      lendersOptions: vp.lenderProfiles,
      onSubmit: async formValue => {
        try {
          const preparedData = mapFormValuesToCreateFinanceProgramInput(formValue, account.id);

          const { data: createdCriteria } = await createPrescreenCriteria({
            variables: {
              input: preparedData.prescreenCriteriaInput,
            },
          });

          const { data: createdProgram } = await createLenderProgram({
            variables: {
              input: {
                ...preparedData,
                prescreenCriteriaIdToLink: createdCriteria?.createPrescreenCriteria?.prescreenCriteriaId,
              },
            },
          });

          await updateLenderProgram({
            variables: {
              input: {
                financeProgramId: createdProgram?.createFinanceProgram?.financeProgramId,
                prescreenCriteriaIdToLink: createdCriteria?.createPrescreenCriteria?.prescreenCriteriaId,
              },
            },
          });

          showNotification(`Finance program created!`);

          await fetchFinancePrograms();

          handleClose();
        } catch (error) {
          showNotification(`Finance program was not created!`, {
            type: "error",
          });
        }
      },
    });
  };

  const onDisplayProgramModalHandler = (profileId, fp) => {
    const programFormValues = mapFinanceProgramEntityToFormValues(fp, vp.lenderProfiles);

    handleOpenPreviewDialog({
      program: programFormValues,
      onEdit: () => {
        handleOpen({
          criteriaOptions,
          isMultipleLenders: false,
          targetLenders: [profileId],
          program: programFormValues,
          isLendersSelectDisabled: true,
          lendersOptions: vp.lenderProfiles,
          isReadOnly: account.id !== fp.accountId,
          onSubmit: async formValue => {
            try {
              const preparedData = mapFormValuesToUpdateFinanceProgramInput(fp, formValue);

              await updateLenderProgram({
                variables: {
                  input: preparedData,
                },
              });

              const isCriteriaDidntUpdate =
                fp?.prescreenCriteria?.prescreenCriteriaId === formValue?.program?.third?.criteria?.value;

              if (!isCriteriaDidntUpdate) {
                const { data: createdCriteria } = await createPrescreenCriteria({
                  variables: {
                    input: mapCriteriaFormValuesToCreateInput(formValue, account.id),
                  },
                });

                await updateLenderProgram({
                  variables: {
                    input: {
                      financeProgramId: formValue.program.id,
                      prescreenCriteriaIdToLink: createdCriteria?.createPrescreenCriteria?.prescreenCriteriaId,
                    },
                  },
                });
              }

              await fetchFinancePrograms();

              showNotification(`Finance program updated!`);

              handleClose();
            } catch (error) {
              showNotification(`Finance program was not updated!`, {
                type: "error",
              });
            }
          },
        });
      },
    });
  };

  function generateBPMNXML(tasks) {
    const taskCount = tasks.length;
    const xmlHeader = `<?xml version="1.0" encoding="UTF-8"?>`;
    const definitionsStart = `
    <definitions xmlns="http://www.omg.org/spec/BPMN/20100524/MODEL"
                 xmlns:bpmndi="http://www.omg.org/spec/BPMN/20100524/DI"
                 xmlns:omgdc="http://www.omg.org/spec/DD/20100524/DC"
                 xmlns:omgdi="http://www.omg.org/spec/DD/20100524/DI"
                 xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"
                 xmlns:camunda="http://camunda.org/schema/1.0/bpmn"
                 xsi:schemaLocation="http://www.omg.org/spec/BPMN/20100524/MODEL BPMN20.xsd"
                 id="Definitions_1"
                 targetNamespace="http://bpmn.io/schema/bpmn">`;

    //Hardcoded Step 1
    const processStart = `
      <process id="process_external_task" isExecutable="true">
        <startEvent id="startEvent" name="Start">
          <outgoing>flow1</outgoing>
        </startEvent>
        <sequenceFlow id="flow1" sourceRef="startEvent" targetRef="externalTask1"/>`;

    const taskTemplate = (taskId, topic, flowIn, flowOutSuccess, flowOutFail) => {
      const taskIds = JSON.stringify(
        tasks[taskId - 1].profiles.map(({ id, selectedFinancePrograms, autoSubmit }) => ({
          lenderProfileId: id,
          financePrograms: selectedFinancePrograms,
          autoSubmit,
        }))
      );

      return `
        <serviceTask id="externalTask${taskId}" name="Submit to Lenders Stage ${taskId}" camunda:type="external" camunda:topic="lender-waterfall">
          <extensionElements>
            <camunda:inputOutput>
              <camunda:inputParameter name="PROFILES_TO_SCREEN">${taskIds}</camunda:inputParameter>
              <camunda:outputParameter name="CONTINUE_WATERFALL"></camunda:outputParameter>
            </camunda:inputOutput>
          </extensionElements>
          <incoming>${flowIn}</incoming>
          <outgoing>${flowOutSuccess}</outgoing>
          <outgoing>${flowOutFail}</outgoing>
        </serviceTask>`;
    };

    const sequenceFlowTemplate = (flowId, sourceRef, targetRef, condition) => `
        <sequenceFlow id="flow${flowId}" sourceRef="${sourceRef}" targetRef="${targetRef}">
        <conditionExpression xsi:type="tFormalExpression">\${${condition}}</conditionExpression>
      </sequenceFlow>`;

    let endEvent = `
        <endEvent id="endEvent" name="Finished">
          <incoming>submitHandler</incoming>
        </endEvent>`;

    endEvent += `
      </process>`;

    const diagramStart = `
      <bpmndi:BPMNDiagram id="BPMNDiagram_1">
        <bpmndi:BPMNPlane id="BPMNPlane_1" bpmnElement="process_external_task">
          <bpmndi:BPMNShape id="startEvent_di" bpmnElement="startEvent">
            <omgdc:Bounds x="100" y="100" width="36" height="36"/>
          </bpmndi:BPMNShape>`;

    const shapeTemplate = (taskId, x, y) => `
          <bpmndi:BPMNShape id="externalTask${taskId}_di" bpmnElement="externalTask${taskId}">
            <omgdc:Bounds x="${x}" y="${y}" width="200" height="80"/>
          </bpmndi:BPMNShape>`;

    const endShape = `
          <bpmndi:BPMNShape id="endEvent_di" bpmnElement="endEvent">
            <omgdc:Bounds x="${200 + 150 * taskCount + 1}" y="100" width="36" height="36"/>
          </bpmndi:BPMNShape>
          <bpmndi:BPMNShape id="submitHandler_di" bpmnElement="submitHandler">
            <omgdc:Bounds x="${200 + 150 * taskCount}" y="200" width="200" height="36"/>
          </bpmndi:BPMNShape>`;

    const edgeTemplate = (flowId, x1, y1, x2, y2) => `
          <bpmndi:BPMNEdge id="flow${flowId}_di" bpmnElement="flow${flowId}">
            <omgdi:waypoint x="${x1}" y="${y1}"/>
            <omgdi:waypoint x="${x2}" y="${y2}"/>
          </bpmndi:BPMNEdge>`;

    const diagramEnd = `
        </bpmndi:BPMNPlane>
      </bpmndi:BPMNDiagram>
    </definitions>`;

    let bpmnXML = xmlHeader + definitionsStart + processStart;

    for (let i = 1; i <= taskCount; i++) {
      const flowOutSuccess = `flow${i + 1}`;
      const flowOutFail = `flowError${i}`;
      //if last task, set nextTask to submitHandler
      const nextTask = i === taskCount ? "submitHandler" : `externalTask${i + 1}`;

      bpmnXML += taskTemplate(i, `topic${i}`, `flow${i}`, flowOutSuccess, flowOutFail);
      bpmnXML += sequenceFlowTemplate(i + 1, `externalTask${i}`, nextTask, `CONTINUE_WATERFALL == true`);
      bpmnXML += sequenceFlowTemplate(`Error${i}`, `externalTask${i}`, "submitHandler", `CONTINUE_WATERFALL == false`);
    }

    bpmnXML += `
        <serviceTask id="submitHandler" name="Submit Handler" camunda:type="external" camunda:topic="lender-waterfall">`;
    //Incoming from 1 .. N
    for (let i = 1; i <= taskCount; i++) {
      bpmnXML += ` <incoming>externalTask${i}</incoming>`;
    }
    bpmnXML += `<outgoing>endEvent</outgoing>
        </serviceTask>
        <sequenceFlow id="submitHandlerRef" sourceRef="submitHandler" targetRef="endEvent"></sequenceFlow>
        `;

    bpmnXML += endEvent;

    //Diagram Shapes here...
    bpmnXML += diagramStart;

    for (let i = 1; i <= taskCount; i++) {
      bpmnXML += shapeTemplate(i, 200 + (i - 1) * 150, 80);
    }

    bpmnXML += endShape;

    for (let i = 1; i <= taskCount; i++) {
      bpmnXML += edgeTemplate(i, 100 + (i - 1) * 150, 118, 200 + (i - 1) * 150, 118);
      bpmnXML += edgeTemplate(`Error${i}`, 200, 118 + (i - 1) * 150, 200, 200 + (i - 1) * 150);
    }
    bpmnXML += edgeTemplate(taskCount + 1, 100 + taskCount * 150, 118, 200 + taskCount * 150, 118);
    bpmnXML += `<bpmndi:BPMNEdge id="submitHandlerRef_di" bpmnElement="submitHandlerRef">
            <omgdi:waypoint x="${200 + 150 * taskCount}" y="218"/>
            <omgdi:waypoint x="${200 + 150 * taskCount}" y="218"/>
          </bpmndi:BPMNEdge>`;
    bpmnXML += diagramEnd;

    return bpmnXML;
  }

  const genXML = async e => {
    setErrorOnSave("");
    e.preventDefault();

    const xml = generateBPMNXML(lookups);
    setGeneratedBPMNXML(xml);
    //setOpenVisualizerModal(true);
    // console.log(xml.replace(/\n/g, ""));
    // console.log(lookups);
    try {
      await createProcess({
        variables: {
          input: {
            accountId: account.id,
            processFileContents: xml.replace(/\n/g, ""),
            type: "LENDER_WATERFALL",
            processConfigValues: lookups,
            active: waterfallActive,
          },
        },
      });
    } catch (error) {
      setErrorOnSave(error.message);
    }

    setIsShowSnackbar(true);
  };

  const handleCreateProgram = () => {
    alert("modal popup, collapse item");
  };

  return (
    <div style={{ padding: "15px" }}>
      <Backdrop sx={{ color: "#000", zIndex: theme => theme.zIndex.drawer + 1 }} open={fpLoading}>
        <CircularProgress color="primary" size={"large"} />
      </Backdrop>

      <Grid container spacing={1}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <DndProvider backend={HTML5Backend}>
            <Grid container spacing={2}>
              <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                <LenderProfileList
                  profiles={profiles}
                  onDropFinaceProgram={handleDrop}
                  handleCreateProgram={handleCreateProgram}
                />
              </Grid>
              <Grid item xl={9} lg={9} md={6} sm={12} xs={12}>
                <Grid container>
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Grid container spacing={1}>
                      <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                        <Button
                          fullWidth
                          size="small"
                          onClick={handleAddLookup}
                          variant="contained"
                          color="secondary"
                          startIcon={<AddIcon />}
                        >
                          {ordinal(lookupCounter)} Step
                        </Button>
                      </Grid>
                      <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                        <Button
                          fullWidth
                          onClick={genXML}
                          size="small"
                          variant="contained"
                          color="primary"
                          startIcon={<SaveIcon />}
                        >
                          Save Config
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    {fpLoading ?? (
                      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <LinearProgress />
                      </Grid>
                    )}
                    <Grid container>
                      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <div style={{ textAlign: "center", marginTop: lookups.length ? "1vh" : "30vh" }}>
                          {lookups.length ? (
                            <h3>{moduleName.short} Sequence</h3>
                          ) : (
                            <h3>Start by adding a step first ...</h3>
                          )}
                        </div>
                      </Grid>
                      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        {lookups.map((lookup, index) => (
                          <>
                            <DropArea
                              key={lookup.id}
                              lookup={lookup}
                              onDropProfile={onDropProfile}
                              onRemoveProfileFromLookup={handleRemoveProfileFromLookup}
                              onToggleAutoSubmitProfileFromLookup={onToggleAutoSubmitProfileFromLookup}
                              onChangeSelectedFinanceProgram={onChangeSelectedFinanceProgram}
                              onCreateProgramModalHandler={onCreateProgramModalHandler}
                              onDisplayProgramModalHandler={onDisplayProgramModalHandler}
                              onRemoveLookup={onRemoveLookup}
                              queryLoading={fpLoading}
                              type={type}
                            />
                            {index < lookups.length - 1 ? <div className={classes.waterfallDivider}></div> : null}
                          </>
                        ))}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DndProvider>
        </Grid>
      </Grid>

      <Snackbar
        open={isShowSnackbar}
        autoHideDuration={2000}
        onClose={handleCloseSuccessSnackbar}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        {errorOnSave ? (
          <Alert onClose={handleCloseSuccessSnackbar} severity="error" variant="filled">
            {errorOnSave}
          </Alert>
        ) : (
          <Alert onClose={handleCloseSuccessSnackbar} severity="success" variant="filled">
            Changes Saved
          </Alert>
        )}
      </Snackbar>

      <Visualizer
        xml={generatedBPMNXML}
        open={openVisualizerModal}
        onClose={() => {
          setOpenVisualizerModal(false);
        }}
      />
    </div>
  );
};

const useStyles = makeStyles(() => ({
  propertiesContainer: {
    overflowY: "auto",
    borderTop: "solid 2px #CCC",
    background: "#FAFAFA",
    padding: "5px",
    height: "200px",
  },
  modelerContainer: {
    flex: "1",
    minHeight: "400px",
  },
  modelerParent: {
    flex: "1",
    display: "flex",
    flexDirection: "column",
  },
  lenderList: {
    border: "1px solid #DEDEDE",
    padding: "15px",
    borderRadius: "5px",
  },
  lenderItem: {
    border: "1px solid #CCC",
    padding: "5px",
    margin: "5px",
  },
  waterfallDivider: {
    width: "100%",
    height: "50px",
    backgroundImage: `url(${downArrows})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "contain",
  },
}));

export default WorkflowConstructor;
