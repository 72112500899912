import React, { useRef, useState } from "react";
import { gql } from "apollo-boost";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import { makeStyles } from "@material-ui/core/styles";
import { Query } from "react-apollo";
import Tile from "../../components/Tile/Tile";
import { convertToDate, formatCurrency } from "../../utils";
import { FormControlLabel, Grid, Switch } from "@material-ui/core";
import { connect } from "react-redux";
import { setRefetch, setValue } from "../../redux/actions";
import CreateProposalModal from "./AddNewProposal";
import Table from "components/Table";
import TableHeader from "components/Table/TableHeader";
import TableSearch from "components/Table/TableSearch";
import TableSelect from "components/Table/TableSelect";
import DragIndicator from "assets/img/drag-indicator.svg";

const _ = require("lodash");

const styles = theme => ({
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
  left: {
    textAlign: "left",
  },
  right: {
    textAlign: "right",
  },
  fixReactTable: {
    "& .ReactTable .rt-thead .rt-th, .ReactTable .rt-thead .rt-td": {
      display: "flex",
      alignItems: "center",
      lineHeight: "1rem !important",
    },
    "& .ReactTable .rt-thead .rt-resizable-header-content": {
      position: "relative",
      paddingRight: "1rem",
      overflow: "visible",
      display: "flex",
      alignItems: "center",
    },
    "& .ReactTable .rt-thead .rt-th.-cursor-pointer > div:first-of-type:after": {
      position: "absolute",
      right: "0",
      top: "50%",
      transform: "translateY(-50%)",
      bottom: "unset",
    },
    "& .ReactTable .rt-thead.-filters input, & .ReactTable .-pagination .-pageJump input": {
      height: "26px",
    },
    "& .ReactTable .rt-thead.-filters input:focus, & .ReactTable .-pagination .-pageJump input:focus": {
      backgroundImage: `linear-gradient(${theme.palette.primary.main}, ${theme.palette.primary.main}), linear-gradient(#d2d2d2, #d2d2d2)`,
    },
    "& .ReactTable .rt-resizer": {
      backgroundImage: `url(${DragIndicator})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "left center",
      backgroundSize: "26px",
    },
  },
  filterSelect: {
    height: "1rem !important",
    boxSizing: "content-box",
    fontSize: "14px !important",
    "&:focus": {
      backgroundImage: `linear-gradient(${theme.palette.primary.main}, ${theme.palette.primary.main}), linear-gradient(#d2d2d2, #d2d2d2) !important`,
    },
  },
});

const useStyles = makeStyles(styles);

const GET_PROPOSAL_LIST = gql`
  query {
    proposals {
      id
      amountRequested
      creditProductIds
      riskTier
      vendorOpportunityId
      accountId
      vendorSalespersonId
      firstName
      lastName
      email
      businessName
      status
      createdDateTime
      salesPersonName
      fullName
    }
  }
`;

const GET_ACCOUNT_PROPOSAL_LIST = gql`
  query {
    proposalsByAccount {
      id
      amountRequested
      creditProductIds
      riskTier
      vendorOpportunityId
      accountId
      vendorSalespersonId
      firstName
      lastName
      email
      businessName
      status
      createdDateTime
      salesPersonName
      fullName
    }
  }
`;

// TODO This is an old component, it should be removed as soon as we are sure that the new Proposals page works in production. Also, do not forget to clean up the components associated with it.
function PageProposalList(props) {
  const userProfileDynamicsAccountId = props.account.dynamicsAccountId;
  const userProfileAccountId = props.account.id;
  const classes = useStyles();
  let statusesSel = [];
  const rolesSel = [];
  const [proposals, setProposals] = useState("self");
  const [pageNumber, setPageNumber] = useState(0);
  const searchRef = useRef({ prevSearch: undefined, currentSearch: "" });
  const statusRef = useRef({ prevStatus: undefined, currentSelect: "" });
  const [savedListSettings, setSavedListSettings] = useState({ page: 0, pageSize: 10 });
  const [pageSizeValue, setPageSizeValue] = useState(10);

  function handleProposalRowClick(propId) {
    props.history.push(`/proposalDetail/${propId}`);
  }

  const filterProposalListData = proposals => {
    const { currentSearch } = searchRef.current;

    if (currentSearch.length === 0) {
      return proposals;
    }

    const lowerCaseSearch = currentSearch.toLowerCase().trim();

    let _proposals = proposals.proposals ?? proposals.proposalsByAccount;

    const searchData = _proposals.filter(el => {
      if (
        typeof el.businessName === "string" ||
        typeof el.contactName === "string" ||
        typeof el.amountRequested === "string" ||
        typeof el.salesPersonName === "string"
      ) {
        return (
          (el.businessName || "").toLowerCase().includes(lowerCaseSearch) ||
          (el.contactName || "").toLowerCase().includes(lowerCaseSearch) ||
          (el.amountRequested || "").toLowerCase().includes(lowerCaseSearch) ||
          (el.salesPersonName || "").toLowerCase().includes(lowerCaseSearch)
        );
      }

      return false;
    });

    if (proposals.proposals) {
      return { ...proposals, proposals: searchData };
    } else {
      return { ...proposals, proposalsByAccount: searchData };
    }
  };

  function processProposalListData(proposals) {
    if (proposals && _.isArray(proposals.proposals)) {
      const roles = [];
      proposals.proposals.forEach(item => {
        try {
          item.contactName = item.firstName ? item.firstName + item.lastName : item.fullName;
          item.amountRequested = formatCurrency(item.amountRequested);
          item.createdDateTime = convertToDate(item.createdDateTime);
        } catch (error) {
          console.log(error);
        }
      });
      roles.forEach(item => {
        const rolesSelItem = {
          value: item,
          text: item ? item : "None",
        };
        rolesSel.push(rolesSelItem);
      });
      statusesSel = _(proposals.proposalsByAccount)
        .map("status")
        .uniq()
        .map(status => ({ value: status, text: status || "None" }))
        .value();

      return proposals.proposals;
    }
    if (proposals && _.isArray(proposals.proposalsByAccount)) {
      const roles = [];
      proposals.proposalsByAccount.forEach(item => {
        try {
          item.contactName = item.firstName ? item.firstName + item.lastName : item.fullName;
          item.amountRequested = formatCurrency(item.amountRequested);
          item.createdDateTime = convertToDate(item.createdDateTime);
        } catch (error) {
          console.log(error);
        }
      });
      roles.forEach(item => {
        const rolesSelItem = {
          value: item,
          text: item ? item : "None",
        };
        rolesSel.push(rolesSelItem);
      });
      statusesSel = _(proposals.proposalsByAccount)
        .map("status")
        .uniq()
        .map(status => ({ value: status, text: status || "None" }))
        .value();

      return proposals.proposalsByAccount;
    }
  }

  const [openAddNewProposalModal, setOpenAddNewProposalModal] = useState(false);

  const handleOpenAddNewProposalModal = () => {
    setOpenAddNewProposalModal(true);
  };
  const handleCloseAddNewProposalModal = () => {
    setOpenAddNewProposalModal(false);
  };

  const handleSetProposalsToLoad = () => {
    if (proposals == "self") {
      setProposals("account");
    } else {
      setProposals("self");
    }
  };

  return (
    <>
      <Query
        context={{ authRequired: true }}
        query={proposals === "self" ? GET_PROPOSAL_LIST : GET_ACCOUNT_PROPOSAL_LIST}
        fetchPolicy="no-cache"
      >
        {({ loading, error, data, refetch: refetchProposals }) => {
          if (loading) return "Loading...";
          if (error) return <h3>{String(error)}</h3>;
          let proposalListData = processProposalListData(filterProposalListData(data));
          if (proposals !== "self") {
            proposalListData = processProposalListData(filterProposalListData(data));
          }
          const { currentSelect: currentStatusSelect } = statusRef.current;
          if (currentStatusSelect) {
            proposalListData = proposalListData.filter(el => currentStatusSelect.includes(el.status));
          }

          return (
            <div className={classes.root}>
              <Grid>
                <Grid item xs={12}>
                  <Tile>
                    <TableHeader
                      filterComps={
                        <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                          <div style={{ display: "flex", columnGap: 16 }}>
                            <TableSearch
                              setPageNumber={setPageNumber}
                              setSavedListSettings={setSavedListSettings}
                              searchRef={searchRef}
                              savedListSettings={savedListSettings}
                            />
                            <TableSelect
                              setPageNumber={setPageNumber}
                              setSavedListSettings={setSavedListSettings}
                              selectRef={statusRef}
                              savedListSettings={savedListSettings}
                              id="status"
                              label="Status"
                              options={statusesSel}
                              width={200}
                              multiSelect={true}
                            />
                            {props.userProfile.vendorContactRole !== "sales_rep" && (
                              <FormControlLabel
                                control={
                                  <Switch
                                    checked={proposals === "self"}
                                    onChange={() => handleSetProposalsToLoad()}
                                    color="primary"
                                  />
                                }
                                label="Show Only My Proposals"
                                className={classes.switch}
                              />
                            )}
                          </div>
                        </div>
                      }
                    >
                      {/* <Button
                        style={{ marginLeft: "auto" }}
                        color="primary"
                        size="sm"
                        onClick={() => handleOpenAddNewProposalModal()}
                      >
                        {<Add />}Create New
                      </Button> */}
                    </TableHeader>
                    <Table
                      color="primary"
                      data={proposalListData}
                      columns={[
                        {
                          Header: "Created On",
                          accessor: "createdDateTime",
                          resizable: false,
                        },
                        {
                          Header: "Company Name",
                          accessor: "businessName",
                        },
                        {
                          Header: "Contact",
                          accessor: "contactName",
                        },
                        {
                          Header: "Amount",
                          accessor: "amountRequested",
                          value: "test",
                        },
                        {
                          Header: "Status",
                          accessor: "status",
                        },
                        {
                          Header: "Owner",
                          accessor: "salesPersonName",
                        },
                      ]}
                      pageSize={pageSizeValue}
                      pageNumber={pageNumber}
                      defaultPageSize={savedListSettings.pageSize}
                      showPaginationTop={false}
                      showPaginationBottom={true}
                      onPageChange={changePage => {
                        setPageNumber(changePage);
                      }}
                      onPageSizeChange={changePageSize => {
                        setPageSizeValue(changePageSize);
                        setPageNumber(0);
                      }}
                      getTrProps={(state, rowInfo, column, instance) => ({
                        style: { cursor: "pointer" },
                        onClick: e => handleProposalRowClick(rowInfo.original.id),
                      })}
                      className={classes.root + " -highlight"}
                      NoDataComponent={() => null}
                    />
                  </Tile>
                </Grid>
              </Grid>
              <CreateProposalModal
                open={openAddNewProposalModal}
                handleClose={() => handleCloseAddNewProposalModal()}
                handleOpenAddNewProposalModal={handleOpenAddNewProposalModal}
                dynamicsAccountId={userProfileDynamicsAccountId}
                accountId={userProfileAccountId}
                refetchProposals={refetchProposals}
              />
            </div>
          );
        }}
      </Query>
    </>
  );
}

const mapStateToProps = state => ({
  account: state.account,
  userProfile: state.userProfile,
  vp: state.vp,
  refetch: state.refetch,
});

const mapDispatchToProps = {
  reduxSetValue: setValue,
  setRefetch,
};

export default connect(mapStateToProps, mapDispatchToProps)(PageProposalList);
