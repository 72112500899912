import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Button } from "@material-ui/core";
import defaultAvatar from "../../assets/img/default-avatar.png";
import { UserProfile } from "@trnsact/trnsact-shared-types";
import { useSelector } from "react-redux";
const config = require("../../config");

export const NeedHelpSection = () => {
  const classes = useStyles();

  const accountRep = useSelector((state: any) => state.accountRep) as UserProfile;

  return (
    <Box className={classes.root}>
      <img
        src={
          accountRep?.mugshot
            ? `https://${config.S3_BUCKET_NAME}.s3-us-west-2.amazonaws.com/${accountRep.mugshot}`
            : defaultAvatar
        }
        className={classes.avatarImg}
        alt="Account rep photo"
      />
      <h3>{accountRep?.fullName}</h3>
      <a rel="noopener noreferrer" href="https://dcr.atlassian.net/servicedesk/customer/portals" target="_blank">
        <Button variant="contained" className={classes.helpButton}>
          Need Help?
        </Button>
      </a>
    </Box>
  );
};

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    margin: "20px 0 50px 0",
    alignItems: "center",
    zIndex: 5,
  },
  avatarImg: {
    width: 80,
    height: 80,
    overflow: "hidden",
    borderRadius: "50%",
  },
  helpButton: {
    textTransform: "none",
  },
});
