import { useFormContext, useWatch } from "react-hook-form";
import { triggerPathByStep } from "../constants";
import { FinanceProgramForm } from "../schema";
import { FinanceProgramFormSteps } from "../types";
import { useMemo } from "react";

interface Options {
  isReadOnly?: boolean;
}

export function useStepperActions({ isReadOnly = false }: Options) {
  const { getValues, setValue, control, trigger, getFieldState } = useFormContext<FinanceProgramForm>();
  const { step: currentStepWatchValue = FinanceProgramFormSteps.First } = useWatch<FinanceProgramForm>({ control });

  const handleBack = async () => {
    const currentStep = getValues("step");

    setValue("step", currentStep - 1);
  };

  const handleNext = async () => {
    const currentStep = getValues("step");

    if (!isReadOnly) {
      const isValid = await trigger(triggerPathByStep[currentStep], {
        shouldFocus: true,
      });

      if (!isValid) return;
    }

    setValue("step", currentStep + 1);
  };

  const handleGoToExactStep = async (step: FinanceProgramFormSteps) => {
    if (currentStepWatchValue === step) return;

    if (!isReadOnly) {
      const triggerPathsForCheck = Object.values(triggerPathByStep)
        .map(path => path)
        .filter((_, index) => index + 1 < step);

      await trigger(triggerPathsForCheck);

      const pathToRedirectToError = triggerPathsForCheck
        .map(path => getFieldState(path))
        .findIndex(value => Boolean(value.error));

      setValue("step", pathToRedirectToError + 1 > 0 ? pathToRedirectToError + 1 : step);
    } else {
      setValue("step", step);
    }
  };

  const availableSteps = useMemo(
    () => ({
      next: currentStepWatchValue + 1 > FinanceProgramFormSteps.Fourth,
      prev: currentStepWatchValue - 1 < FinanceProgramFormSteps.First,
    }),
    [currentStepWatchValue]
  );

  return {
    handleBack,
    handleNext,
    handleGoToExactStep,
    currentStepWatchValue,
    availableSteps,
  };
}
