import React, { Fragment } from "react";
import { Box, Button } from "@material-ui/core";
import { StepCard } from "./StepCard";
import { CardContainer } from "../../../../../components/shared/CardContainer/CardContainer";
import AddIcon from "@material-ui/icons/AddCircle";
import { makeStyles } from "@material-ui/core/styles";
import { grey } from "@material-ui/core/colors";
import { FinanceProgram } from "@trnsact/trnsact-shared-types";

interface Props {
  financePrograms: any[];
  onCreateProgram?: VoidFunction;
  onUpdateProgram?: (programToUpdate: FinanceProgram) => void;
}

export const ConfigurationPanel = ({ onCreateProgram, onUpdateProgram, financePrograms }: Props) => {
  const classes = useStyles();

  return (
    <CardContainer containerClassName="panel" title="Steps" elevation={0}>
      <Box className={classes.container}>
        {new Array(3).fill("").map((_, index, array) => (
          <Fragment key={index}>
            <StepCard
              stepNumber={index + 1}
              lenders={new Array(3).fill("")}
              onDrop={() => {}}
              onRemove={() => {}}
              onRemoveLender={() => {}}
              onSelectProgram={() => {}}
              onChangeAutoSubmit={() => {}}
              financePrograms={financePrograms}
              onCreateProgram={onCreateProgram}
              onUpdateProgram={onUpdateProgram}
            />

            {array.length - 1 !== index && <Box className={classes.connector} />}
          </Fragment>
        ))}

        <Box className={classes.connector} />

        <Button color="primary" variant="outlined" startIcon={<AddIcon />}>
          Add new step
        </Button>
      </Box>
    </CardContainer>
  );
};

const useStyles = makeStyles({
  container: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  connector: {
    width: "2px",
    minHeight: "50px",
    border: `1px dashed ${grey["500"]}`,
  },
});
