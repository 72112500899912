import { useSelector } from "react-redux";
import { adminRoles } from "pages/Prequal/constants";
import { PortalConfigurationType } from "@trnsact/trnsact-shared-types";
import { PortalConfigurationRolesSettingsKeys } from "types";
import { usePortalConfigurationChecks } from "modules/portalConfigurations";

export function usePermissions() {
  const userProfile = useSelector((state: any) => state.userProfile);

  const { checkPortalConfigEntryForCurrentUserRole } = usePortalConfigurationChecks();

  const isSuperAdmin = [adminRoles.super, adminRoles.singleAccountOnly].includes(userProfile.adminRole);

  const hasWriteAccess =
    checkPortalConfigEntryForCurrentUserRole(
      PortalConfigurationType.Aftermarket,
      PortalConfigurationRolesSettingsKeys.AssignedOnly
    ) || isSuperAdmin;
  const hasReadOnlyAccess = checkPortalConfigEntryForCurrentUserRole(
    PortalConfigurationType.Aftermarket,
    PortalConfigurationRolesSettingsKeys.ReadOnly
  );

  return {
    hasWriteAccess,
    hasReadOnlyAccess,
  };
}
