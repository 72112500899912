export const voNotificationsDefaultConfig = {
  stageUpdates: {
    isTurnedOn: true,
    isNotifyAllRelatedUsers: true,
    locationIds: [],
    userRoles: [],
    userIds: [],
  },
};

export const defaultConfigByType: Record<string, any> = {
  VO_NOTIFICATIONS: voNotificationsDefaultConfig,
};
