import React from "react";
import { useFormContext } from "react-hook-form";
import { Box, Typography } from "@material-ui/core";
import { LenderProfiles } from "@trnsact/trnsact-shared-types/src/generated";
import { SelectOption } from "../../../../../../global";
import { FinanceProgramForm } from "../../../../schema";
import { LendersAutocompleteField } from "./LendersAutocompleteField";
import { AutocompleteField, InputField, QuillInputField } from "../../../../../../components/form";

interface Props {
  isReadOnly: boolean;
  isMultipleLenders?: boolean;
  userType?: "lender" | "dealer";
  lendersOptions: LenderProfiles[];
  isLendersSelectDisabled?: boolean;
  dealerVendorProfileOptions?: any[];
}

export const ProgramDetails = ({
  userType,
  isReadOnly,
  lendersOptions,
  isMultipleLenders,
  isLendersSelectDisabled,
  dealerVendorProfileOptions = [],
}: Props) => {
  const { control, setValue } = useFormContext<FinanceProgramForm>();

  return (
    <>
      <Box className="formRow">
        <InputField
          control={control}
          name="program.first.name"
          label="Finance Program Name"
          inputProps={{
            required: true,
            disabled: isReadOnly,
            autoComplete: "off",
            placeholder: "Enter name",
          }}
        />

        <InputField
          control={control}
          name="program.first.displayName"
          label="Finance Program Display Name"
          tooltip="This name will be shown to customers"
          inputProps={{
            required: true,
            autoComplete: "off",
            disabled: isReadOnly,
            placeholder: "Enter display name",
          }}
        />
      </Box>

      {!!lendersOptions.length && (
        <LendersAutocompleteField
          lendersOptions={lendersOptions}
          isMultipleLenders={isMultipleLenders}
          isLendersSelectDisabled={isLendersSelectDisabled}
        />
      )}

      {userType === "lender" && !!dealerVendorProfileOptions?.length && (
        <AutocompleteField<SelectOption, true, FinanceProgramForm>
          multiple
          control={control}
          disableCloseOnSelect
          name="program.first.dealers"
          disabled={!dealerVendorProfileOptions?.length}
          getOptionSelected={(option, value) => value && option.value === value?.value}
          options={dealerVendorProfileOptions?.map(profile => ({
            value: profile.lenderProfiles[0]?.lenderProfileId,
            label: profile.name.replace("VP - ", ""),
            ...profile,
          }))}
          textFieldProps={{
            label: "Dealers this program is available to",
            placeholder: "Search by name...",
          }}
        />
      )}

      <Box className="formSectionWithCustomLabel">
        <Typography component="span" variant="body1">
          Program Guidelines
        </Typography>

        <QuillInputField
          control={control}
          readOnly={isReadOnly}
          name="program.first.guidelines"
          onChange={(value, delta, source, editor) => {
            setValue("program.first.guidelines", editor.getContents());
          }}
        />
      </Box>

      <Box className="formSectionWithCustomLabel">
        <Typography component="span" variant="body1">
          Internal Notes
        </Typography>

        <InputField
          control={control}
          name="program.first.notes"
          inputProps={{
            variant: "outlined",
            disabled: isReadOnly,
            placeholder: "Enter your notes",
          }}
        />
      </Box>
    </>
  );
};
